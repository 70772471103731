import {
    ALL_DATA_VANILLA,
    CALL_UP_LIMIT, CALL_UP_LIMIT_ABS,
    CURRENCY, FIXING_DATE_VANILLA,
    FIXING_DATES_VANILLA, GREEKS_VANILLA,
    IS_WITHOUT_NOTIONAL_VANILLA, MODEL_VANILLA, N_SIMS_VANILLA, NOTIONAL_VANILLA,
    PAYOFF_VANILLA, PERIOD_MODE_VANILLA, REF_SPOTS_VANILLA, STRIKE_ABS_VANILLA, STRIKE_VANILLA,
    TICKERS_VANILLA, TRADING_DATE_VANILLA
} from "./types";

export const set_tickers = (value) => {
    return {
        type: TICKERS_VANILLA,
        value
    }
}

export const set_fixing_dates = (value) => {
    return {
        type: FIXING_DATES_VANILLA,
        value
    }
}

export const set_is_without_notional = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL_VANILLA,
        value
    }
}

export const set_currency = (value) => {
    return {
        type: CURRENCY,
        value
    }
}

export const set_payoff = (value) => {
    return {
        type: PAYOFF_VANILLA,
        value
    }
}

export const set_strike = (value) => {
    return {
        type: STRIKE_VANILLA,
        value
    }
}

export const set_call_up_limit = (value) => {
    return {
        type: CALL_UP_LIMIT,
        value
    }
}

export const set_trading_date_vanilla = (value) => {
    return {
        type: TRADING_DATE_VANILLA,
        value
    }
}

export const set_ref_spots = (value) => {
    console.log("{ change ref spots vanilla }", value)
    return {
        type: REF_SPOTS_VANILLA,
        value
    }
}

export const set_fixing_date_vanilla = (value) => {
    return {
        type: FIXING_DATE_VANILLA,
        value
    }
}

export const set_strike_abs = (value) => {
    return {
        type: STRIKE_ABS_VANILLA,
        value
    }
}

export const set_call_up_limit_abs = (value) => {
    return {
        type: CALL_UP_LIMIT_ABS,
        value
    }
}

export const set_greeks_vanilla = (value) => {
    return {
        type: GREEKS_VANILLA,
        value
    }
}

export const set_notional_vanilla = (value) => {
    return {
        type: NOTIONAL_VANILLA,
        value
    }
}

export const set_period_mode_vanilla = (value) => {
    return {
        type: PERIOD_MODE_VANILLA,
        value
    }
}

export const set_model_vanilla = (value) => {
    return {
        type: MODEL_VANILLA,
        value
    }
}

export const set_n_sims_vanilla = (value) => {
    return {
        type: N_SIMS_VANILLA,
        value
    }
}

export const set_all_data_vanilla = (value) => {
    return {
        type: ALL_DATA_VANILLA,
        value: value.product
    }
}
