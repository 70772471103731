import {useDispatch, useSelector} from "react-redux";
import {
    set_change_state_marketdata,
    set_corr_mtx,
    set_divs,
    set_fixings, set_market_data_id,
    set_pricing_date,
    set_rate, set_service_divs, set_service_vols,
    set_spots,
    set_vols
} from "../redux/MarketData/actions";
import axios from "axios";
import {set_ref_spots, set_tickers} from "../redux/Autocall/actions";
import {
    set_call_up_limit, set_call_up_limit_abs, set_fixing_date_vanilla,
    set_ref_spots as set_ref_spots_vanilla, set_strike_abs,
    set_tickers as set_tickers_vanilla
} from "../redux/Vanilla/actions";
import useViewMode from "./useViewMode";
import {set_ref_spots_market_data_product} from "../redux/MarketDataProduct/actions";
import useRefreshMode from "./useRefreshMode";
import useRefreshFields from "./useRefreshFields";
import {set_ref_spots_basket, set_tickers_basket} from "../redux/CallOnBasket/action";
import {ParticipationReducer} from "../redux/Participation/ParticipationReducer";
import {
    set_call_up_limit_abs_participation,
    set_fixing_date_participation,
    set_ref_spots_participation,
    set_strike_abs_participation,
    set_tickers_participation
} from "../redux/Participation/actions";
import {set_ref_spots_digital_call} from "../redux/DigitalCall/actions";
import {set_ref_spots_digital_call_short} from "../redux/DigitalCallShort/actions";
import {set_ref_spots_spread_option} from "../redux/SpreadOption/actions";

const useMarketDataRefresh = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const refreshMode = useRefreshMode()
    const refreshFields = useRefreshFields()
    // Getting data for market data refresh
    const [acStepdown, acStrike, barrier,
        cpnAMTpa, cpnStrike, fixedCPNAMT,
        fixedCPNCNT, isMemory, isWithoutNotional,
        noACPeriod, scheduleFreq, strike,
        timeToMaturity, acStrikes, cpnStrikes,
        fixingDates, tickers, trading_date,
        refSpots, serviceVols, serviceDivs] = useSelector((store) => {
        const {autocallReducer, MarketDataReducer} = store
        return [
            autocallReducer.acStepdown, autocallReducer.acStrike, autocallReducer.barrier,
            autocallReducer.cpnAMTpa, autocallReducer.cpnStrike, autocallReducer.fixedCPNAMT,
            autocallReducer.fixedCPNCNT, autocallReducer.isMemory, autocallReducer.isWithoutNotional,
            autocallReducer.noACPeriod, autocallReducer.scheduleFreq, autocallReducer.strike,
            autocallReducer.timeToMaturity, autocallReducer.acStrikes, autocallReducer.cpnStrikes,
            autocallReducer.fixingDates, autocallReducer.tickers, autocallReducer.tradingDate,
            autocallReducer.refSpots, MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const [acStepdownDigital, acStrikeDigital, barrierDigital,
        cpnAMTpaDigital, cpnStrikeDigital, fixedCPNAMTDigital,
        fixedCPNCNTDigital, isMemoryDigital, isWithoutNotionalDigital,
        noACPeriodDigital, scheduleFreqDigital, strikeDigital,
        timeToMaturityDigital, acStrikesDigital, cpnStrikesDigital,
        fixingDatesDigital, tickersDigital, trading_dateDigital,
        refSpotsDigital, serviceVolsDigital, serviceDivsDigital] = useSelector((store) => {
        const {digitalCallReducer, MarketDataReducer} = store
        return [
            digitalCallReducer.acStepdown, digitalCallReducer.acStrike, digitalCallReducer.barrier,
            digitalCallReducer.cpnAMTpa, digitalCallReducer.cpnStrike, digitalCallReducer.fixedCPNAMT,
            digitalCallReducer.fixedCPNCNT, digitalCallReducer.isMemory, digitalCallReducer.isWithoutNotional,
            digitalCallReducer.noACPeriod, digitalCallReducer.scheduleFreq, digitalCallReducer.strike,
            digitalCallReducer.timeToMaturity, digitalCallReducer.acStrikes, digitalCallReducer.cpnStrikes,
            digitalCallReducer.fixingDates, digitalCallReducer.tickers, digitalCallReducer.tradingDate,
            digitalCallReducer.refSpots, MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const [acStepdownDigitalShort, acStrikeDigitalShort, barrierDigitalShort,
        cpnAMTpaDigitalShort, cpnStrikeDigitalShort, fixedCPNAMTDigitalShort,
        fixedCPNCNTDigitalShort, isMemoryDigitalShort, isWithoutNotionalDigitalShort,
        noACPeriodDigitalShort, scheduleFreqDigitalShort, strikeDigitalShort,
        timeToMaturityDigitalShort, acStrikesDigitalShort, cpnStrikesDigitalShort,
        fixingDatesDigitalShort, tickersDigitalShort, trading_dateDigitalShort,
        refSpotsDigitalShort, serviceVolsDigitalShort, serviceDivsDigitalShort] = useSelector((store) => {
        const {digitalCallShortReducer, MarketDataReducer} = store
        return [
            digitalCallShortReducer.acStepdown, digitalCallShortReducer.acStrike, digitalCallShortReducer.barrier,
            digitalCallShortReducer.cpnAMTpa, digitalCallShortReducer.cpnStrike, digitalCallShortReducer.fixedCPNAMT,
            digitalCallShortReducer.fixedCPNCNT, digitalCallShortReducer.isMemory, digitalCallShortReducer.isWithoutNotional,
            digitalCallShortReducer.noACPeriod, digitalCallShortReducer.scheduleFreq, digitalCallShortReducer.strike,
            digitalCallShortReducer.timeToMaturity, digitalCallShortReducer.acStrikes, digitalCallShortReducer.cpnStrikes,
            digitalCallShortReducer.fixingDates, digitalCallShortReducer.tickers, digitalCallShortReducer.tradingDate,
            digitalCallShortReducer.refSpots, MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const [acStepdownSpreadOption, acStrikeSpreadOption, barrierSpreadOption,
        cpnAMTpaSpreadOption, cpnStrikeSpreadOption, fixedCPNAMTSpreadOption,
        fixedCPNCNTSpreadOption, isMemorySpreadOption, isWithoutNotionalSpreadOption,
        noACPeriodSpreadOption, scheduleFreqSpreadOption, strikeSpreadOption,
        timeToMaturitySpreadOption, acStrikesSpreadOption, cpnStrikesSpreadOption,
        fixingDatesSpreadOption, tickersSpreadOption, trading_dateSpreadOption,
        refSpotsSpreadOption, serviceVolsSpreadOption, serviceDivsSpreadOption] = useSelector((store) => {
        const {spreadOptionReducer, MarketDataReducer} = store
        return [
            spreadOptionReducer.acStepdown, spreadOptionReducer.acStrike, spreadOptionReducer.barrier,
            spreadOptionReducer.cpnAMTpa, spreadOptionReducer.cpnStrike, spreadOptionReducer.fixedCPNAMT,
            spreadOptionReducer.fixedCPNCNT, spreadOptionReducer.isMemory, spreadOptionReducer.isWithoutNotional,
            spreadOptionReducer.noACPeriod, spreadOptionReducer.scheduleFreq, spreadOptionReducer.strike,
            spreadOptionReducer.timeToMaturity, spreadOptionReducer.acStrikes, spreadOptionReducer.cpnStrikes,
            spreadOptionReducer.fixingDates, spreadOptionReducer.tickers, spreadOptionReducer.tradingDate,
            spreadOptionReducer.refSpots, MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const [callUpLimit, currency, isWithoutNotionalVanilla,
        payoff, priceCurrency, strikeVanilla,
        tradingDate, fixingDatesVanilla, tickersVanilla] = useSelector((store) => {
        const {VanillaReducer} = store
        return [
            VanillaReducer.callUpLimit, VanillaReducer.currency, VanillaReducer.isWithoutNotional,
            VanillaReducer.payoff, VanillaReducer.priceCurrency, VanillaReducer.strike,
            VanillaReducer.tradingDate, VanillaReducer.fixingDates, VanillaReducer.tickersVanilla
        ]
    })

    const [callUpLimitP, currencyP, isWithoutNotionalP,
        payoffP, priceCurrencyP, strikeP,
        tradingDateP, fixingDatesP, tickersP,
        leverage_rate] = useSelector(store => {
        const {ParticipationReducer} = store
        return [
            ParticipationReducer.callUpLimit, ParticipationReducer.currency, ParticipationReducer.isWithoutNotional,
            ParticipationReducer.payoff, ParticipationReducer.priceCurrency, ParticipationReducer.strike,
            ParticipationReducer.tradingDate, ParticipationReducer.fixingDates, ParticipationReducer.tickersParticipation,
            ParticipationReducer.leverage_rate
        ]
    })

    const [tickersMD, refSpotsMD] = useSelector((store) => {
        const {MarketDataProductReducer} = store
        return [MarketDataProductReducer.tickersMD, MarketDataProductReducer.refSpotsMD]
    })

    const [tickersBasket, isWithoutNotionalBasket, fixingDatesBasket, fixingDateBasket, tradingDateBasket] = useSelector((store) => {
        const {BasketReducer} = store
        return [BasketReducer.tickersBasket, BasketReducer.isWithoutNotional, BasketReducer.fixingDates, BasketReducer.fixingDate, BasketReducer.tradingDate]
    })



    const saveServices = (product) => {
        saveServicesVols(product)
        saveServicesDivs(product)
    }

    const saveServicesVols = (product) => {
        const serviceVols = {}
        product.tickers.forEach((ticker) => {
            serviceVols[ticker] = '📈'
        })
        console.log("serviceVols", serviceVols)
        dispatch(set_service_vols(serviceVols))
    }

    const saveServicesDivs = (product) => {
        const serviceDivs = {}
        product.tickers.forEach((ticker) => {
            serviceDivs[ticker] = '📈'
        })
        console.log("serviceDivs", serviceDivs)
        dispatch(set_service_divs(serviceDivs))
    }

    const save_tickers = (tickers) => {
        if (viewMode.viewMode === "Autocall") dispatch(set_tickers(tickers))
        else if (viewMode.viewMode === "Vanilla") dispatch(set_tickers_vanilla(tickers))
        else if (viewMode.viewMode === "Call On Basket") dispatch(set_tickers_basket(tickers))
        else if (viewMode.viewMode === "Protected Participation") dispatch(set_tickers_participation(tickers))
    }


    const saveMarketData = (data, product) => {
        console.log("saveMarketData", data, product)
        console.log("spots", data.spots, data.pricing_date)
        console.log("corr_mtx", data.corr_mtx)
        save_tickers(product.tickers)
        console.warn("[ test CUSTOM tt ]", refreshMode.refreshMode)
        if (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") {
            dispatch(set_pricing_date(data.pricing_date ?? new Date().toISOString().slice(0, 10)))
            dispatch(set_rate(data.rate))
            dispatch(set_spots(data.spots))
            dispatch(set_corr_mtx(data.corr_mtx))
            if (!Array.isArray(data.fixings)) {
                dispatch(set_fixings([]))
            } else {
                dispatch(set_fixings(data.fixings))
            }
            dispatch(set_vols(data.vols))
            dispatch(set_divs(data.divs))
            saveServices(product)
            console.log("[ CORR_MTX ]", data.corr_mtx)
        } else if (refreshMode.refreshMode === "CUSTOM") {
            console.warn("[ test CUSTOM ]", refreshFields.refreshFields)
            if (refreshFields.refreshFields.spots) {
                dispatch(set_spots(data.spots))
            }
            if (refreshFields.refreshFields.corr_mtx) {
                dispatch(set_corr_mtx(data.corr_mtx))
            }
            // if (refreshFields.refreshFields.fixings) {
            //     dispatch(set_fixings(data.fixings))
            // }
            if (refreshFields.refreshFields.vols) {
                dispatch(set_vols(data.vols))
                saveServicesVols(product)
            }
            if (refreshFields.refreshFields.divs) {
                dispatch(set_divs(data.divs))
                saveServicesDivs(product)
            }
            if (refreshFields.refreshFields.rate) {
                dispatch(set_rate(data.rate))
            }
        }


    }

    const saveRefSpots = (product) => {
        if (viewMode.viewMode === 'Autocall') {
            dispatch(set_ref_spots(product.spots))
        } else if (viewMode.viewMode === 'Vanilla') {
            dispatch(set_ref_spots_vanilla(product.spots))
        } else if (viewMode.viewMode === 'MarketData') {
            dispatch(set_ref_spots_market_data_product(product.spots))
        } else if (viewMode.viewMode === 'Call On Basket') {
            dispatch(set_ref_spots_basket(product.spots))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_ref_spots_participation(product.spots))
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            dispatch(set_ref_spots_digital_call(product.spots))
        } else if (viewMode.viewMode === "Digital Call") {
            dispatch(set_ref_spots_digital_call_short(product.spots))
        } else if (viewMode.viewMode === "Spread Option") {
            dispatch(set_ref_spots_spread_option(product.spots))
        }
    }

    const saveVanillaDeps = (product, md) => {
        if (viewMode.viewMode === "Vanilla") {
            if (callUpLimit !== undefined) {
                dispatch(set_call_up_limit_abs(md.spots[product.tickers[0]] * callUpLimit))
            }
            dispatch(set_strike_abs(md.spots[product.tickers[0]] * strikeVanilla))
            const tmp = new Date(product.trading_date)
            tmp.setDate(tmp.getDate() + fixingDatesVanilla)
            dispatch(set_fixing_date_vanilla([tmp.toISOString().slice(0, 10)]))
        } else if (viewMode.viewMode === "Protected Participation") {
            if (callUpLimitP !== undefined) {
                dispatch(set_call_up_limit_abs_participation(md.spots[product.tickers[0]] * callUpLimitP))
            }
            dispatch(set_strike_abs_participation(md.spots[product.tickers[0]] * strikeP))
            const tmp = new Date(product.trading_date)
            tmp.setDate(tmp.getDate() + fixingDatesP)
            dispatch(set_fixing_date_participation([tmp.toISOString().slice(0, 10)]))
        }
    }

    const saveMarketDataId = (data) => {
        console.log("saveMarketDataId", data)
        Promise.all([
            axios.post(`/rdict/post`, data)
        ])
            .then(receivedV => {
                console.log("saveMarketDataId", receivedV[0])
                dispatch(set_market_data_id(receivedV[0].data))
                dispatch(set_change_state_marketdata(false))
            })
    }

    let date_ = new Date(trading_date)
    date_.setDate(date_.getDate() + fixingDatesVanilla)

    let date_p = new Date(trading_date)
    date_p.setDate(date_p.getDate() + fixingDatesP)

    return {
        saveMarketData,
        saveMarketDataId,
        saveRefSpots,
        saveVanillaDeps,
        data: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                ac_stepdown: acStepdown,
                ac_strike: acStrike,
                barrier: barrier,
                class_name: "Autocall",
                cpn_amt_pa: cpnAMTpa,
                cpn_strike: cpnStrike,
                fixed_cpn_amt: fixedCPNAMT,
                fixed_cpn_cnt: fixedCPNCNT,
                is_memory: isMemory,
                is_without_notional: isWithoutNotional,
                no_ac_period: noACPeriod,
                schedule_freq: scheduleFreq,
                strike: strike,
                time2maturity: timeToMaturity,
                trading_date: trading_date,
                typology: "EQS",
                version: "v1.0_20230531_1",
                ac_strikes: acStrikes,
                cpn_strikes: cpnStrikes,
                fixing_dates: fixingDates,
                tickers: tickers,
                ref_spots: refSpots
            },
            system: {
                model: "LocalVol"
            }
        }),
        dataVanilla: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                call_up_limit: callUpLimit,
                class_name: "Vanilla",
                currency: currency,
                is_without_notional: isWithoutNotionalVanilla,
                payoff: payoff,
                price_currency: priceCurrency,
                strike: strikeVanilla,
                trading_date: tradingDate,
                typology: "OPT",
                version: "v1.0_20230702",
                fixing_dates: [date_.toISOString().slice(0, 10)],
                tickers: tickersVanilla
            }
        }),
        dataParticipation: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                call_up_limit: callUpLimitP,
                class_name: "Vanilla",
                currency: currencyP,
                is_without_notional: isWithoutNotionalP,
                payoff: payoffP,
                price_currency: priceCurrencyP,
                strike: strikeP,
                trading_date: tradingDateP,
                typology: "OPT",
                version: "v1.0_20230702",
                fixing_dates: [date_p.toISOString().slice(0, 10)],
                tickers: tickersP,
                leverage_rate: leverage_rate
            }
        }),
        dataMarketData: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                class_name: "MarketData",
                typology: "OPT",
                version: "v1.0_20230702",
                tickers: tickersMD
            }
        }),
        dataBasket: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                class_name: "Autocall",
                tickers: tickersBasket,
                typology: "EQS",
                version: "v1.0_20230531_1",
                ac_strike: 5.0,
                ac_strikes: [5.0],
                barrier: 0,
                strike: 0,
                cpn_strike: 0,
                cpn_strikes: [0],
                fixed_cpn_amt: 0,
                fixed_cpn_cnt: 0,
                is_call_payoff: true,
                is_memory: true,
                cpn_amt_pa: 0,

                is_without_notional: isWithoutNotionalBasket,
                ref_spots: undefined,
                fixing_dates: fixingDateBasket,
                trading_date: tradingDateBasket,
                schedule_freq: fixingDatesBasket,
                time2maturity: fixingDatesBasket

            },
            system: {
                model: "LocalVol"
            }
        }),
        dataDigitalCall: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                ac_stepdown: acStepdownDigital,
                ac_strike: acStrikeDigital,
                barrier: barrierDigital,
                class_name: "Autocall",
                cpn_amt_pa: cpnAMTpaDigital,
                cpn_strike: cpnStrikeDigital,
                fixed_cpn_amt: fixedCPNAMTDigital,
                fixed_cpn_cnt: fixedCPNCNTDigital,
                is_memory: isMemoryDigital,
                is_without_notional: isWithoutNotionalDigital,
                no_ac_period: noACPeriodDigital,
                schedule_freq: scheduleFreqDigital,
                strike: strikeDigital,
                time2maturity: timeToMaturityDigital,
                trading_date: trading_dateDigital,
                typology: "EQS",
                version: "v1.0_20230531_1",
                ac_strikes: acStrikesDigital,
                cpn_strikes: cpnStrikesDigital,
                fixing_dates: fixingDatesDigital,
                tickers: tickersDigital,
                ref_spots: refSpotsDigital
            },
            system: {
                model: "LocalVol"
            }
        }),
        dataDigitalCallShort: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                ac_stepdown: acStepdownDigitalShort,
                ac_strike: acStrikeDigitalShort,
                barrier: barrierDigitalShort,
                class_name: "Autocall",
                cpn_amt_pa: cpnAMTpaDigitalShort,
                cpn_strike: cpnStrikeDigitalShort,
                fixed_cpn_amt: fixedCPNAMTDigitalShort,
                fixed_cpn_cnt: fixedCPNCNTDigitalShort,
                is_memory: isMemoryDigitalShort,
                is_without_notional: isWithoutNotionalDigitalShort,
                no_ac_period: noACPeriodDigitalShort,
                schedule_freq: scheduleFreqDigitalShort,
                strike: strikeDigitalShort,
                time2maturity: timeToMaturityDigitalShort,
                trading_date: trading_dateDigitalShort,
                typology: "EQS",
                version: "v1.0_20230531_1",
                ac_strikes: acStrikesDigitalShort,
                cpn_strikes: cpnStrikesDigitalShort,
                fixing_dates: fixingDatesDigitalShort,
                tickers: tickersDigitalShort,
                ref_spots: refSpotsDigitalShort
            },
            system: {
                model: "LocalVol"
            }
        }),
        dataSpreadOption: JSON.stringify({
            events: {},
            market_data: {},
            position: {
                notional: -1
            },
            product: {
                ac_stepdown: acStepdownSpreadOption,
                ac_strike: acStrikeSpreadOption,
                barrier: barrierSpreadOption,
                class_name: "Autocall",
                cpn_amt_pa: cpnAMTpaSpreadOption,
                cpn_strike: cpnStrikeSpreadOption,
                fixed_cpn_amt: fixedCPNAMTSpreadOption,
                fixed_cpn_cnt: fixedCPNCNTSpreadOption,
                is_memory: isMemorySpreadOption,
                is_without_notional: isWithoutNotionalSpreadOption,
                no_ac_period: noACPeriodSpreadOption,
                schedule_freq: scheduleFreqSpreadOption,
                strike: strikeSpreadOption,
                time2maturity: timeToMaturitySpreadOption,
                trading_date: trading_dateSpreadOption,
                typology: "EQS",
                version: "v1.0_20230531_1",
                ac_strikes: acStrikesSpreadOption,
                cpn_strikes: cpnStrikesSpreadOption,
                fixing_dates: fixingDatesSpreadOption,
                tickers: tickersSpreadOption,
                ref_spots: refSpotsSpreadOption
            },
            system: {
                model: "LocalVol"
            }
        })
    }
}

export default useMarketDataRefresh