import {
    AC_PERFORMANCE,
    AC_STEPDOWN,
    AC_STRIKE,
    AC_STRIKES, ALL_DATA_AUTOCALL,
    BARRIER,
    CPN_AMT_PA, CPN_PERFORMANCE,
    CPN_STRIKE,
    CPN_STRIKES, CURRENCY_AUTOCALL, DATE_EXPIRY_AUTOCALL, EVENT_AUTOCALL, FINAL_PERFORMANCE, FIXED_CPN_AMT,
    FIXED_CPN_CNT,
    FIXING_DATES, FIXING_DETAILS_STATE, GREEKS_AUTOCALL, HISTORY, IS_CALL_PAYOFF,
    IS_WITHOUT_NOTIONAL, MODEL_AUTOCALL, N_SIMS_AUTOCALL,
    NO_AC_PERIOD, NOTIONAL_AUTOCALL,
    PAYOFF, PERIOD_MODE, PRICING_HISTORY,
    REF_SPOTS, REFRESH_RESULTS,
    SCHEDULE_FREQ, SNAPSHOT_AUTOCALL,
    STRIKE, TICKERS,
    TIME_TO_MATURITY,
    TRADING_DATE
} from "./types";
import {makeLog, makeLogService} from "../../utils/logger";

const currentDate = new Date()
currentDate.setDate(currentDate.getDate() +  365)

const initialState = {
    tradingDate: new Date().toISOString().slice(0, 10), // OK
    timeToMaturity: 1, // OK
    tickers: ["MGNT", "MTSS", "OZON", "SMLT", "YNDX"], // OK
    strike: 1, // OK
    scheduleFreq: 91, // FIXME перевести в число
    refSpots: {
        "MGNT": 100,
        "MTSS": 100,
        "OZON": 100,
        "SMLT": 100,
        "YNDX": 100
    }, // OK
    productTypeId: 8, // FIXME тут не ясно откуда взять жтот id и его нет в запросе
    priceCurrency: "RUB", // FIXME не нашел где используем в запросе
    noACPeriod: 0, // OK
    isWithoutNotional: false, // OK
    isMemory: true, // OK
    refreshResult: false, // FIXME не нашел где используем в запросе
    fixingDates: ["2023-07-31", "2023-10-29", "2024-01-28", "2024-04-28", "2024-07-28", "2024-10-27", "2025-01-26", "2025-04-27", "2025-07-27", "2025-10-26", "2026-01-25", "2026-04-26"], // OK
    fixedCPNCNT: 0, // OK
    fixedCPNAMT: 0.0498, // OK
    currency: "RUB", // FIXME не нашел где используем в запросе
    cpnStrikes: [0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7], // OK
    cpnStrike: 0.7, // OK
    cpnAMTpa: 0.2, // OK
    barrier: 0.9, // OK
    acStrike: 1.0, // OK
    acStrikes: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], // OK
    fixingDetailsState: [false, false, false, false, false, false, false, false, false, false, false, false],
    acStepdown: 0, // OK

    payoff: "EKI", // FIXME не нашел где используем в запросе

    history: {},
    pricingHistory: {},

    acPerformance: "WoF",
    cpnPerformance: "WoF",
    finalPerformance: "WoF",

    test: undefined,
    dateExpiry: currentDate.toISOString().slice(0, 10),
    periodMode: "years",

    isCallPayoff: false,
    notional: 100000000,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },

    model: "LocalVol",
    modelOptions: ["LocalVol", "BlackScholes"],

    n_sims: 131072,

    events: {},

    snapshot: {
        ac_strike: 1.0,
        timeToMaturity: 1,
        scheduleFreq: 91,
        cpnStrike: 0.7,
        cpnAMTpa: 0.2,
        barrier: 0.9,
        noACPeriod: 0,
        acStepdown : 0,
        currency: "RUB",
        isWithoutNotional: false,
        periodMode: "years",
        fixedCPNAMT: 0.0498,
        fixedCPNCNT: 0,
        payoff: "EKI",
        strike: 1,
        acPerformance: "WoF",
        cpnPerformance: "WoF",
        finalPerformance: "WoF",
        isCallPayoff: false
    }
}

export const autocallReducer = (state = initialState, action) => {
    switch (action.type) {
        //Pricing_request
        case IS_WITHOUT_NOTIONAL:
            makeLog("IS_WITHOUT_NOTIONAL", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case AC_STEPDOWN:
            makeLog("AC_STEPDOWN", state.acStepdown, action.value)
            return {
                ...state,
                acStepdown: action.value
            }
        case TIME_TO_MATURITY:
            makeLog("TIME_TO_MATURITY", state.timeToMaturity, action.value)
            return {
                ...state,
                timeToMaturity: action.value
            }
        case SCHEDULE_FREQ:
            makeLog("SCHEDULE_FREQ", state.scheduleFreq, action.value)
            return {
                ...state,
                scheduleFreq: action.value
            }
        case AC_STRIKE:
            makeLog("AC_STRIKE", state.acStrike, action.value)
            return {
                ...state,
                acStrike: action.value
            }
        case NO_AC_PERIOD:
            makeLog("NO_AC_PERIOD", state.noACPeriod, action.value)
            return {
                ...state,
                noACPeriod: action.value
            }
        case CPN_STRIKE:
            makeLog("CPN_STRIKE", state.cpnStrike, action.value)
            return {
                ...state,
                cpnStrike: action.value
            }
        case FIXED_CPN_CNT:
            makeLog("FIXED_CPN_CNT", state.fixedCPNCNT, action.value)
            return {
                ...state,
                fixedCPNCNT: action.value
            }
        case FIXED_CPN_AMT:
            makeLog("FIXED_CPN_AMT", state.fixedCPNAMT, action.value)
            return {
                ...state,
                fixedCPNAMT: action.value
            }
        case CPN_AMT_PA:
            makeLog("CPN_AMT_PA", state.cpnAMTpa, action.value)
            return {
                ...state,
                cpnAMTpa: action.value
            }
        case PAYOFF:
            makeLog("PAYOFF", state.payoff, action.value)
            return {
                ...state,
                payoff: action.value,
                barrier: action.value === "GP" ? 5 : 0.9,
                strike: 1,
                commonState: true
            }
        case STRIKE:
            makeLog("STRIKE", state.strike, action.value)
            return {
                ...state,
                strike: action.value
            }
        case BARRIER:
            makeLog("BARRIER", state.barrier, action.value)
            return {
                ...state,
                barrier: action.value
            }
        case TRADING_DATE:
            makeLog("TRADING_DATE", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case FIXING_DETAILS_STATE:
            makeLogService("FIXING_DETAILS_STATE", state.fixingDetailsState, action.value)
            return {
                ...state,
                fixingDetailsState: action.value
            }
        case FIXING_DATES:
            makeLog("FIXING_DATES", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case AC_STRIKES:
            makeLog("AC_STRIKES", state.acStrikes, action.value)
            return {
                ...state,
                acStrikes: action.value
            }
        case CPN_STRIKES:
            makeLog("CPN_STRIKES", state.cpnStrikes, action.value)
            return {
                ...state,
                cpnStrikes: action.value
            }
        case REF_SPOTS:
            makeLog("REF_SPOTS", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case HISTORY:
            return {
                ...state,
                history: action.value
            }
        case TICKERS:
            makeLog("TICKERS", state.tickers, action.value)
            return {
                ...state,
                tickers: action.value
            }
        case REFRESH_RESULTS:
            makeLog("REFRESH_RESULTS", state.refreshResult, action.value)
            return {
                ...state,
                refreshResult: action.value
            }
        case PRICING_HISTORY:
            return {
                ...state,
                pricingHistory: action.value
            }
        case AC_PERFORMANCE:
            makeLog("AC_PERFORMANCE", state.acPerformance, action.value)
            return {
                ...state,
                acPerformance: action.value
            }
        case CPN_PERFORMANCE:
            makeLog("CPN_PERFORMANCE", state.cpnPerformance, action.value)
            return {
                ...state,
                cpnPerformance: action.value
            }
        case FINAL_PERFORMANCE:
            makeLog("FINAL_PERFORMANCE", state.finalPerformance, action.value)
            return {
                ...state,
                finalPerformance: action.value
            }
        case CURRENCY_AUTOCALL:
            makeLog("CURRENCY_AUTOCALL", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case DATE_EXPIRY_AUTOCALL:
            makeLog("DATE_EXPIRY_AUTOCALL", state.dateExpiry, action.value)
            return {
                ...state,
                dateExpiry: action.value
            }
        case PERIOD_MODE:
            makeLog("PERIOD_MODE", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case IS_CALL_PAYOFF:
            makeLog("IS_CALL_PAYOFF", state.isCallPayoff, action.value)
            return {
                ...state,
                isCallPayoff: action.value
            }
        case NOTIONAL_AUTOCALL:
            makeLog("NOTIONAL_AUTOCALL", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        case GREEKS_AUTOCALL:
            makeLog("GREEKS_AUTOCALL", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case MODEL_AUTOCALL:
            makeLog("MODEL_AUTOCALL", state.model, action.value)
            return {
                ...state,
                model: action.value
            }
        case N_SIMS_AUTOCALL:
            makeLog("N_SIMS_AUTOCALL", state.n_sims, action.value)
            return {
                ...state,
                n_sims: action.value
            }
        case EVENT_AUTOCALL:
            makeLog("EVENT_AUTOCALL", state.events, action.value)
            return {
                ...state,
                events: action.value
            }
        case SNAPSHOT_AUTOCALL:
            makeLogService("SNAPSHOT_AUTOCALL", state.snapshot, action.value)
            return {
                ...state,
                snapshot: action.value
            }
        case ALL_DATA_AUTOCALL:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}
