import {useDispatch, useSelector} from "react-redux";
import {set_ref_spots} from "../redux/Autocall/actions";
import {set_market_data_id, set_service_divs, set_service_vols, set_spots} from "../redux/MarketData/actions";
import {set_ref_spots as set_ref_spots_vanilla} from "../redux/Vanilla/actions";
import useChangeState from "./useChangeState";
import useViewMode from "./useViewMode";
import useAllMarketData from "./useAllMarketData";
import axios from "axios";

const useMarketData_do_ref = (ticker) => {
    const dispatch = useDispatch()
    const allMarketDate = useAllMarketData()
    const changeState = useChangeState()
    const viewMode = useViewMode()
    const [refSpots] = useSelector((store) => {
        const {autocallReducer} = store
        return [autocallReducer.refSpots]
    })

    const [spots, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.spots, MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const refSpotsVanilla = useSelector((store) => {
        const {VanillaReducer} = store
        return VanillaReducer.refSpots
    })

    const refSpotsBasket = useSelector((store) => {
        const {BasketReducer} = store
        return BasketReducer.refSpots
    })

    const refSpotsMD = useSelector((store) => {
        const {MarketDataProductReducer} = store
        return MarketDataProductReducer.refSpotsMD
    })

    const refSpotsDigitalCall = useSelector((store) => {
        const {digitalCallReducer} = store
        return digitalCallReducer.refSpots
    })

    const refSpotsDigitalCallShort = useSelector((store) => {
        const {digitalCallShortReducer} = store
        return digitalCallShortReducer.refSpots
    })

    const refSpotsSpreadOption = useSelector((store) => {
        const {spreadOptionReducer} = store
        return spreadOptionReducer.refSpots
    })

    const changeSpot = (value) => {
        dispatch(set_spots({...spots, [ticker]: value}))
        const tmp = JSON.parse(JSON.stringify(allMarketDate))
        tmp.spots = {...spots, [ticker]: value}
        axios.post("/rdict/post", tmp)
            .then(data => {
                dispatch(set_market_data_id(data.data))
            })
        changeState.setChange(true)
    }

    const changeRefSpot = (value) => {
        if (viewMode.viewMode === 'Autocall') {
            dispatch(set_ref_spots({...refSpots, [ticker]: value}))
        } else if (viewMode.viewMode === 'Vanilla') {
            dispatch(set_ref_spots_vanilla({[ticker]: value}))
        } else if (viewMode.viewMode === 'Call On Basket') {
            dispatch(set_ref_spots({...refSpots, [ticker]: value}))
        }
        // TODO добавить обработку для других видов

        changeState.setChange(true)
    }

    const changeVols = (value) => {
        dispatch(set_service_vols({...serviceVols, [ticker]: value}))
        const tmp = JSON.parse(JSON.stringify(allMarketDate))
        tmp.vols = {...tmp.vols, [ticker]: value}
        axios.post("/rdict/post", tmp)
            .then(data => {
                dispatch(set_market_data_id(data.data))
            })
        changeState.setChange(true)
    }

    const changeDivs = (value) => {
        dispatch(set_service_divs({...serviceDivs, [ticker]: value}))
        changeState.setChange(true)
    }

    const chooseRefSpot = () => {
        if (viewMode.viewMode === 'Autocall') {
            return refSpots[ticker]
        } else if (viewMode.viewMode === 'Vanilla') {
            return refSpotsVanilla[ticker]
        } else if (viewMode.viewMode === 'MarketData') {
            return refSpotsMD[ticker]
        } else if (viewMode.viewMode === 'Call On Basket') {
            return refSpotsBasket[ticker]
        } else if (viewMode.viewMode === 'Digital Call on Basket') {
            return refSpotsDigitalCall[ticker]
        } else if (viewMode.viewMode === "Digital Call") {
            return refSpotsDigitalCallShort[ticker]
        } else if (viewMode.viewMode === "Spread Option") {
            return refSpotsSpreadOption[ticker]
        }
    }

    return {
        spot: spots[ticker],
        changeSpot,
        refSpot: chooseRefSpot(),
        changeRefSpot,
        vol: serviceVols[ticker],
        changeVols,
        div: serviceDivs[ticker],
        changeDivs
    }
}

export default useMarketData_do_ref