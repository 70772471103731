import './SelectField.css'

const SelectField = ({products, productSelected, setProductSelector, setIsActive}) => {

    return (
        <div className="select-field">
            {products.map((elem, i) => (
                <div key={i} className={`select-field-line ${productSelected === elem ? "selected" : ''}`}
                     onClick={() => {setProductSelector(elem); setIsActive(false)}}
                >{elem}</div>
            ))}
        </div>
    )
}
export default SelectField
