import {useEffect, useState} from 'react'
import Select from '../Select/Select'
import './Input.css'
import calendar from "../../static/calendar.svg";


const Input = ({label, type, defaultValue, allValues, changeCallback, styleClassName, counter, propertyBtn, placeholder, focusOutAction, extraClass}) => {
    const [val, setVal] = useState(defaultValue)
    const focusOutAction_ = focusOutAction ? focusOutAction : () => {console.log()}
    const changeCallback_ = changeCallback ? changeCallback : (vl, c) => {
        vl.replace(".", ",")
        setVal(vl)
    }
    useEffect(() => {
        // console.log('default value was changed', val)
        setVal(defaultValue)
    }, [defaultValue])
    if (type === 'select'){
        return (
            <div className={`${styleClassName}-block`}>
                <div className={`${styleClassName}-label`}>{label}</div>
                <Select values={allValues} selected={defaultValue} setSelected={changeCallback}/>
            </div>
        )
    } else if (type === 'date') {
        const styles = {
            backgroundImage: `url("${calendar}")`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `right 12px center`
        }
        return (
            <div className={`${styleClassName}-block`}>
                <label className={`${styleClassName}-label`}>{label}</label>
                <input className={`${styleClassName} ${extraClass}`} style={{...styles, width: "100%"}} type={type} value={defaultValue} onChange={(e)=>changeCallback(e.target.value, counter)} placeholder={placeholder}/>
            </div>
        )
    }
    return (
        <div className={`${styleClassName}-block`}>
            <label className={`${styleClassName}-label`}>{label}</label>
            <input className={`${styleClassName} ${extraClass}`} style={{width: "100%"}} type={type} value={focusOutAction ? val : defaultValue} onChange={(e)=>changeCallback_(e.target.value, counter)} placeholder={placeholder} onBlur={(e) => {
                focusOutAction_(e.target.value, counter)
                console.log("unfocus f")
            }} onKeyDown={e => {
                if (e.key ===  "Enter") {
                    if (propertyBtn && propertyBtn.onClickCallback !== undefined) {
                        propertyBtn.onClickCallback(val)
                        console.log("val: ", val, "default value", defaultValue)
                        console.log("-- Enter Click --")
                        setVal("")
                    }
                }
            }}/>
            {!propertyBtn ? null : (
                <div className={`${styleClassName}-btn ${extraClass}`} onClick={() => propertyBtn.onClickCallback(val)}>
                    {propertyBtn.content === 'img' ? (
                        <img src={propertyBtn.img} alt="action input btn"/>
                    ) : (
                        <div className={`${styleClassName}-btn-text`}>{propertyBtn.text}</div>
                    )}
                </div>
                
            )}
        </div>
    )
}

export default Input