import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";
import {
    useProductDetailDigitalCallShort
} from "../../hooks/DigitalCallShortProductData/useProductDetailDigitalCallShort";

export const ProductDetailViewDigitalCallShort = () => {
    const data = useProductDetailDigitalCallShort()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}