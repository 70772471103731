import './ErrorField.css'
import img from './../../static/error.svg'
import img_close from './../../static/close.svg'
import img_success from './../../static/success.png'
import useInfoPopup from "../../hooks/useInfoPopup";

const ErrorField = () => {
    const data = useInfoPopup()
    return (
        <div className={`error-field ${data.data.popupStatus ? 'active' : ''}`}>
            <div className={'error-field-nav'} >
                <div className={`error-field-label`} >
                    <div className={`error-field-img`} >
                        <img src={data.data.popupType ? img_success : img} alt={`error icon`} />
                    </div>
                    <div className={data.data.popupType ? `error-field-success` : `error-field-label-text`} >
                        {data.data.popupType ? "Success" : "Error"}
                    </div>
                </div>
                <div onClick={() => data.setPopupStatus(false)} className={`error-field-close-btn`} >
                    <img src={img_close} alt={`close btn`} />
                </div>
            </div>
            <div className={'error-field-content'} >
                {data.data.popupContent}
            </div>
        </div>
    )
}

export default ErrorField