import {
    AC_STRIKES_DIGITAL_CALL_SHORT,
    ALL_DATA_DIGITAL_CALL_SHORT,
    CPN_PERFORMANCE_DIGITAL_CALL_SHORT,
    CPN_STRIKE_DIGITAL_CALL_SHORT,
    CPN_STRIKES_DIGITAL_CALL_SHORT,
    CURRENCY_DIGITAL_CALL_SHORT, DATE_EXPIRY_DIGITAL_CALL_SHORT,
    FIXED_CPN_AMT_DIGITAL_CALL_SHORT,
    FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT,
    FIXING_DATES_DIGITAL_CALL_SHORT,
    FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT, GREEKS_DIGITAL_CALL_SHORT,
    IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT, NOTIONAL_DIGITAL_CALL_SHORT,
    PERIOD_MODE_DIGITAL_CALL_SHORT,
    REF_SPOTS_DIGITAL_CALL_SHORT,
    SCHEDULE_FREQ_DIGITAL_CALL_SHORT,
    TICKERS_DIGITAL_CALL_SHORT,
    TIME_TO_MATURITY_DIGITAL_CALL_SHORT, TRADING_DATE_DIGITAL_CALL_SHORT
} from "./types";

export const set_tickers_digital_call_short = (value) => {
    return {
        type: TICKERS_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_ref_spots_digital_call_short = (value) => {
    return {
        type: REF_SPOTS_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_ac_strikes_digital_call_short = (value) => {
    return {
        type: AC_STRIKES_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_cpn_strikes_digital_call_short = (value) => {
    return {
        type: CPN_STRIKES_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_fixing_dates_digital_call_short = (value) => {
    return {
        type: FIXING_DATES_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_fixed_cpn_amt_digital_call_short = (value) => {
    return {
        type: FIXED_CPN_AMT_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_fixing_details_state_digital_call_short = (value) => {
    return {
        type: FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_currency_digital_call_short = (value) => {
    return {
        type: CURRENCY_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_is_without_notional_digital_call_short = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_time_to_maturity_digital_call_short = (value) => {
    return {
        type: TIME_TO_MATURITY_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_period_mode_digital_call_short = (value) => {
    return {
        type: PERIOD_MODE_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_cpn_strike_digital_call_short = (value) => {
    return {
        type: CPN_STRIKE_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_fixed_cpn_amt_pa_digital_call_short = (value) => {
    return {
        type: FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_cpn_performance_digital_call_short = (value) => {
    return {
        type: CPN_PERFORMANCE_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_schedule_freq_digital_call_short = (value) => {
    return {
        type: SCHEDULE_FREQ_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_all_data_digital_call_short = (value) => {
    return {
        type: ALL_DATA_DIGITAL_CALL_SHORT,
        value: value.product
    }
}

export const set_greeks_digital_call_short = (value) => {
    return {
        type: GREEKS_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_notional_digital_call_short = (value) => {
    return {
        type: NOTIONAL_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_trading_date_digital_call_short = (value) => {
    return {
        type: TRADING_DATE_DIGITAL_CALL_SHORT,
        value
    }
}

export const set_date_expiry_digital_call_short = (value) => {
    return {
        type: DATE_EXPIRY_DIGITAL_CALL_SHORT,
        value
    }
}