import {useSelector} from "react-redux";
import useViewMode from "./useViewMode";

const useMarketDataRefreshParams = () => {
    const viewMode = useViewMode()
    const [corrHistory, corrFloor, corrPercentile,
        corrLen, pricingDate, refreshResult,
        tradingDate, tradingDateVanilla, tradingDateMD,
        refreshMode, refreshModes, tradingDateBasket,
        tradingDateDigitalCall, tradingDateDigitalCallShort, tradingDateSpreadOption] = useSelector(state => {
        const {
            MarketDataReducer, autocallReducer, VanillaReducer,
            MarketDataProductReducer, commonReducer, BasketReducer,
            digitalCallReducer, digitalCallShortReducer, spreadOptionReducer
        } = state;
        return [MarketDataReducer.corrHistory, MarketDataReducer.corrFloor,
            MarketDataReducer.corrPercentile, MarketDataReducer.corrLen,
            MarketDataReducer.pricingDate, autocallReducer.refreshResult,
            autocallReducer.tradingDate, VanillaReducer.tradingDate,
            MarketDataProductReducer.tradingDateMD, commonReducer.refreshMode,
            commonReducer.refreshModes, BasketReducer.tradingDate, digitalCallReducer.tradingDate,
            digitalCallShortReducer.tradingDate, spreadOptionReducer.tradingDate]
    })
    const getTradingDate = () => {
        if (viewMode.viewMode === "Autocall") {
            return tradingDate
        } else if (viewMode.viewMode === "Vanilla") {
            return tradingDateVanilla
        } else if (viewMode.viewMode === "MarketData") {
            return tradingDateMD
        } else if (viewMode.viewMode === "Call On Basket") {
            return tradingDateBasket
        } else if (viewMode.viewMode === "Digital Call") {
            return tradingDateDigitalCallShort
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            return tradingDateDigitalCall
        } else if (viewMode.viewMode === "Spread Option") {
            return tradingDateSpreadOption
        }
    }

    return {
        corrHistory,
        corrFloor,
        corrPercentile,
        corrLen,
        pricingDate,
        refreshResult,
        refreshMode,
        refreshModes,
        tradingDate: getTradingDate()
    }
}

export default useMarketDataRefreshParams;