import './FixingDetails.css'
import FixingDetailsDesktop from "../../../components/FixingDetailsDesktop/FixingDetailsDesktop";
import FixingDetailsMobile from "../../../components/FixingDetailsMobile/FixingDetailsMobile";

const FixingDetails = () => {
    const isMobile = window.innerWidth < 600

    if (isMobile) return (
        <div className={`fixing_details`}>
            <FixingDetailsMobile />
        </div>
    )
    return (
        <div className={`fixing_details`}>
            <FixingDetailsDesktop />
        </div>
    )
}
export default FixingDetails