import {useDispatch, useSelector} from "react-redux";
import {set_payoff, set_strike, set_strike_abs} from "../../redux/Vanilla/actions";
import {
    set_payoff_participation,
    set_strike_abs_participation,
    set_strike_participation
} from "../../redux/Participation/actions";

const useDetailsParticipation = () => {
    const dispatch = useDispatch()
    const [payoff] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.payoff]
    })
    const [refSpots, strike, strikeAbs, tickers] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.refSpots, ParticipationReducer.strike, ParticipationReducer.strikeAbs, ParticipationReducer.tickersParticipation]
    })

    const setStrike = (value) => {
        const tmp = Number((value / 100).toFixed(2))
        const abs = Number(tmp * refSpots[tickers[0]].toFixed(2))
        dispatch(set_strike_participation(tmp))
        dispatch(set_strike_abs_participation(abs))
    }

    const setStrikeAbs = (value) => {
        const tmp = Number(((value / refSpots[tickers[0]])).toFixed(2))
        dispatch(set_strike_abs_participation(value))
        dispatch(set_strike_participation(tmp))
    }

    const setPayoff = (value) => {
        dispatch(set_payoff_participation(value))
    }

    return {
        title: "Details",
        data: [
            {
                name: "Exercise",
                value: payoff,
                onChange: setPayoff,
                type: {
                    name: "selector",
                    options: ["call", "put"]
                }
            },
            {
                name: "Strike, %",
                value: strike * 100,
                onChange: setStrike,
                type: {
                    name: "number"
                }
            },
            {
                name: "Strike, abs",
                value: strikeAbs,
                onChange: setStrikeAbs,
                type: {
                    name: "number"
                }
            }
        ]
    }
}

export default useDetailsParticipation