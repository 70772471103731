import './ModalWindow.css'
import close from './../../static/close.svg'

const ModalWindow = ({content, isActive, setIsActive, smallSize}) => {
    if (!isActive) return null
    return (
        <div className={`modal_window`} onClick={() => setIsActive(false)}>
            <div className="modal_window-close" onClick={() => setIsActive(false)}>
                <div className="modal_window-close-img"></div>
            </div>
            <div className={`modal_window-content ${smallSize ? "modal_window-tiny" : ""}`} onClick={e => e.stopPropagation()}>
                {content}
            </div>
        </div>
    )
}

export default ModalWindow