import {useDispatch, useSelector} from "react-redux";
import {set_call_up_limit, set_call_up_limit_abs} from "../../redux/Vanilla/actions";
import {set_call_up_limit_abs_participation, set_call_up_limit_participation} from "../../redux/Participation/actions";

const useCapParticipation = () => {
    const dispatch = useDispatch()
    const [callUpLimit, callUpLimitAbs, refSpots, tickers] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.callUpLimit, ParticipationReducer.callUpLimitAbs, ParticipationReducer.refSpots, ParticipationReducer.tickersParticipation]
    })

    const setCallUpLimit = (value) => {
        console.log('[setCallUpLimit] ', value, callUpLimit)
        if (value === undefined) {
            dispatch(set_call_up_limit_participation(undefined))
            dispatch(set_call_up_limit_abs_participation(undefined))
            return
        }
        const tmp = Number((value / 100).toFixed(2))
        console.log("tmp", tmp)
        const abs = Number((tmp * refSpots[tickers[0]]).toFixed(2))
        dispatch(set_call_up_limit_participation(tmp))
        dispatch(set_call_up_limit_abs_participation(abs))

    }

    const setCallUpLimitAbs = (value) => {
        console.log('[setCallUpLimitAbs] ', value, callUpLimitAbs)
        if (value === undefined) {
            dispatch(set_call_up_limit_abs_participation(undefined))
            dispatch(set_call_up_limit_participation(undefined))
            return
        }
        const tmp = Number(((value / refSpots[tickers[0]])).toFixed(2))
        console.log("tmp in abs", tmp)
        dispatch(set_call_up_limit_abs_participation(value))
        dispatch(set_call_up_limit_participation(tmp))
    }

    return {
        title: "Cap",
        data: [
            {
                name: "Upper Strike, %",
                value: callUpLimit ? callUpLimit * 100 : undefined,
                onChange: setCallUpLimit,
                type: {
                    name: "number",
                    close: true
                }
            },
            {
                name: "Upper Strike, abs",
                value: callUpLimitAbs,
                onChange: setCallUpLimitAbs,
                type: {
                    name: "number",
                    close: true
                }
            }
        ]
    }
}

export default useCapParticipation