import {useDispatch, useSelector} from "react-redux";
import useChangeState from "../useChangeState";
import useFixingDetails from "../useFixingDetails";
import {freqDaysToStr, strToFreqDays} from "../../utils/utilsFunctions";
import {
    set_date_expiry_digital_call, set_period_mode_digital_call,
    set_schedule_freq_digital_call,
    set_time_to_maturity_digital_call
} from "../../redux/DigitalCall/actions";

const useFixingsDigitalCall = ()=> {
    const dispatcher = useDispatch()
    const changeState = useChangeState()
    const fixings = useFixingDetails()

    const [timeToMaturity, scheduleFreq, dateExpiry,
        tradingDate, periodMode, snapshot] = useSelector((state) => {
        const {digitalCallReducer} = state
        return [digitalCallReducer.timeToMaturity, digitalCallReducer.scheduleFreq, digitalCallReducer.dateExpiry,
            digitalCallReducer.tradingDate, digitalCallReducer.periodMode, digitalCallReducer.snapshot]
    })

    const setTimeToMaturity = (value) => {
        if (periodMode === "days") {
            value = value / 365
        }
        changeState.setChange(true, timeToMaturity, value)
        const date = new Date(tradingDate)
        date.setDate(date.getDate() + Math.round(value * 365))
        dispatcher(set_time_to_maturity_digital_call(value))
        dispatcher(set_date_expiry_digital_call(date.toISOString().slice(0, 10)))
        fixings.refreshFixingsByFixings(value)
    }

    const setScheduleFreq = (value) => {
        // TODO проверить на правильность чисел
        const days = strToFreqDays(value)
        changeState.setChange(true, scheduleFreq, days)
        dispatcher(set_schedule_freq_digital_call(days))
        fixings.refreshFixingsByFixings(undefined, days)
    }

    const setDateExpiry = (value) => {
        const date = new Date(tradingDate)
        const diff = new Date(value) - date
        const msPerDay = 1000 * 60 * 60 * 24
        const days = Math.round(diff / msPerDay)
        dispatcher(set_date_expiry_digital_call(value))
        fixings.refreshFixingsByFixings((days / 365))
        dispatcher(set_time_to_maturity_digital_call((days / 365).toFixed(3)))
    }

    const setPeriodMode = (value) => {
        dispatcher(set_period_mode_digital_call(value))
    }

    return {
        title: "Fixings",
        data: [
            {
                name: `Term, ${periodMode}`,
                value: periodMode === "years" ? timeToMaturity : Math.round(timeToMaturity * 365),
                onChange: setTimeToMaturity,
                change: 1e-6 < Math.abs(snapshot.timeToMaturity - timeToMaturity),
                isMain: true,
                type: {
                    name: "number"
                }
            },
            {
                name: "Term, prd",
                value: periodMode,
                onChange: setPeriodMode,
                change: snapshot.periodMode !== periodMode,
                type: {
                    name: "selector",
                    options: ["years", "days"]
                }
            },
            {
                name: "Freq days",
                value: freqDaysToStr(scheduleFreq),
                onChange: setScheduleFreq,
                change: snapshot.scheduleFreq !== scheduleFreq,
                isMain: true,
                type: {
                    name: "selector",
                    options: ["mon", "qtr", "s/a", "ann"]
                }
            },
            {
                name: "Date expiry",
                value: dateExpiry,
                onChange: setDateExpiry,
                type: {
                    name: "date"
                }
            }
        ]
    }
}

export default useFixingsDigitalCall;