import {useDispatch, useSelector} from "react-redux";
import {
    set_currency,
    set_fixing_date_vanilla,
    set_fixing_dates,
    set_is_without_notional
} from "../../redux/Vanilla/actions";


const useNotionalValue = () => {
    const dispatch = useDispatch();
    const [fixingDates, isWithoutNotional, currency, currencyOptions, tradingDate] = useSelector((state) => {
        const {VanillaReducer} = state;
        return [VanillaReducer.fixingDates, VanillaReducer.isWithoutNotional, VanillaReducer.currency, VanillaReducer.currencyOptions, VanillaReducer.tradingDate]
    });



    const setIsWithoutNotional = (value) => {
        if (value === "Warrant") {
            value = true
        } else if (value === "Note") {
            value = false
        }
        dispatch(set_is_without_notional(value))
    }

    const setCurrency = (value) => {
        dispatch(set_currency(value))
    }

    return {
        title: "Notional",
        data: [
            {
                name: "Wrapper",
                value: isWithoutNotional ? "Warrant" : "Note",
                onChange: setIsWithoutNotional,
                type: {
                    name: "selector",
                    options: ["Warrant", "Note"]
                }
            },
            {
                name: "Currency",
                value: currency,
                onChange: setCurrency,
                type: {
                    name: "selector",
                    options: currencyOptions
                }
            }
        ]
    }

}

export default useNotionalValue;