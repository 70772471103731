import "./MobileNav.css"
import Modal from "../../../components/Modal/Modal";
import Link from "../Nav/Link/Link";
import {useState} from "react";
import imgClose from './../../../static/cross_32_white.svg'

const MobileNav = ({selection}) => {
    const linksName = ["Pricing", "Logs"]
    const [isActive, setIsActive] = useState(false)
    return (
        <div className={`nav-burger`}>
            <div onClick={() => {setIsActive(true)}} className="menu-burger">
                <div className="burger-line"></div>
                <div className="burger-line"></div>
                <div className="burger-line"></div>
            </div>
            <Modal isActive={isActive}>
                <div className="nav-mobile">
                    <div onClick={() => setIsActive(false)} className="close-nav">
                        <img src={imgClose} alt="close btn"/>
                    </div>
                    <Link title={"Home"} selection={selection}/>
                    {linksName.map((elem, i) => (
                        <Link key={i} title={elem} selection={selection}/>
                    ))}
                </div>
            </Modal>
        </div>
    )
}

export default MobileNav