import './VolSurface.css'
import Loader from "../Loader/Loader";
import Plot from "react-plotly.js";
import {useEffect, useState} from "react";
import axios from "axios";
import GraphList from "../GraphList/GraphList";
import {compareObjects} from "../../utils/utilsFunctions";

const VolSurface = ({mode, ticker, setIsChange, volData, tmpData, resetTrigger}) => {
    const [loading, setLoading] = useState(true)
    const [baseData, setBaseData] = useState(JSON.parse(JSON.stringify(tmpData)))
    const [legendData, setLegendData] = useState([])
    const [isFullData, setIsFullData] = useState(false)
    const x = [30, 90, 180, 365, 1095]
    // const [localChange, setLocalChange] = useState(false)
    const isMobile = window.innerWidth <= 768
    console.log("[ base state ]", baseData)

    const [traceData, setTraceData] = useState({
        x,
        y: [],
        z: [],
        type: "surface"
    })

    const [data, setData] = useState([
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.30`,
            visible: true,
            line: {
                shape: "spline",
                dash: "solid",
                color: "#4c4cd0"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.90`,
            visible: true,
            line: {
                shape: "spline",
                dash: "solid",
                color: "#FFAA00"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.180`,
            visible: true,
            line: {
                shape: "spline",
                dash: "solid",
                color: "#367c36"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.365`,
            visible: true,
            line: {
                shape: "spline",
                dash: "solid",
                color: "#8f3131"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.1095`,
            visible: true,
            line: {
                shape: "spline",
                dash: "solid",
                color: "#874f9b"
            }
        }])

    const [fullData, setFullData] = useState([
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.30`,
            visible: true,
            line: {
                shape: "spline",
                dash: "dash",
                color: "#4c4cd0"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.90`,
            visible: true,
            line: {
                shape: "spline",
                dash: "dash",
                color: "#FFAA00"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.180`,
            visible: true,
            line: {
                shape: "spline",
                dash: "dash",
                color: "#367c36"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.365`,
            visible: true,
            line: {
                shape: "spline",
                dash: "dash",
                color: "#8f3131"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}.1095`,
            visible: true,
            line: {
                shape: "spline",
                dash: "dash",
                color: "#874f9b"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}_NEW.30`,
            visible: true,
            line: {
                shape: "spline",
                color: "#2d2d7a"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}_NEW.90`,
            visible: true,
            line: {
                shape: "spline",
                color: "#a26e00"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}_NEW.180`,
            visible: true,
            line: {
                shape: "spline",
                color: "#1f491f"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}_NEW.365`,
            visible: true,
            line: {
                shape: "spline",
                color: "#541d1d"
            }
        },
        {
            x: [],
            y: [],
            type: "scatter",
            mode: 'lines',
            name: `${ticker}_NEW.1095`,
            visible: true,
            line: {
                shape: "spline",
                color: "#4a2b56"
            }
        }
    ])
    // const requestHelp = (data) => {
    //     const jsonData = JSON.parse(data)
    //     let y_ = []
    //     let z_ = []
    //     let y_30_ = []
    //     let y_90_ = []
    //     let y_180_ = []
    //     let y_365_ = []
    //     let y_1095_ = []
    //     jsonData.data.map((elem, i) => {
    //         y_ = [...y_, elem.index.toFixed(4)]
    //         z_ = [...z_, []]
    //         y_30_ = [...y_30_, elem[30]]
    //         y_90_ = [...y_90_, elem[90]]
    //         y_180_ = [...y_180_, elem[180]]
    //         y_365_ = [...y_365_, elem[365]]
    //         y_1095_ = [...y_1095_, elem[1095]]
    //         x.map((days, j) => {
    //             z_[i][j] = elem[days]
    //             return undefined
    //         })
    //         return undefined
    //     })
    //     return {
    //         y_,
    //         z_,
    //         y_30_,
    //         y_90_,
    //         y_180_,
    //         y_365_,
    //         y_1095_
    //     }
    // }

    useEffect(() => {
        console.log("useEffect resetTrigger", resetTrigger.resetTrigger, data)
        if (resetTrigger.resetTrigger) {
            console.log("resetTrigger")
            setIsFullData( false)
            resetTrigger.setResetTrigger(false)
        }
    }, [resetTrigger.resetTrigger])

    useEffect(() => {
        setLoading(true)
        const doRequest = () => {
            axios(`/market_data/volb/${JSON.stringify(volData)}?expiries=[30, 90, 180, 365, 1095]`)
                .then(data_ => {
                    const resp = JSON.parse(data_.data)
                    const tmp = data
                    const fullTmp = fullData
                    tmp.forEach(elem => {
                        const name = elem.name.split(".")[1]
                        elem.y = resp.data.map(mapEl => mapEl[Number(name)])
                        elem.x = resp.data.map(mapEl => mapEl.index.toFixed(4))
                        elem.line.dash = "solid"
                    })
                    setData(prev => tmp)
                    fullTmp.forEach(elem => {
                        const name = elem.name.split(".")
                        if (name[0].split("_")[1] !== "NEW") {
                            elem.y = resp.data.map(mapEl => mapEl[Number(name[1])])
                            elem.x = resp.data.map(mapEl => mapEl.index.toFixed(4))
                        }
                    })
                    setTraceData(prev => ({
                        ...prev,
                        y: resp.data.map(mapEl => mapEl.index.toFixed(4)),
                        z: resp.data.map(mapEl => x.map(xEl => mapEl[xEl]))
                    }))
                    setFullData(fullTmp)
                    // setData(tmp)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        doRequest()

    }, [])

    useEffect(() => {
        if (compareObjects(baseData, tmpData)) {
            setIsFullData(false)
            return
        }
        setLoading(true)
        setIsFullData(true)
        const target = {
            model: "parametric",
            short: tmpData.short,
            long: tmpData.long,
            skew: tmpData.skew,
            curve: tmpData.curve,
            speed: tmpData.speed,
            eps_curve: tmpData.eps_curve,
            eps_skew: tmpData.eps_skew
        }
        const doRequest = () => {
            axios(`/market_data/volb/${JSON.stringify(target)}?expiries=[30, 90, 180, 365, 1095]`)
                .then(data => {
                    const resp = JSON.parse(data.data)
                    const fullTmp = fullData
                    fullTmp.forEach(elem => {
                        const name = elem.name.split(".")
                        if (name[0].split("_")[1] === "NEW") {
                            elem.y = resp.data.map(mapEl => mapEl[Number(name[1])])
                            elem.x = resp.data.map(mapEl => mapEl.index.toFixed(4))
                        } else {
                            elem.line.dash = "dash"
                        }
                    })
                    setFullData(fullTmp)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        doRequest()
    }, [tmpData])


    useEffect(() => {
        if (loading) return
        console.log("legendData", legendData)
        if (legendData.length === 0) return
        legendData.forEach((elem, i) => {
            if (isFullData) {
                fullData.forEach((scatter, j) => {
                    if (scatter.name === elem.name) {
                        console.log("find")
                        fullData[j].visible = elem.visible
                    }
                })
            } else {
                data.forEach((scatter, j) => {
                    if (scatter.name === elem.name) {
                        console.log("find")
                        data[j].visible = elem.visible
                    }
                })
            }
        })
    }, [loading])


    // const trace_surface = {
    //     x: x,
    //     y: y,
    //     z: z,
    //     type: mode
    // }

    // const trace_scatter_30 = {
    //     x: y,
    //     y: y_30,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}.30`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         dash: localChange ? "dash" : "solid",
    //         color: "#4c4cd0"
    //     }
    // }
    // const trace_scatter_90 = {
    //     x: y,
    //     y: y_90,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}.90`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         dash: localChange ? "dash" : "solid",
    //         color: "#FFAA00"
    //     }
    // }
    // const trace_scatter_180 = {
    //     x: y,
    //     y: y_180,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}.180`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         dash: localChange ? "dash" : "solid",
    //         color: "#367c36"
    //     }
    // }
    // const trace_scatter_365 = {
    //     x: y,
    //     y: y_365,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}.365`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         dash: localChange ? "dash" : "solid",
    //         color: "#8f3131"
    //     }
    // }
    // const trace_scatter_1095 = {
    //     x: y,
    //     y: y_1095,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}.1095`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         dash: localChange ? "dash" : "solid",
    //         color: "#874f9b"
    //     }
    // }

    // const trace_scatter_30_new = {
    //     x: y,
    //     y: y_30_new,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}_NEW.30`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         color: "#2d2d7a"
    //     }
    // }
    // const trace_scatter_90_new = {
    //     x: y,
    //     y: y_90_new,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}_NEW.90`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         color: "#a26e00"
    //     }
    // }
    // const trace_scatter_180_new = {
    //     x: y,
    //     y: y_180_new,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}_NEW.180`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         color: "#1f491f"
    //     }
    // }
    // const trace_scatter_365_new = {
    //     x: y,
    //     y: y_365_new,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}_NEW.365`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         color: "#541d1d"
    //     }
    // }
    // const trace_scatter_1095_new = {
    //     x: y,
    //     y: y_1095_new,
    //     type: "scatter",
    //     mode: 'lines',
    //     name: `${ticker}_NEW.1095`,
    //     visible: true,
    //     line: {
    //         shape: "spline",
    //         color: "#4a2b56"
    //     }
    // }


    const layout = {
        margin: {
            l: 50,
            r: 50,
            t: 20,
            b: 20
        },
        hovermode: 'x unified',
        plot_bgcolor: "#000",
        autosize: true,
        paper_bgcolor: '#000',
        font: {
            color: "#fff"
        },
        scene: {
            xaxis: {
                gridcolor: "#fff"
            },
            yaxis: {
                gridcolor: "#fff"
            },
            zaxis: {
                gridcolor: "#fff"
            }
        },
        xaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        yaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        showlegend: !isMobile
    }

    const handleLegendClick = (e) => {
        console.log("handleLegendClick static", e.data)
        setLegendData(e.data)
    }

    console.log("rerender", isFullData)
    return (
        <div className={`graph_surf ${loading ? 'active' : ''} ${mode === 'list' ? "list" : ""}`}>
            {mode === "list"
                ? <GraphList ticker={ticker} graphData={fullData[5].y.length > 0 ? [traceData.y, ...fullData.map(el => el.name.includes("NEW") ? el.y : undefined).slice(5)] : [traceData.y, ...data.map(el => el.y)]}/>
                : <div>
                    {loading ? <Loader/> :
                        <Plot classname={`myplot`}
                              data={mode === "surface" ? [traceData] : isFullData ? fullData : data}
                              layout={layout}
                              onLegendClick={e => handleLegendClick(e)}
                              config={{
                                  responsive: true,
                                  displayModeBar: false,
                                  staticPlot: mode === 'surface' ? false : isMobile
                              }}>
                            {/*{console.log("[ MODE GRAPH ]", fullData[5].y.length > 0 ? [traceData.y, ...fullData.map(el => el.name.includes("NEW") ? el.y : undefined)].slice(5) : [traceData.y, ...data.map(el => el.y)])}*/}
                        </Plot>
                    }
                </div>
            }
        </div>
    )
}

export default VolSurface