import useNotionalDigitalCall from "./useNotionalDigitalCall";
import useFixingsDigitalCall from "./useFixingsDigitalCall";
import useCouponDigitalCall from "./useCouponDigitalCall";
import usePerformanceDigitalCall from "./usePerformanceDigitalCall";

const useProductDigitalCall = () => {
    const notional = useNotionalDigitalCall()
    const fixings = useFixingsDigitalCall()
    const coupon = useCouponDigitalCall()
    const performance = usePerformanceDigitalCall()

    return [notional, fixings, coupon, performance]
}

export default useProductDigitalCall