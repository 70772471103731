import {useDispatch, useSelector} from "react-redux";
import {
    set_ac_strikes as set_ac_strikes_autocall,
    set_cpn_strikes as set_cpn_strikes_autocall,
    set_fixing_details_state as set_fixing_details_state_autocall,
    set_fixing_dates as set_fixing_dates_autocall,
    set_fixed_cpn_amt as set_fixed_cpn_amt_autocall
} from "../redux/Autocall/actions";
import {
    set_ac_strikes_digital_call,
    set_cpn_strikes_digital_call,
    set_fixing_details_state_digital_call,
    set_fixing_dates_digital_call,
    set_fixed_cpn_amt_digital_call
} from "../redux/DigitalCall/actions";
import {daysDifference} from "../utils/utilsFunctions";
import useRequest from "./useRequest";
import useViewMode from "./useViewMode";
import {
    set_ac_strikes_digital_call_short,
    set_cpn_strikes_digital_call_short, set_fixed_cpn_amt_digital_call_short,
    set_fixing_dates_digital_call_short, set_fixing_details_state_digital_call_short
} from "../redux/DigitalCallShort/actions";
import {set_is_local_window} from "../redux/Common/actions";

const useFixingDetails = () => {
    const dispatch = useDispatch();
    const {get} = useRequest()
    const {viewMode} = useViewMode()

    const [fixingDates, acStrikes, cpnStrikes,
        fixingDetailsState, timeToMaturity, scheduleFreq,
        tradingDate, cpnAMTpa, acStrike,
        noACPeriod, acStepdown, cpnStrike,
        fixedCPNCNT, fixings] = useSelector(store => {
        const {autocallReducer, MarketDataReducer, digitalCallReducer, digitalCallShortReducer, spreadOptionReducer} = store;
        if (viewMode === "Autocall") {
            return [autocallReducer.fixingDates, autocallReducer.acStrikes, autocallReducer.cpnStrikes,
                autocallReducer.fixingDetailsState, autocallReducer.timeToMaturity, autocallReducer.scheduleFreq,
                autocallReducer.tradingDate, autocallReducer.cpnAMTpa, autocallReducer.acStrike,
                autocallReducer.noACPeriod, autocallReducer.acStepdown, autocallReducer.cpnStrike,
                autocallReducer.fixedCPNCNT, MarketDataReducer.fixings];
        } else if (viewMode === "Digital Call on Basket") {
            return [digitalCallReducer.fixingDates, digitalCallReducer.acStrikes, digitalCallReducer.cpnStrikes,
                digitalCallReducer.fixingDetailsState, digitalCallReducer.timeToMaturity, digitalCallReducer.scheduleFreq,
                digitalCallReducer.tradingDate, digitalCallReducer.cpnAMTpa, digitalCallReducer.acStrike,
                digitalCallReducer.noACPeriod, digitalCallReducer.acStepdown, digitalCallReducer.cpnStrike,
                digitalCallReducer.fixedCPNCNT, MarketDataReducer.fixings];
        } else if (viewMode === "Digital Call") {
            return [digitalCallShortReducer.fixingDates, digitalCallShortReducer.acStrikes, digitalCallShortReducer.cpnStrikes,
                digitalCallShortReducer.fixingDetailsState, digitalCallShortReducer.timeToMaturity, digitalCallShortReducer.scheduleFreq,
                digitalCallShortReducer.tradingDate, digitalCallShortReducer.cpnAMTpa, digitalCallShortReducer.acStrike,
                digitalCallShortReducer.noACPeriod, digitalCallShortReducer.acStepdown, digitalCallShortReducer.cpnStrike,
                digitalCallShortReducer.fixedCPNCNT, MarketDataReducer.fixings]
        } else if (viewMode === "Spread Option") {
            return [spreadOptionReducer.fixingDates, spreadOptionReducer.acStrikes, spreadOptionReducer.cpnStrikes,
                spreadOptionReducer.fixingDetailsState, spreadOptionReducer.timeToMaturity, spreadOptionReducer.scheduleFreq,
                spreadOptionReducer.tradingDate, spreadOptionReducer.cpnAMTpa, spreadOptionReducer.acStrike,
                spreadOptionReducer.noACPeriod, spreadOptionReducer.acStepdown, spreadOptionReducer.cpnStrike,
                spreadOptionReducer.fixedCPNCNT, MarketDataReducer.fixings]
        }
        return [[], [], [], [], 0, 0, "", 0, 0, 0, 0, 0, 0, []];
    })

    const funcGenerator = () => {
        if (viewMode === "Autocall") {
            return [
                set_ac_strikes_autocall,
                set_cpn_strikes_autocall,
                set_fixing_dates_autocall,
                set_fixed_cpn_amt_autocall,
                set_fixing_details_state_autocall
            ]
        } else if (viewMode === "Digital Call on Basket") {
            return [
                set_ac_strikes_digital_call,
                set_cpn_strikes_digital_call,
                set_fixing_dates_digital_call,
                set_fixed_cpn_amt_digital_call,
                set_fixing_details_state_digital_call
            ]
        } else if (viewMode === "Digital Call") {
            return [
                set_ac_strikes_digital_call_short,
                set_cpn_strikes_digital_call_short,
                set_fixing_dates_digital_call_short,
                set_fixed_cpn_amt_digital_call_short,
                set_fixing_details_state_digital_call_short
            ]
        }
        return [() => {}, () => {}, () => {}, () => {}, () => {}]
    }

    const [set_ac_strikes, set_cpn_strikes, set_fixing_dates, set_fixed_cpn_amt, set_fixing_details_state] = funcGenerator()

    const setFixingDates = (val, index) => {
        const tmpFd = [...fixingDates];
        tmpFd[index] = val;
        dispatch(set_fixing_dates(tmpFd));
    }

    const setAcStrikes = (val, index) => {
        const tmpAs = [...acStrikes];
        tmpAs[index] = val / 100;
        dispatch(set_ac_strikes(tmpAs));
    }

    const setCpnStrikes = (val, index) => {
        const tmpCs = [...cpnStrikes];
        tmpCs[index] = val / 100;
        dispatch(set_cpn_strikes(tmpCs));
    }

    const setFixingDetailsState = (index) => {
        const tmpState = [...fixingDetailsState];
        tmpState[index] = !tmpState[index];
        dispatch(set_fixing_details_state(tmpState));
    }

    const addFixingDetails = () => {
        const tmpFd = [...fixingDates];
        tmpFd.push(new Date().toISOString().slice(0, 10));
        dispatch(set_fixing_dates(tmpFd));

        const tmpAs = [...acStrikes];
        tmpAs.push(1);
        dispatch(set_ac_strikes(tmpAs));

        const tmpCs = [...cpnStrikes];
        tmpCs.push(1);
        dispatch(set_cpn_strikes(tmpCs));

        const tmpState = [...fixingDetailsState];
        tmpState.push(false);
        dispatch(set_fixing_details_state(tmpState));
    }

    const removeFixingDetails = () => {
        const tmpFd = [...fixingDates];
        const tmpAs = [...acStrikes];
        const tmpCs = [...cpnStrikes];
        const tmpState = [...fixingDetailsState];
        fixingDetailsState.map((item, i) => {
            if (item === true) {
                tmpFd[i] = false
                tmpAs[i] = false
                tmpCs[i] = false
            }
            return undefined
        })
        dispatch(set_fixing_dates(tmpFd.filter(item => item !== false)));
        dispatch(set_ac_strikes(tmpAs.filter(item => item !== false)));
        dispatch(set_cpn_strikes(tmpCs.filter(item => item !== false)));
        dispatch(set_fixing_details_state(tmpState.filter(item => item !== true)));
    }

    const refreshFixingDetailsFull = async () => {
        dispatch(set_is_local_window(true))
        const data = await get(
            "/utils/generate_fixings/",
            {
                params: {
                    "time2maturity": timeToMaturity === undefined ? null : Math.round(timeToMaturity * 365),
                    "freq": scheduleFreq === undefined ? null : scheduleFreq,
                    "trading_date": tradingDate
                }
            },
            () => {
            },
            () => {
            }
        )
        console.log(data, timeToMaturity, scheduleFreq, tradingDate)
        const result = JSON.parse(data)
        console.warn("RESULT", result)
        dispatch(set_fixing_dates(result))
        const freq = daysDifference(result[0], tradingDate) / 365
        dispatch(set_fixed_cpn_amt(cpnAMTpa * freq))

        const acStr = await get(
            "/utils/generate_ac_strikes/",
            {
                params: {
                    fixings_cnt: result.length,
                    ac_strike: (isNaN(acStrike) || !acStrike) ? 5 : acStrike,
                    no_ac_periods: (isNaN(noACPeriod) || !noACPeriod) ? 0 : noACPeriod,
                    ac_stepdown: (isNaN(acStepdown) || !acStepdown) ? 0 : acStepdown
                }
            },
            () => {},
            () => {}
        )

        const res_ac = JSON.parse(acStr)
        dispatch(set_ac_strikes(res_ac))

        const cpnStr = await get(
            "/utils/generate_cpn_strikes/",
            {
                params: {
                    fixings_cnt: res_ac.length,
                    cpn_strike: (isNaN(cpnStrike) || !cpnStrike) ? 0 : cpnStrike,
                    fixed_cpn_count: (isNaN(fixedCPNCNT) || !fixedCPNCNT) ? 0 : fixedCPNCNT
                }
            },
            () => {
            },
            () => {
            }
        )

        const res_cpn = JSON.parse(cpnStr)
        dispatch(set_cpn_strikes(res_cpn))
        dispatch(set_fixing_details_state([...Array(res_cpn.length)].map(() => false)))
        dispatch(set_is_local_window(false))
        return {
            fixingDates: result,
            acStrikes: res_ac,
            cpnStrikes: res_cpn,
        }
    }

    const refreshFixingsByAutocall = (newACStrike, newNoAcPeriod, newACStepdown) => {
        dispatch(set_is_local_window(true))
        console.log(newACStrike, newNoAcPeriod, newACStepdown, (newACStrike ? newACStrike : (isNaN(acStrike) || !acStrike) ? 5 : acStrike))
        get(
            "/utils/generate_ac_strikes/",
            {
                params: {
                    fixings_cnt: fixingDates.length,
                    ac_strike: newACStrike ? newACStrike : (isNaN(acStrike) || !acStrike) ? 5 : acStrike,
                    no_ac_periods: newNoAcPeriod ? newNoAcPeriod : (isNaN(noACPeriod) || !noACPeriod) ? 0 : noACPeriod,
                    ac_stepdown: newACStepdown ? newACStepdown : (isNaN(acStepdown) || !acStepdown) ? 0 : acStepdown
                }
            },
            (data) => {
                const res_ac = JSON.parse(data)
                dispatch(set_ac_strikes(res_ac))
            },
            (err) => console.error(err)
        )
            .then(() => {
                console.log(" __ GET AC STRIKES __ ")
                dispatch(set_is_local_window(false))
            })
    }

    const refreshFixingsByFixings = (newT2M, newFreq, newTradingDate) => {
        dispatch(set_is_local_window(true))

        const t2m = newT2M ?? timeToMaturity
        get(
            "/utils/generate_fixings/",
            {
                params: {
                    "time2maturity": Math.round(t2m * 365),
                    "freq": newFreq ?? scheduleFreq,
                    "trading_date": newTradingDate ?? tradingDate
                }
            },
            (data) => {
                const result = JSON.parse(data)
                dispatch(set_fixing_dates(result))
                const freq = daysDifference(result[0], tradingDate) / 365
                dispatch(set_fixed_cpn_amt(cpnAMTpa * freq))
                console.warn("[ DEBUG 3 ]", acStrike, acStrike ?? 500, noACPeriod ?? 0, acStepdown ?? 0)
                get(
                    "/utils/generate_ac_strikes/",
                    {
                        params: {
                            fixings_cnt: result.length,
                            ac_strike: (isNaN(acStrike) || !acStrike) ? 5 : acStrike,
                            no_ac_periods: (isNaN(noACPeriod) || !noACPeriod) ? 0 : noACPeriod,
                            ac_stepdown: (isNaN(acStepdown) || !acStepdown) ? 0 : acStepdown
                        }
                    },
                    (data) => {
                        const res_ac = JSON.parse(data)
                        dispatch(set_ac_strikes(res_ac))
                        get(
                            "/utils/generate_cpn_strikes/",
                            {
                                params: {
                                    fixings_cnt: res_ac.length,
                                    cpn_strike: (isNaN(cpnStrike) || !cpnStrike) ? 0 : cpnStrike,
                                    fixed_cpn_count: (isNaN(fixedCPNCNT) || !fixedCPNCNT) ? 0 : fixedCPNCNT
                                }
                            },
                            (data) => {
                                const res_cpn = JSON.parse(data)
                                dispatch(set_cpn_strikes(res_cpn))
                                dispatch(set_fixing_details_state([...Array(res_cpn.length)].map(() => false)))
                            },
                            (err) => console.error(err)
                        )
                            .then(() => {
                                console.log(" __ GET CPN STRIKES __ ")
                                dispatch(set_is_local_window(false))
                            })
                    },
                    (err) => console.error(err)
                )
                    .then(() => console.log(" __ GET AC STRIKES __ "))
            },
            (err) => console.error(err)
        )
            .then(() => console.log(" __ GET FIXINGS __ "))

    }

    const refreshFixingsByCoupon = (newCpnStrike, newFixedCpnCnt) => {
        dispatch(set_is_local_window(true))
        console.log(newCpnStrike, newFixedCpnCnt, (newCpnStrike ? newCpnStrike : isNaN(cpnStrike) || !cpnStrike) ? 0 : cpnStrike, (newFixedCpnCnt ? newFixedCpnCnt : isNaN(fixedCPNCNT) || !fixedCPNCNT) ? 0 : fixedCPNCNT)
        get(
            "/utils/generate_cpn_strikes/",
            {
                params: {
                    fixings_cnt: acStrikes.length,
                    cpn_strike: newCpnStrike ? newCpnStrike : (isNaN(cpnStrike) || !cpnStrike) ? 0 : cpnStrike,
                    fixed_cpn_count: newFixedCpnCnt ? newFixedCpnCnt : (isNaN(fixedCPNCNT) || !fixedCPNCNT) ? 0 : fixedCPNCNT
                }
            },
            (data) => {
                const res_cpn = JSON.parse(data)
                dispatch(set_cpn_strikes(res_cpn))
                dispatch(set_fixing_details_state([...Array(res_cpn.length)].map(() => false)))
            },
            (err) => console.error(err)
        )
            .then(() => {
                console.log(" __ GET CPN STRIKES __ ")
                dispatch(set_is_local_window(false))
            })
    }


    return {
        fixingDates,
        setFixingDates,
        acStrikes,
        setAcStrikes,
        cpnStrikes,
        setCpnStrikes,
        fixingDetailsState,
        setFixingDetailsState,
        addFixingDetails,
        removeFixingDetails,
        refreshFixingDetailsFull,
        refreshFixingsByAutocall,
        refreshFixingsByFixings,
        refreshFixingsByCoupon,
        fixings
    }
}

export default useFixingDetails;