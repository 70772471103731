// Импортируем стили для кнопки
import './Button.css'

/**
 * Компонент Button для создания кнопки с пользовательскими стилями и поведением.
 *
 * @param {Object} props Объект свойств, передаваемый компоненту.
 * @param {string} props.text Текст, который будет отображаться на кнопке.
 * @param {function} props.callback Функция обратного вызова, которая будет вызываться при клике на кнопку.
 * @param {Object} props.style Объект стилей, который будет применен к кнопке.
 * @param {string} props.styleClass Класс для стилизации кнопки, может быть использован для добавления специфичных стилей.
 *
 * @returns {JSX.Element} Возвращает JSX элемент, представляющий кнопку.
 */
const Button = ({text, callback, style, styleClass}) => {
    return (
        // Создаем кнопку с динамическим классом, стилями и обработчиком нажатия
        <button
            className={`${styleClass} button`} // Объединяем класс из props и базовый класс 'button'
            onClick={callback} // Присваиваем обработчик клика, который активирует функцию callback
            style={style} // Применяем инлайн-стили, переданные через props
        >
            {text} {/*Отображаем текст кнопки*/}
        </button>
    )
}

// Экспортируем компонент Button для использования в других частях приложения
export default Button
