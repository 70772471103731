import './InputGraph.css'
import {useEffect, useState} from "react";
import propIcon from "./../../static/property.svg"

const InputGraph = ({value, changeCallback, openFunc, volChange}) => {
    const [localValue, setLocalValue] = useState("")
    useEffect(() => {
        if (value !== undefined) {
            setLocalValue(value.toString())
        }

    }, [value])

    const save = () => {
        let saveValue = value
        if (localValue !== "") {
            saveValue = localValue
        }
        saveValue = parseFloat(saveValue)
        if (isNaN(saveValue)) {
            saveValue = value
        }
        setLocalValue(saveValue)
        changeCallback(saveValue)
    }

    const blurAction = () => {
        save()
    }

    const KeyDownAction = (e) => {
        if (e.key === "Enter") {
            save()
            e.target.blur()
        }
    }

    return (
        <div className={"input_graph"}>
            <div className={"input_graph-wrapper"}>
                <input type="text"
                       className={`input_graph-field ${volChange ? 'input_graph-field--changed' : ''}`}
                       value={localValue}
                       onChange={(e) => setLocalValue(e.target.value)}
                       onBlur={() => blurAction()}
                       onKeyDown={(e) => KeyDownAction(e)}
                />
                <div className="input_graph-btn" onClick={openFunc}>
                    <img src={propIcon} alt="Property Icon"/>
                </div>
            </div>
        </div>
    )
}

export default InputGraph