import {
    ALL_DATA_MARKET_DATA_PRODUCT,
    REF_SPOTS_MARKET_DATA_PRODUCT,
    TICKERS_MARKET_DATA_PRODUCT,
    TRADING_DATE_MARKET_DATA_PRODUCT
} from "./types";

export const set_tickers_market_data_product = (value) => {
    return {
        type: TICKERS_MARKET_DATA_PRODUCT,
        value
    }
}

export const set_ref_spots_market_data_product = (value) => {
    return {
        type: REF_SPOTS_MARKET_DATA_PRODUCT,
        value
    }
}

export const set_trading_date_market_data_product = (value) => {
    return {
        type: TRADING_DATE_MARKET_DATA_PRODUCT,
        value
    }
}

export const set_all_data_market_data_product = (value) => {
    return {
        type: ALL_DATA_MARKET_DATA_PRODUCT,
        value: value.product
    }
}
