// Market Data Types
export const PRICING_DATE = 'PRICING_DATE'

export const RATE = 'RATE'

export const SPOTS = 'SPOTS'

export const CORR_MTX = 'CORR_MTX'

export const FIXINGS = 'FIXINGS'

export const VOLS = 'VOLS'

export const DIVS = 'DIVS'

export const SERVICE_VOLS = "SERVICE_VOLS"

export const SERVICE_DIVS = "SERVICE_DIVS"

export const CORR_FLOOR = "CORR_FLOOR"

export const CORR_HISTORY = "CORR_HISTORY"

export const CORR_PERCENTILE = "CORR_PERCENTILE"

export const CORR_LEN = "CORR_LEN"

export const MARKET_DATA_ID = "MARKET_DATA_ID"

export const CHANGE_STATE_MARKETDATA = "CHANGE_STATE_MARKETDATA"

export const ALL_DATA_MARKETDATA = "ALL_DATA_MARKETDATA"

export const COMMON_EVENT = "COMMON_EVENT"
