import {
    PRICING_DATE,
    RATE,
    SPOTS,
    CORR_MTX,
    FIXINGS,
    VOLS,
    DIVS,
    SERVICE_VOLS,
    SERVICE_DIVS,
    CORR_HISTORY,
    CORR_FLOOR,
    CORR_PERCENTILE,
    CORR_LEN,
    MARKET_DATA_ID,
    CHANGE_STATE_MARKETDATA,
    ALL_DATA_MARKETDATA, COMMON_EVENT
} from "./types";

export const set_pricing_date = (value) => {
    console.log("{ change pricing date }", value)
    return {
        type: PRICING_DATE,
        value
    }
}

export const set_rate = (value) => {
    return {
        type: RATE,
        value
    }
}

export const set_spots = (value) => {

    console.log("{ change spots }", value)
    return {
        type: SPOTS,
        value
    }
}

export const set_corr_mtx = (value) => {
    console.log("{ change corr mtx }", value)
    return {
        type: CORR_MTX,
        value
    }
}

export const set_fixings = (value) => {
    return {
        type: FIXINGS,
        value
    }
}

export const set_vols = (value) => {
    return {
        type: VOLS,
        value
    }
}

export const set_divs = (value) => {
    return {
        type: DIVS,
        value
    }
}

export const set_service_vols = value => {
    return {
        type: SERVICE_VOLS,
        value
    }
}

export const set_service_divs = value => {
    return {
        type: SERVICE_DIVS,
        value
    }
}

export const set_corr_floor = value => {
    return {
        type: CORR_FLOOR,
        value
    }
}

export const set_corr_history = value => {
    return {
        type: CORR_HISTORY,
        value
    }
}

export const set_corr_percentile = value => {
    return {
        type: CORR_PERCENTILE,
        value
    }
}

export const set_corr_len = value => {
    return {
        type: CORR_LEN,
        value
    }
}

export const set_market_data_id = value => {
    return {
        type: MARKET_DATA_ID,
        value
    }
}

export const set_change_state_marketdata = value => {
    return {
        type: CHANGE_STATE_MARKETDATA,
        value
    }
}

export const set_all_data_marketdata = value => {
    return {
        type: ALL_DATA_MARKETDATA,
        value: value.marketData
    }
}

export const set_common_event = value => {
    return {
        type: COMMON_EVENT,
        value
    }
}
