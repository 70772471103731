import SettingsRecalculate from "./SettingsRecalculate";
import "./SettingsAutocall.css"
import DownloadAll from "../../DownloadAll/DownloadAll";
import SettingsRefreshField from "../SettingsRefreshField/SettingsRefreshField";
import SettingsMarketData from "./SettingsMarketData";
import SettingsSystem from "./SettingsSystem";
import useRefreshMode from "../../../hooks/useRefreshMode";

const SettingsAutocall = () => {
    const role = localStorage.getItem("oAuth_pricing")
    return (
        <>
            <div className="settings__block">
                <div className={"settings__block--subtitle"}>Pricing</div>
                <SettingsRecalculate/>
            </div>
            {(role !== "tiny") &&
                <div className="settings__block">
                <div className={"settings__block--subtitle"}>Market Data</div>
                <SettingsMarketData/>
            </div>}
            <div className="settings__block">
                <div className={"settings__block--subtitle"}>System</div>
                <SettingsSystem/>
            </div>
            <div className="settings__block">
                <div className={"settings__block--subtitle"}>Other</div>
                <DownloadAll/>
            </div>
            {/*<div className="settings__block">*/}
            {/*    <div className={"settings__block--subtitle"}>Outputs</div>*/}
            {/*    <SettingsRefreshField />*/}
            {/*</div>*/}
        </>
    )
}

export default SettingsAutocall;