import {useDispatch, useSelector} from "react-redux";
import {
    set_call_up_limit,
    set_call_up_limit_abs,
    set_fixing_date_vanilla,
    set_strike,
    set_strike_abs
} from "../redux/Vanilla/actions";
import useViewMode from "./useViewMode";
import {
    set_call_up_limit_abs_participation,
    set_call_up_limit_participation, set_fixing_date_participation,
    set_strike_abs_participation, set_strike_participation
} from "../redux/Participation/actions";

const useSecondRefresh = () => {
    const dispatch = useDispatch();
    const viewMode = useViewMode()
    const [refSpots, callUpLimit, callUpLimitAbs,
        strike, strikeAbs, tickers,
        tradingDate, fixingDates, fixingDate,
        leverageRate] = useSelector(state => {
        const {VanillaReducer, ParticipationReducer} = state;
        if (viewMode.viewMode === "Vanilla") {
            return [VanillaReducer.refSpots, VanillaReducer.callUpLimit, VanillaReducer.callUpLimitAbs,
                VanillaReducer.strike, VanillaReducer.strikeAbs, VanillaReducer.tickersVanilla,
                VanillaReducer.tradingDate, VanillaReducer.fixingDates, VanillaReducer.fixingDate,
            undefined];
        } else if (viewMode.viewMode === "Protected Participation") {
            return [ParticipationReducer.refSpots, ParticipationReducer.callUpLimit, ParticipationReducer.callUpLimitAbs,
            ParticipationReducer.strike, ParticipationReducer.strikeAbs, ParticipationReducer.tickersParticipation,
            ParticipationReducer.tradingDate, ParticipationReducer.fixingDates, ParticipationReducer.fixingDate,
            ParticipationReducer.leverage_rate]
        }
        return [undefined, undefined, undefined,
        undefined, undefined, undefined,
        undefined, undefined, undefined,
        undefined]

    });
    const refreshVanilla = () => {
        const spot = refSpots[tickers[0]];
        console.log(" second, spot ", spot)
        if (callUpLimit !== undefined) {
            dispatch(set_call_up_limit_abs(callUpLimit))
            dispatch(set_call_up_limit(callUpLimit / spot))
        }
        dispatch(set_strike_abs(spot))
        dispatch(set_strike(1))
        const tmp = new Date(tradingDate)
        tmp.setDate(tmp.getDate() + fixingDates)
        dispatch(set_fixing_date_vanilla([tmp.toISOString().slice(0, 10)]))
    }

    const refreshParticipation = () => {
        const spot = refSpots[tickers[0]];
        console.log(" second, spot ", spot)
        if (callUpLimit !== undefined) {
            dispatch(set_call_up_limit_abs_participation(callUpLimit))
            dispatch(set_call_up_limit_participation(callUpLimit / spot))
        }
        dispatch(set_strike_abs_participation(spot))
        dispatch(set_strike_participation(1))
        const tmp = new Date(tradingDate)
        tmp.setDate(tmp.getDate() + fixingDates)
        dispatch(set_fixing_date_participation([tmp.toISOString().slice(0, 10)]))
    }

    return {
        refreshVanilla,
        refreshParticipation
    }
}


export default useSecondRefresh;