import {
    ALL_DATA_MARKETDATA,
    CHANGE_STATE_MARKETDATA, COMMON_EVENT,
    CORR_FLOOR,
    CORR_HISTORY, CORR_LEN, CORR_MTX, CORR_PERCENTILE,
    DIVS,
    FIXINGS, MARKET_DATA_ID,
    PRICING_DATE,
    RATE,
    SERVICE_DIVS,
    SERVICE_VOLS,
    SPOTS,
    VOLS
} from "./types";
import {makeLog, makeLogService} from "../../utils/logger";

const initialState = {
    pricingDate: new Date().toISOString().slice(0, 10),
    rate: 0.08,
    spots: {
        "MGNT": 100,
        "MTSS": 100,
        "OZON": 100,
        "SMLT": 100,
        "YNDX": 100
    },
    corrMtx: {
        "MGNT": {"MGNT": 1, "MTSS": 1, "OZON": 1, "SMLT": 1, "YNDX": 1},
        "MTSS": {"MGNT": 1, "MTSS": 1, "OZON": 1, "SMLT": 1, "YNDX": 1},
        "OZON": {"MGNT": 1, "MTSS": 1, "OZON": 1, "SMLT": 1, "YNDX": 1},
        "SMLT": {"MGNT": 1, "MTSS": 1, "OZON": 1, "SMLT": 1, "YNDX": 1},
        "YNDX": {"MGNT": 1, "MTSS": 1, "OZON": 1, "SMLT": 1, "YNDX": 1}
    },
    fixings: [],
    vols: {
        "MGNT": {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1,
            "ref_spot": 100
        },
        "MTSS": {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1,
            "ref_spot": 100
        },
        "OZON": {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1,
            "ref_spot": 100
        },
        "SMLT": {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1,
            "ref_spot": 100
        },
        "YNDX": {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1,
            "ref_spot": 100
        }
    },
    divs: {
        "MGNT": [{"date": "2023-07-31", "div_amt": 20, "quarter": 1}],
        "MTSS": [{"date": "2023-08-01", "div_amt": 20, "quarter": 1}],
        "OZON": [{"date": "2023-08-02", "div_amt": 20, "quarter": 1}],
        "SMLT": [{"date": "2023-08-03", "div_amt": 20, "quarter": 1}],
        "YNDX": [{"date": "2023-08-04", "div_amt": 20, "quarter": 1}]
    },
    serviceVols: {
        "MGNT": '📈',
        "MTSS": '📈',
        "OZON": '📈',
        "SMLT": '📈',
        "YNDX": '📈'
    },
    serviceDivs: {
        "MGNT": '📈',
        "MTSS": '📈',
        "OZON": '📈',
        "SMLT": '📈',
        "YNDX": '📈'
    },
    corrFloor: 0.7, // отправляется как параметр запроса на обновление данных
    corrHistory: 365, // отправляется как параметр запроса на обновление данных
    corrPercentile: 0.9,
    corrLen: 20,
    marketDataId: "",
    version: "v1.0_20230407",
    changeState: false,
    events: {}
}

const myCompare = (a, b) => {
    if (typeof a !== typeof b) {
        return false
    }
    if (typeof a === 'object') {
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false
        }
        for (let key in a) {
            if (!myCompare(a[key], b[key])) {
                return false
            }
        }
        return true
    }
    return a === b
}


const changeState = (original, newValue) => {
    if (myCompare(original, newValue)) {
        return initialState.changeState
    }
    return true
}

export const MarketDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRICING_DATE:
            makeLog("PRICING_DATE", state.pricingDate, action.value)
            return {
                ...state,
                pricingDate: action.value,
            }
        case RATE:
            makeLog("RATE", state.rate, action.value)
            return {
                ...state,
                rate: action.value,
            }
        case SPOTS:
            makeLog("SPOTS", state.spots, action.value)
            return {
                ...state,
                spots: action.value,
            }
        case CORR_MTX:
            makeLog("CORR_MTX", state.corrMtx, action.value)
            return {
                ...state,
                corrMtx: action.value,
            }
        case FIXINGS:
            makeLog("FIXINGS", state.fixings, action.value)
            return {
                ...state,
                fixings: action.value,
            }
        case VOLS:
            makeLog("VOLS", state.vols, action.value)
            return {
                ...state,
                vols: action.value,
            }
        case DIVS:
            makeLog("DIVS", state.divs, action.value)
            return {
                ...state,
                divs: action.value,
            }
        case SERVICE_VOLS:
            makeLogService("SERVICE_VOLS", state.serviceVols, action.value)
            return {
                ...state,
                serviceVols: action.value,
            }
        case SERVICE_DIVS:
            makeLogService("SERVICE_DIVS", state.serviceDivs, action.value)
            return {
                ...state,
                serviceDivs: action.value,
            }
        case CORR_FLOOR:
            makeLog("CORR_FLOOR", state.corrFloor, action.value)
            return {
                ...state,
                corrFloor: action.value,
            }
        case CORR_HISTORY:
            makeLog("CORR_HISTORY", state.corrHistory, action.value)
            return {
                ...state,
                corrHistory: action.value,
            }
        case CORR_PERCENTILE:
            makeLog("CORR_PERCENTILE", state.corrPercentile, action.value)
            return {
                ...state,
                corrPercentile: action.value,
            }
        case CORR_LEN:
            makeLog("CORR_LEN", state.corrLen, action.value)
            return {
                ...state,
                corrLen: action.value,
            }
        case MARKET_DATA_ID:
            makeLogService("MARKET_DATA_ID", state.marketDataId, action.value)
            return {
                ...state,
                marketDataId: action.value
            }
        case CHANGE_STATE_MARKETDATA:
            return {
                ...state,
                changeState: action.value
            }
        case ALL_DATA_MARKETDATA:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        case COMMON_EVENT:
            return {
                ...state,
                events: action.value
            }
        default:
            return state
    }
}
