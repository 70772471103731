import useMainView from "./useMainView";
import useViewMode from "./useViewMode";
import {useSelector} from "react-redux";

const useSystemData = () => {
    const viewMode = useViewMode()
    const [model, nSims] = useSelector(store => {
        const {autocallReducer, VanillaReducer, BasketReducer, ParticipationReducer, digitalCallReducer} = store
        if (viewMode.viewMode === "Autocall") {
            return [autocallReducer.model, autocallReducer.n_sims]
        } else if (viewMode.viewMode === "Vanilla") {
            return [VanillaReducer.model, VanillaReducer.n_sims]
        } else if (viewMode.viewMode === "Call On Basket") {
            return [BasketReducer.model, BasketReducer.n_sims]
        } else if (viewMode.viewMode === "Protected Participation") {
            return [ParticipationReducer.model, ParticipationReducer.n_sims]
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            return [digitalCallReducer.model, digitalCallReducer.n_sims]
        } else {
            return ["LocalVol", undefined]
        }

    })

    return {
        model,
        n_sims: nSims
    }
}

export default useSystemData