import NavSelect from "./NavSelect/NavSelect";
import './ProductNav.css'

const ProductNav = ({mode, setMode}) => {
    return (
        <div className="product-nav">
            <NavSelect
                mode={mode}
                setMode={setMode}
            />
        </div>
    )
}
export default ProductNav