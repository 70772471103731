import {useDispatch, useSelector} from "react-redux";
import {set_market_data_id} from "../redux/MarketData/actions";

const useMarketDataId = () => {
    const dispatch = useDispatch()
    const marketDataId = useSelector(state => {
        const {MarketDataReducer} = state;
        return MarketDataReducer.marketDataId
    });

    const setMarketDataId = (value) => {
        dispatch(set_market_data_id(value))
    }

    return {
        marketDataId,
        setMarketDataId
    }
}

export default useMarketDataId;