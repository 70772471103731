import './PricingRequestSearch.css'
import useDates from "../../../../hooks/MarketData/useDates";
import InputDate from "../../../../components/InputDate/InputDate";

/**
 * Component for input trading and pricing dates
 * @returns {JSX.Element}
 * @constructor
 */
const   PricingRequestSearch = () => {
    const data = useDates()

    return (
        <div className={`pricing-request-search`}>
            <div className={'pricing-request_date-block'}>
                <div className={'pricing-request_date-block__item'}>
                    <InputDate withIcon title={"Pricing date"} value={data.pricingDate} changeCallback={val => data.setPricingDate(val)}/>
                </div>
                <div className={'pricing-request_date-block__item'}>
                    <InputDate withIcon title={" Trading Date"} value={data.tradingDate} changeCallback={val => data.setTradingDate(val)}/>
                </div>
            </div>
        </div>
    )
}
export default PricingRequestSearch