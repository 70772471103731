import './MarketDataRefresh.css'
import Button from "../Button/Button";
import Select from "../Select/Select";
import searchImg from "../../static/refresh.svg";
import copyImg from "../../static/copy.svg";
import axios from "axios";
import useMarketDataRefresh from "../../hooks/useMarketDataRefresh";
import {useEffect, useState} from "react";
import useMarketDataId from "../../hooks/useMarketDataId";
import InputText from "../InputText/InputText";
import useViewMode from "../../hooks/useViewMode";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import useInfoPopup from "../../hooks/useInfoPopup";
import {useDispatch, useSelector} from "react-redux";
import {set_tickers_market_data_product} from "../../redux/MarketDataProduct/actions";
import useRefreshMode from "../../hooks/useRefreshMode";
import useRefreshFields from "../../hooks/useRefreshFields";
import copy from "clipboard-copy";
import {makeLog} from "../../utils/logger";

const MarketDataRefresh = () => {
    const dispatch = useDispatch()
    const updateData = useMarketDataRefresh()
    const refreshManager = useRefreshMarketData()
    const viewMode = useViewMode()
    const popup = useInfoPopup()
    const marketDataId = useMarketDataId()
    const [modalActive, setModalActive] = useState(false)
    const refreshMode = useRefreshMode()
    const refreshFields = useRefreshFields()
    const changeState = useSelector(state => state.MarketDataReducer.changeState)
    const role = localStorage.getItem("oAuth_pricing")
    const selectOptions = refreshMode.refreshModes.map(el => {
        if (typeof el === "string") {
            return el
        }
        if (el.roles.includes(role)) {
            return el.name
        }
    }).filter(el => el !== undefined)


    useEffect(() => {
        setTimeout(() => {
            setModalActive(false)
        }, 7000)
    }, [modalActive])

    useEffect(() => {
        console.log("[ marketDataId.marketDataId ]", marketDataId.marketDataId)
    }, [marketDataId.marketDataId])

    const refreshMD = (mode) => {
        refreshManager.refreshMD(undefined, mode)
            .catch(e => {
                popup.setPopupStatus(true, `Error refresh market_data: ${e}`, false)
            })
    }

    useEffect(() => {
        if (refreshManager.isRefresh) {
            if (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") {
                popup.setPopupStatus(true, `Success refresh all market_data`, true)
            } else {
                const refreshFieldsList = []
                for (let key in refreshFields.refreshFields) {
                    if (refreshFields.refreshFields[key]) {
                        refreshFieldsList.push(key)
                    }
                }
                popup.setPopupStatus(true, `Success refresh ${refreshFieldsList.join(", ")} market_data`, true)
            }

            refreshManager.setIsRefresh(false)
        }
    }, [refreshManager.isRefresh])

    const updateMD = () => {
        axios(`/rdict/get/${marketDataId.marketDataId}`)
            .then(data => {
                data = data.data
                console.warn(data, Object.keys(data).length, Object.keys(data.spots))
                if (Object.keys(data).length !== 0) {
                    updateData.saveMarketData(data, {tickers: Object.keys(data.spots)})
                    console.log("[ viewMode.viewMode ]", viewMode.viewMode)
                    if (viewMode.viewMode === "MarketData") {
                        dispatch(set_tickers_market_data_product(Object.keys(data.spots)))
                    }
                    popup.setPopupStatus(true, "Success updated market_data", true)
                } else {
                    popup.setPopupStatus(true, "Wrong Market Data ID", false)
                }
            })
            .catch(() => {
                popup.setPopupStatus(true, "Wrong Market Data ID", false)
            })
    }

    const changeRefreshMode = (value) => {
        if (refreshMode.refreshMode !== value) {
            if (value === "CUSTOM") {
                popup.setPopupStatus(true, "Switched source of refresh MarketData to CUSTOM", true)
            } else if (value === "DEFAULT") {
                popup.setPopupStatus(true, "Switched source of refresh MarketData to " + value, true)
                console.log("[ update refresh fields ]")
                refreshFields.resetAllFields()
            } else if (value === "HEDGING") {
                popup.setPopupStatus(true, "Switched source of refresh MarketData to " + value, true)
                console.log("[ update refresh fields ]")
                refreshFields.resetAllFields()
            }
            refreshMode.setRefreshMode(value)
            if (value === "DEFAULT" || value === "HEDGING") {
                refreshMD(value)
            }
        }
    }

    return (
        <div className={`market-data-search`}>
            <Button callback={() => {
                makeLog("__refresh_md__", "CLICK", "CLICK")
                refreshMD(refreshMode.refreshMode)
            }} text={refreshMode.refreshMode === "CUSTOM" ? "Refresh" : `Refresh all`}
                    styleClass={'market-data_refresh-btn'}/>
            <div className={`market-data-source-n-id`}>
                <div className={`market-data-search-source`}>
                    <Select label={`Source`} selected={refreshMode.refreshMode}
                            setSelected={(e) => changeRefreshMode(e)}
                            values={selectOptions}/>
                </div>
                <div className={`market-data-search-field ${changeState ? "through-line" : ""}`}>
                    <InputText value={marketDataId.marketDataId} changeCallback={e => marketDataId.setMarketDataId(e)}
                               title={`Market Data ID`}/>
                    <div className={`market-data-search-btn`}
                         onClick={() => updateMD()}>
                        <img src={searchImg} alt=""/>
                    </div>
                    <div className={`market_data_search_copy`}
                         onClick={() => {
                             copy(marketDataId.marketDataId)
                                 .then(() => {
                                     popup.setPopupStatus(true, "Copied to clipboard", true)
                                 })
                         }}>
                        <img src={copyImg} alt="copy"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketDataRefresh