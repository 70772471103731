import Link from "./Link/Link";
import "./Nav.css"
const Nav = ({selection}) => {

    const linksName = ["Pricing", "Logs"] // "Charts","Volb","Index", "Home",
    return (
        <div className="nav">
            {linksName.map((elem, i) => (
                <Link key={i} title={elem} selection={selection}/>
            ))}
        </div>
    )
}
export default Nav