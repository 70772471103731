import {useDispatch, useSelector} from "react-redux";
import {set_currency_participation, set_is_without_notional_participation} from "../../redux/Participation/actions";

const useNotionalParticipation = () => {
    const dispatch = useDispatch();
    const [fixingDates, isWithoutNotional, currency, currencyOptions, tradingDate] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.fixingDates, ParticipationReducer.isWithoutNotional, ParticipationReducer.currency, ParticipationReducer.currencyOptions, ParticipationReducer.tradingDate]
    });



    const setIsWithoutNotional = (value) => {
        if (value === "Warrant") {
            value = true
        } else if (value === "Note") {
            value = false
        }
        dispatch(set_is_without_notional_participation(value))
    }

    const setCurrency = (value) => {
        dispatch(set_currency_participation(value))
    }

    return {
        title: "Notional",
        data: [
            // {
            //     name: "Wrapper",
            //     value: isWithoutNotional ? "Warrant" : "Note",
            //     onChange: setIsWithoutNotional,
            //     type: {
            //         name: "selector",
            //         options: ["Warrant", "Note"]
            //     }
            // },
            {
                name: "Currency",
                value: currency,
                onChange: setCurrency,
                type: {
                    name: "selector",
                    options: currencyOptions
                },
                extra: "small"
            }
        ]
    }

}

export default useNotionalParticipation