import './graph.css'

export class GraphBuilder {
    constructor() {
        this.data = undefined
        this.settings = undefined;
        this.calc = undefined;
        this.flow = undefined;
        this.name = undefined;
        this.table = undefined;
        this.table_result = undefined;
        this.result = [];
        this.metrics = {
            last_value: {
                date: undefined,
                value: []
            }
        }
    }

    #transformDates = () => {
        if (this.data.graph) {
            for (let i = 0; i < this.data.graph.length; i++) {
                this.data.graph[i].x = this.data.graph[i].x.map(item => {
                    if (typeof item === "number") return item
                    item = new Date(item)
                    return new Date(item - item.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
                })
            }
        }
    }

    #parsingData = () => {
        if (typeof this.data === 'string') {
            this.data = JSON.parse(this.data.replaceAll("NaN", "null"))
        }
    }

    #prepareTable() {
        this.table_result = (
            <div>
                {this.table.map((el, i) => (
                    <div>
                        <div className="graph-table__head">
                            {el.headers.map((header, j) => (
                                <div>{header}</div>
                            ))}
                        </div>

                        {el.rows.map((row, j) => (
                            <div className="graph-table__row">
                                {row.map((cell, k) => (
                                    <div>{cell}</div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    }

    setData(data) {
        console.warn(data.data)
        this.data = data.data
        this.#parsingData()
        this.#transformDates()
        this.table = this.data.table
        console.warn("Table", this.table)
        //     [{
        //     headers: ["Date", "Value", "Delta"],
        //     rows: [[123, 321, 3], [123, 321, "qwe"], [123, 321, "ewq"]]
        // },{
        //     headers: ["Date", "Value", "Delta"],
        //     rows: [[123, 321, 3], [123, 321, "qwe"], [123, 321, "ewq"]]
        // }]

        // if (this.table) {
        //     this.#prepareTable()
        // }
        if (this.data.graph) {
            this.result = this.data.graph
        }
        console.warn(this.result)
    }

    getTable() {
        this.#prepareTable()
        return this.table_result
    }

    preparedData() {
        return this.data.graph
    }
}