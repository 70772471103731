import {useSelector} from "react-redux";

const useCallOnBasketSnapshot = () => {
    return useSelector((store) => {
        const {BasketReducer, MarketDataReducer, commonReducer} = store
        return {
            product: BasketReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}

export default useCallOnBasketSnapshot;