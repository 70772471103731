import {useDispatch, useSelector} from "react-redux";
import {
    set_ac_stepdown,
    set_ac_strike, set_no_ac_periods
} from "../../redux/Autocall/actions";
import useChangeState from "../useChangeState";
import useFixingDetails from "../useFixingDetails";

const useAutocallDetail = () => {
    const dispatcher = useDispatch()
    const fixings = useFixingDetails()
    const changeState = useChangeState()

    const [acStrike, noACPeriod, acStepdown, snapshot] = useSelector((state) => {
        const {autocallReducer} = state
        return [autocallReducer.acStrike, autocallReducer.noACPeriod, autocallReducer.acStepdown, autocallReducer.snapshot]
    })

    const setAcStrike = (value) => {
        const tmp = Number((value / 100).toFixed(2))
        changeState.setChange(true, acStrike, tmp)
        dispatcher(set_ac_strike(tmp))
        fixings.refreshFixingsByAutocall(tmp)
    }
    const setNoACPeriods = (value) => {
        changeState.setChange(true, noACPeriod, value)
        dispatcher(set_no_ac_periods(value))
        fixings.refreshFixingsByAutocall(undefined, value)
    }

    const setAcStepdown = (value) => {
        const tmp = Number((value / 100.0).toFixed(2))
        changeState.setChange(true, acStepdown, tmp)
        dispatcher(set_ac_stepdown(tmp))
        fixings.refreshFixingsByAutocall(undefined, undefined, tmp)
    }

    return {
        title: "Autocall",
        data: [
            {
                name: "AC Strike, %",
                value: Math.round(acStrike * 100),
                onChange: setAcStrike,
                change: snapshot ? snapshot.ac_strike !== acStrike : false,
                isMain: true,
                type: {
                    name: "number"
                }
            },
            {
                name: "No AC Prds",
                value: noACPeriod,
                onChange: setNoACPeriods,
                change: snapshot ? snapshot.noACPeriod !== noACPeriod : false,
                type: {
                    name: "number"
                }
            },
            {
                name: "AC Stepdown, %",
                value: acStepdown * 100,
                onChange: setAcStepdown,
                change: snapshot ? snapshot.acStepdown !== acStepdown : false,
                type: {
                    name: "number"
                }
            }
        ]
    }
}

export default useAutocallDetail