import {useDispatch, useSelector} from "react-redux";
import useChangeState from "../useChangeState";
import {set_ac_performance, set_cpn_performance, set_final_performance} from "../../redux/Autocall/actions";
import {set_cpn_performance_digital_call} from "../../redux/DigitalCall/actions";

const usePerformanceDigitalCall = () => {
    const dispatch = useDispatch();
    const changeState = useChangeState();

    const [acPerformance, cpnPerformance, finalPerformance, snapshot] = useSelector((store) => {
        const {digitalCallReducer} = store;
        return [digitalCallReducer.acPerformance, digitalCallReducer.cpnPerformance, digitalCallReducer.finalPerformance, digitalCallReducer.snapshot]
    })

    const setCpnPerformance = (value) => {
        changeState.setChange(true, cpnPerformance, value)
        dispatch(set_cpn_performance_digital_call(value))
    }

    return {
        title: "Performance",
        data: [
            {
                name: "CPN",
                value: cpnPerformance,
                onChange: setCpnPerformance,
                change: snapshot.cpnPerformance !== cpnPerformance,
                type: {
                    name: "selector",
                    options: ["WoF", "Avg"]
                },
                extra: "small"
            }
        ]
    }
}

export default usePerformanceDigitalCall;