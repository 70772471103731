import {useDispatch, useSelector} from "react-redux";
import {set_commands, set_main_view} from "../redux/Common/actions";
import useInfoPopup from "./useInfoPopup";
import {setTickerHistory} from "../utils/localStorageHandler";

const useMainView = () => {
    const dispatch = useDispatch();
    const infoPopup = useInfoPopup()
    const [mainView, mainViews, command, mainViewsPermissions] = useSelector((state) => {
        const {commonReducer} = state;
        return [commonReducer.mainView, commonReducer.mainViews, commonReducer.command, commonReducer.mainViewsPermissions];
    })

    const resetCommand = () => {
        dispatch(set_commands(""))
    }

    const setMainView = (value, command) => {

        const role = localStorage.getItem("oAuth_pricing")
        command = command.trim()
        if (typeof value === "string") {
            value = value.toUpperCase();
        }
        if (value === "DLIB") {
            dispatch(set_main_view("default"));
            return
        }
        if (command.split(' ').length <= 1) {
            console.error(`Command ${command} is not valid`);
            infoPopup.setPopupStatus(true, `Command ${command} not include parameters`, false)
            return
        }
        if (mainViews.includes(value)) {
            if (mainViewsPermissions[value].includes(role)) {
                dispatch(set_main_view(value))
                dispatch(set_commands(command.toUpperCase()))
                setTickerHistory(command.toUpperCase())
                return
            }
            infoPopup.setPopupStatus(true, `Permission denied`, false)
            return
        }
        console.error(`Main view ${value} is not in mainViews`);
        infoPopup.setPopupStatus(true, `Not found command: ${value}`, false)
    }

    const directSetMainView = () => {
        dispatch(set_main_view("default"))
    }

    return {
        mainView,
        mainViews,
        setMainView,
        command,
        directSetMainView,
        resetCommand
    }
}

export default useMainView;