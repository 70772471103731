import {useSelector} from "react-redux";

export const useDigitalCallShortSnapshot = () => {
    return useSelector(store => {
        const {digitalCallShortReducer, MarketDataReducer, commonReducer} = store
        return {
            product: digitalCallShortReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}