import {getLocalStorageHistory} from "../../../utils/localStorageHandler";
import Container from "../../../components/Container/Container";
import './LogsMain.css'

const LogsMain = () => {
    const history = getLocalStorageHistory().reverse()
    return (
        <div className={'logs'}>
            <Container>
                <div>
                    {history.length !== 0
                        ? history.map((elem, i) => {
                            return <p key={i} className={`logs-line`}>{elem}</p>
                        })
                        : <p style={{textAlign: "center", padding: "20px 0"}}>Логов пока нет</p>}
                </div>
            </Container>
        </div>
    )
}

export default LogsMain