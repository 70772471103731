import {useDispatch, useSelector} from "react-redux";
import {set_cpn_amt_pa, set_cpn_strike, set_fixed_cpn_amt, set_fixed_cpn_cnt} from "../../redux/Autocall/actions";
import useChangeState from "../useChangeState";
import {daysDifference} from "../../utils/utilsFunctions";
import useFixingDetails from "../useFixingDetails";

const useCouponDetail = () => {
    const dispatcher = useDispatch()
    const refresh = useFixingDetails()
    const changeState = useChangeState()

    const [cpnStrike, fixedCPN, cpnAMTpa, fixedCPNAMT, tradingDate, fixingDate, snapshot] = useSelector((state) => {
        const {autocallReducer} = state
        return [autocallReducer.cpnStrike, autocallReducer.fixedCPNCNT, autocallReducer.cpnAMTpa,
            autocallReducer.fixedCPNAMT, autocallReducer.tradingDate, autocallReducer.fixingDates[0], autocallReducer.snapshot]
    })

    const setCpnStrike = (value) => {
        changeState.setChange(true, cpnStrike, value)
        dispatcher(set_cpn_strike(value / 100))
        refresh.refreshFixingsByCoupon(value / 100)
    }

    const setFixedCPN = (value) => {
        changeState.setChange(true, fixedCPN, value)
        dispatcher(set_fixed_cpn_cnt(value))
        refresh.refreshFixingsByCoupon(undefined, value)
    }

    const setFixedCPNAMT = (value) => {
        const freq = daysDifference(fixingDate, tradingDate) / 365
        const fixedCpnCnt = (value / 100) / freq
        dispatcher(set_cpn_amt_pa(fixedCpnCnt))
        dispatcher(set_fixed_cpn_amt(value / 100))
    }

    const setCpnAMTpa = (value) => {
        const freq = daysDifference(fixingDate, tradingDate) / 365
        const fixedCpnAmt = (value / 100) * freq
        dispatcher(set_fixed_cpn_amt(fixedCpnAmt))
        changeState.setChange(true, cpnAMTpa, value)
        dispatcher(set_cpn_amt_pa(value / 100))
    }

    return {
        title: "Coupon",
        data: [
            {
                name: "Cpn Strike",
                value: Number((cpnStrike * 100).toFixed(2)),
                onChange: setCpnStrike,
                change: snapshot ? snapshot.cpnStrike !== cpnStrike : false,
                isMain: true,
                type: {
                    name: "number"
                }
            },
            {
                name: "Fixed Cpns",
                value: fixedCPN,
                onChange: setFixedCPN,
                change: snapshot ? snapshot.fixedCPNCNT !== fixedCPN : false,
                type: {
                    name: "number"
                }
            },
            {
                name: "Cpn Amt p.a.",
                value: Number((cpnAMTpa * 100).toFixed(2)),
                onChange: setCpnAMTpa,
                change: snapshot ? snapshot.cpnAMTpa !== cpnAMTpa : false,
                isMain: true,
                type: {
                    "name": "number"
                }
            },
            {
                name: "Fixed Cpns Amt",
                value: Number((fixedCPNAMT * 100).toFixed(2)),
                onChange: setFixedCPNAMT,
                change: 1e-3 < snapshot ? Math.abs(snapshot.fixedCPNAMT - fixedCPNAMT) : fixedCPNAMT,
                type: {
                    "name": "number"
                }
            }]
    }
}

export default useCouponDetail