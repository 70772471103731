import './NavSelect.css'
import useViewMode from "../../../../hooks/useViewMode";

const NavSelect = ({mode, setMode}) => {
    const mobile = window.innerWidth < 768
    const viewMode = useViewMode()
    const permissions = localStorage.getItem("oAuth_pricing")
    const selectChoice = [
        {
            name: "Product Details",
            mobileName: "Product",
            permissions: ["full", "demo", "tiny"],
            number: 0,
            products: ["Autocall", "Vanilla", "Call On Basket", "MarketData", "Protected Participation", "Digital Call on Basket", "Digital Call", "Spread Option"]
        },
        {
            name: "Fixings Details",
            mobileName: "Fixings",
            permissions: ["full", "demo", "tiny"],
            number: 1,
            products: ["Autocall", "Digital Call on Basket", "Digital Call"]
        },
        {
            name: "Market Data",
            mobileName: "MarketData",
            permissions: ["full", "demo", "tiny"],
            number: 2,
            products: ["Autocall", "Vanilla", "Call On Basket", "MarketData", "Protected Participation", "Digital Call on Basket", "Digital Call", "Spread Option"]
        },
        {
            name: "Greeks",
            mobileName: "Greeks",
            permissions: ["full", "demo", "tiny"],
            number: 4,
            products: ["Autocall", "Vanilla", "Call On Basket", "Protected Participation", "Digital Call on Basket", "Digital Call", "Spread Option"]
        },
        {
            name: "Trade Details",
            mobileName: "Trade",
            permissions: ["full", "demo"],
            number: 3,
            products: ["Autocall", "Vanilla", "Call On Basket", "Protected Participation", "Digital Call on Basket", "Digital Call", "Spread Option"]
        }
    ]
    return (
        <div className="nav-select">
            {selectChoice.map((elem, i) => {
                if (!elem.permissions.includes(permissions) || !elem.products.includes(viewMode.viewMode)) return null
                return <div key={i} className={`nav-select-option ${mode === elem.number ? 'selected' : ''}`} onClick={() => {
                    setMode(elem.number)
                }}>{mobile ? elem.mobileName : elem.name}</div>
            })}
        </div>
    )
}
export default NavSelect