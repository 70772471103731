import {useSelector} from "react-redux";

const useAllMarketData = () => {
    const [corr_mtx, divs, pricing_date,
    rate, spots, vols,
    version, fixings] = useSelector(store => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.corrMtx, MarketDataReducer.divs, MarketDataReducer.pricingDate,
            MarketDataReducer.rate, MarketDataReducer.spots, MarketDataReducer.vols,
            MarketDataReducer.version, MarketDataReducer.fixings]
    })

    return {
        corr_mtx,
        divs,
        pricing_date,
        rate,
        spots,
        vols,
        version,
        fixings
    }
}

export default useAllMarketData