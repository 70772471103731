import {useSelector} from "react-redux";

const useDigitalCallSnapshot = () => {
    return useSelector(store => {
        const {digitalCallReducer, MarketDataReducer, commonReducer} = store
        return {
            product: digitalCallReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}

export default useDigitalCallSnapshot;