import useMainView from "../../hooks/useMainView";
import StockRoot from "../StockRoot/StockRoot";

const ChartManager = () => {
    const mainView = useMainView()
    if (mainView.mainViews.includes(mainView.mainView.toUpperCase())) {
        return (
            <div>
                <StockRoot/>
            </div>
        )
    }
}

export default ChartManager;