import {useDispatch, useSelector} from "react-redux";
import {set_barrier, set_payoff, set_strike} from "../../redux/Autocall/actions";
import useChangeState from "../useChangeState";

const useRedemptionDetail = () => {
    const dispatcher = useDispatch()
    const changeState = useChangeState()

    const [strike, payoff, barrier, snapshot] = useSelector((state) => {
        const {autocallReducer} = state
        return [autocallReducer.strike, autocallReducer.payoff, autocallReducer.barrier, autocallReducer.snapshot]
    })

    const setStrike = (value) => {
        const tmp = Number((value / 100).toFixed(2))
        changeState.setChange(true, strike, tmp)
        dispatcher(set_strike(tmp))
    }

    const setPayoff = (value) => {
        changeState.setChange(true, payoff, value)
        dispatcher(set_payoff(value))
    }

    const setBarrier = (value) => {
        const tmp = Number((value / 100).toFixed(2))
        changeState.setChange(true, barrier, tmp)
        dispatcher(set_barrier(tmp))
    }


    return {
        title: "Redemption",
        data: [
            {
                name: "Strike, %",
                value: strike * 100,
                onChange: setStrike,
                isMain: payoff === "GP",
                change: 1e-6 < snapshot ? Math.abs(snapshot.strike - strike) : strike,
                type: {
                    name: "number",
                    disabled: payoff === "EKI"
                }
            },
            {
                name: "Payoff",
                value: payoff,
                onChange: setPayoff,
                change: snapshot ? snapshot.payoff !== payoff : false,
                type: {
                    name: "selector",
                    options: ["GP", "EKI"]
                }
            },
            {
                name: "Barrier, %",
                value: barrier * 100,
                isMain: payoff === "EKI",
                onChange: setBarrier,
                change: 1e-6 < snapshot ? Math.abs(snapshot.barrier - barrier) : barrier,
                type: {
                    name: "number",
                    disabled: payoff === "GP"
                }
            }]
    }
}

export default useRedemptionDetail