import './Logs.css'
import Header from "../Header/Header";
import LogsMain from "./LogsMain/LogsMain";
import {useEffect} from "react";

const Logs = () => {
    useEffect(() => {
        document.title = "Rumberg | Logs"
    })
    return (
        <div>
            <Header selectHeader={`logs`}/>
            <LogsMain />
        </div>
    )
}

export default Logs