// Импорт необходимых библиотек и компонентов
import {
    getChartHistory,
    getFavorites,
    getLastFourChartHistory,
    removeFavorite,
    setFavorite
} from "../../utils/localStorageHandler";
import "./ChartHistory.css"
import {useDispatch} from "react-redux";
import {set_commands} from "../../redux/Common/actions";
import ModalWindow from "../ModalWindow/ModalWindow";
import {useState} from "react";
import fav from "./../../static/favorit.png"
import unfav from "./../../static/unfavorite.png"
import useFullSnapshot from "../../hooks/snapshots/useFullSnapshot";


export const ChartHistory = () => {
    // Получение последних четырех элементов истории графиков
    const history = getLastFourChartHistory()
    // Получение полной истории графиков
    const fullHistory = getChartHistory()
    // Хук для работы с Redux
    const dispatch = useDispatch()
    // Локальное состояние для активации модального окна
    const [isActive, setIsActive] = useState(false)
    // Получение списка избранных графиков
    const favorites = getFavorites()
    // Локальное состояние для обновления компонента
    const [isRefresh, setIsRefresh] = useState(true)
    // Хук для получения полных данных графика
    const {getGraphSnapshot} = useFullSnapshot()

    // Функция для открытия последних элементов в новой вкладке
    const openRecent = async () => {
        let currentHash = undefined
        for (let i = 0; i < Object.keys(favorites).length; i++) {
            // Получение снимка графика для каждого избранного элемента
            currentHash = await getGraphSnapshot(Object.keys(favorites)[i], currentHash)
        }
        // Открытие новой вкладки с последним графиком
        window.open("/pricing/" + currentHash, '_blank', 'noopener,noreferrer')
    }

    // Рендер компонента
    return (
        <div className="chart-history_window">
            <ModalWindow content={
                <div className="chart-history_table chart-history_table_full">
                    {fullHistory.map(el => (
                        <div
                            onClick={() => {
                                // Получение и отображение данных графика по клику и открытие в новой вкладке
                                getGraphSnapshot(el).then((data) => {
                                    console.log("ID For Restore graph:", data)
                                    window.open("/pricing/" + data, '_blank', 'noopener,noreferrer')
                                })
                                // Скрытие модального окна после выбора элемента
                                setIsActive(false)
                            }}
                            className="chart-history_row chart-history_row_full"
                            style={{
                                display: "flex"
                            }}
                        >
                            <p>{el}</p>
                            <p onClick={
                                (e) => {
                                    // Обработка клика для добавления или удаления из избранного
                                    e.stopPropagation()
                                    if (el in favorites) {
                                        removeFavorite(el)
                                    } else {
                                        setFavorite(el)
                                    }
                                    // Обновление компонента для отражения изменений
                                    setIsRefresh(!isRefresh)
                                }
                            } className="chart-history_status"
                            >{el in favorites ? <img src={fav} alt=""/> : <img src={unfav} alt=""/>}</p>
                        </div>
                    ))}
                </div>
            } isActive={isActive} setIsActive={setIsActive} smallSize={true}/>
            <div className="chart-history_header">
                <p className="chart-history_title">Chart History</p>
                <p onClick={openRecent} className="chart-history_all-btn">Open all recent</p>
            </div>
            <div className="chart-history_table">
                {history.map(el => (
                    <div onClick={() => dispatch(set_commands(el))} className="chart-history_row">
                        <p>{el}</p>
                        <p onClick={
                            (e) => {
                                e.stopPropagation()
                                if (el in favorites) {
                                    removeFavorite(el)
                                } else {
                                    setFavorite(el)
                                }
                                setIsRefresh(!isRefresh)
                            }
                        } className="chart-history_status"
                        >{el in favorites ? <img src={fav} alt=""/> : <img src={unfav} alt=""/>}</p>
                    </div>
                ))}
            </div>
            <div className="chart-history_show" onClick={() => setIsActive(true)}>Show full history</div>
        </div>
    )
}