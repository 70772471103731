import './SimpleMainButton.css'

const SimpleMainButton = ({text, style_, callback}) => {
    if (style_ === "grey") {
        return (
            <div className={"simple_secondary_button"} onClick={() => callback()}>
                {text}
            </div>
        )
    } else if (style_ === "grey-m") {
        return (
            <div className={"simple_secondary_button_mod"} onClick={() => callback()}>
                {text}
            </div>
        )
    }
    return (
        <div className={`simple_main_button`} onClick={() => callback()}>
            {text}
        </div>
    )
}

export default SimpleMainButton