import {useDispatch, useSelector} from "react-redux";
import {set_pricing_date} from "../../redux/MarketData/actions";
import {set_date_expiry_autocall, set_trading_date} from "../../redux/Autocall/actions";
import useViewMode from "../useViewMode";
import {set_fixing_date_vanilla, set_trading_date_vanilla} from "../../redux/Vanilla/actions";
import {set_trading_date_market_data_product} from "../../redux/MarketDataProduct/actions";
import useFixingDetails from "../useFixingDetails";
import {set_fixing_date_basket, set_trading_date_basket} from "../../redux/CallOnBasket/action";
import {set_fixing_date_participation, set_trading_date_participation} from "../../redux/Participation/actions";
import {set_date_expiry_digital_call, set_trading_date_digital_call} from "../../redux/DigitalCall/actions";
import {set_fixing_dates_spread_option, set_trading_date_spread_option} from "../../redux/SpreadOption/actions";
import {
    set_date_expiry_digital_call_short,
    set_trading_date_digital_call_short
} from "../../redux/DigitalCallShort/actions";
import {digitalCallShortReducer} from "../../redux/DigitalCallShort/digitalCallShortReducer";

const useDates = () => {
    const dispatch = useDispatch();
    const fixings = useFixingDetails()
    const viewMode = useViewMode()
    const pricingDate = useSelector(store => {
        const {MarketDataReducer} = store;
        return MarketDataReducer.pricingDate;
    })
    const [tradingDate, timeToMaturity] = useSelector(store => {
        const {autocallReducer, VanillaReducer, MarketDataProductReducer, BasketReducer,
            ParticipationReducer, digitalCallReducer, digitalCallShortReducer,
        spreadOptionReducer} = store;
        let result = null;
        let t2m = null
        if (viewMode.viewMode === "Autocall") {
            result = autocallReducer.tradingDate
            t2m = autocallReducer.timeToMaturity * 365
        } else if (viewMode.viewMode === "Vanilla") {
            result = VanillaReducer.tradingDate
            t2m = VanillaReducer.fixingDates
        } else if (viewMode.viewMode === "MarketData") {
            result = MarketDataProductReducer.tradingDateMD
        } else if (viewMode.viewMode === "Call On Basket") {
            result = BasketReducer.tradingDate
            t2m = BasketReducer.fixingDates
        } else if (viewMode.viewMode === "Protected Participation") {
            result = ParticipationReducer.tradingDate
            t2m = ParticipationReducer.fixingDates
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            result = digitalCallReducer.tradingDate
            t2m = digitalCallReducer.timeToMaturity * 365
        } else if (viewMode.viewMode === "Digital Call") {
            result = digitalCallShortReducer.tradingDate
            t2m = digitalCallShortReducer.timeToMaturity * 365
            console.log("<-- t2m --> ", t2m, digitalCallShortReducer.time2maturity, result)
        } else if (viewMode.viewMode === "Spread Option") {
            result = spreadOptionReducer.tradingDate
            t2m = spreadOptionReducer.timeToMaturity * 365
        }
        return [result, t2m]
    })

    const setPricingDate = (val) => {
        dispatch(set_pricing_date(val));
    }

    const setTradingDate = (val) => {
        if (viewMode.viewMode === "Autocall") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_trading_date(val));
            dispatch(set_date_expiry_autocall(date.toISOString().slice(0, 10)))
            fixings.refreshFixingsByFixings(undefined, undefined, val)
        } else if (viewMode.viewMode === "Vanilla") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_trading_date_vanilla(val));
            dispatch(set_fixing_date_vanilla([date.toISOString().slice(0, 10)]))
        } else if (viewMode.viewMode === "Call On Basket") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_fixing_date_basket([date.toISOString().slice(0, 10)]))
            dispatch(set_trading_date_basket(val))
        } else if (viewMode.viewMode === "Protected Participation") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_fixing_date_participation([date.toISOString().slice(0, 10)]))
            dispatch(set_trading_date_participation(val))
        } else if (viewMode.viewMode === "MarketData") {
            dispatch(set_trading_date_market_data_product(val));
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_trading_date_digital_call(val));
            dispatch(set_date_expiry_digital_call(date.toISOString().slice(0, 10)))
            fixings.refreshFixingsByFixings(undefined, undefined, val)
        } else if (viewMode.viewMode === "Digital Call") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            console.log("<- val -> ", val, date, timeToMaturity)
            dispatch(set_trading_date_digital_call_short(val));
            dispatch(set_date_expiry_digital_call_short(date.toISOString().slice(0, 10)))
            fixings.refreshFixingsByFixings(undefined, undefined, val)
        } else if (viewMode.viewMode === "Spread Option") {
            const date = new Date(val)
            date.setDate(date.getDate() + Math.round(timeToMaturity))
            dispatch(set_trading_date_spread_option(val));
            dispatch(set_fixing_dates_spread_option([date.toISOString().slice(0, 10)]))
            fixings.refreshFixingsByFixings(undefined, undefined, val)
        }

    }

    return {
        pricingDate,
        tradingDate,
        setPricingDate,
        setTradingDate
    }
}

export default useDates;


