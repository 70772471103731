import './ProductDetailLine.css'
import InputManager from "../../components/InputManager/InputManager";
import useViewMode from "../../hooks/useViewMode";

const ProductDetailLine = ({dataset}) => {
    const viewMode = useViewMode()
    // ${i === 2 ? "product-detail_param-line-value-large" : null}
    return (
        <div className="product-detail_param-line">
            <div className="product-detail_param-line-title">{dataset.title}</div>
            {viewMode.viewMode === "Autocall" || viewMode.viewMode === "Digital Call on Basket"
                ? <div className={"product-detail_param_container"}>
                    {dataset.data.map((elem, i) => (
                        <div key={i}
                             className={`product-detail_param-line-value ${dataset.data.length !== 3 && "fg"} `}>
                            <InputManager dataset={elem}/>
                        </div>
                    ))}
                </div>
                : <div className={"product-detail_param_container-vanilla"}>
                    {dataset.data.map((elem, i) => (
                        <div key={i}
                             className={`product-detail_param-line-value-vanilla ${i === 2 ? "product-detail_param-line-value-large" : null} ${elem.extra === "small" ? "small-input" : null}`}>
                            <InputManager dataset={elem}/>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default ProductDetailLine