import {useSelector} from "react-redux";

export const useSpreadOptionSnapshot = () => {
    return useSelector(store => {
        const {spreadOptionReducer, MarketDataReducer, commonReducer} = store
        return {
            product: spreadOptionReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}