import {useDispatch, useSelector} from "react-redux";
import {set_notional_autocall} from "../redux/Autocall/actions";
import useViewMode from "./useViewMode";
import {set_notional_vanilla} from "../redux/Vanilla/actions";
import {set_notional_basket} from "../redux/CallOnBasket/action";
import {set_notional_participation} from "../redux/Participation/actions";
import {set_notional_digital_call} from "../redux/DigitalCall/actions";
import {set_notional_digital_call_short} from "../redux/DigitalCallShort/actions";
import {set_notional_spread_option} from "../redux/SpreadOption/actions";

const useTradeDetails = () => {
    const dispatch = useDispatch();
    const viewMode = useViewMode()
    const [notional] = useSelector((state) => {
        const {autocallReducer, VanillaReducer, BasketReducer, ParticipationReducer,
            digitalCallReducer, digitalCallShortReducer, spreadOptionReducer} = state;
        if (viewMode.viewMode === "Autocall") {
            return [autocallReducer.notional]
        } else if (viewMode.viewMode === "Vanilla") {
            return [VanillaReducer.notional]
        } else if (viewMode.viewMode === "Call On Basket") {
            return [BasketReducer.notional]
        } else if (viewMode.viewMode === "Protected Participation") {
            return [ParticipationReducer.notional]
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            return [digitalCallReducer.notional]
        } else if (viewMode.viewMode === "Digital Call") {
            return [digitalCallShortReducer.notional]
        } else if (viewMode.viewMode === "Spread Option") {
            return [spreadOptionReducer.notional]
        } else {
            return [-1]
        }
    })


    const setNotional = (value) => {
        if (value === undefined) {
            dispatch(set_notional_autocall(-1))
            return
        }
        console.log("setNotional", value, typeof value)
        const tmp = Number(value)
        if (viewMode.viewMode === "Autocall") {
            dispatch(set_notional_autocall(tmp))
        } else if (viewMode.viewMode === "Vanilla") {
            dispatch(set_notional_vanilla(tmp))
        } else if (viewMode.viewMode === "Call On Basket") {
            dispatch(set_notional_basket(tmp))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_notional_participation(tmp))
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            dispatch(set_notional_digital_call(tmp))
        } else if (viewMode.viewMode === "Digital Call") {
            dispatch(set_notional_digital_call_short(tmp))
        } else if (viewMode.viewMode === "Spread Option") {
            dispatch(set_notional_spread_option(tmp))
        }
    }

    return {
        notional,
        setNotional
    }
}

export default useTradeDetails;