import useNotionalBasket from "./useNotionalBasket";
import useExpiryBasket from "./useExpiryBasket";
import useDetailsBasket from "./useDetailsBasket";

const useProductDetailBasket = () => {
    const notional = useNotionalBasket()
    const expiry = useExpiryBasket()
    const details = useDetailsBasket()

    return [notional, expiry, details]
}

export default useProductDetailBasket;