import './InputTicker.css'
import {useEffect, useState} from "react";
import search from './../../static/search.svg'
import useTickerDependencies from "../../hooks/useTickerDependencies";
import useViewMode from "../../hooks/useViewMode";
import useTickerDependenciesVanilla from "../../hooks/useTickerDependenciesVanilla";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import useInfoPopup from "../../hooks/useInfoPopup";
import useTickerDependenciesMarketData from "../../hooks/marketDataProduct/useTickerDependenciesMarketData";
import {set_strike, set_strike_abs} from "../../redux/Vanilla/actions";
import {useDispatch, useSelector} from "react-redux";
import TickerHint from "../TickerHint/TickerHint";
import useAllTickers from "../../hooks/useAllTickers";
import useTickerDependenciesCallOnBasket from "../../hooks/useTickerDependenciesCallOnBasket";
import {set_strike_abs_participation, set_strike_participation} from "../../redux/Participation/actions";
import useTickerDependenciesDigitalCall from "../../hooks/useTickerDependenciesDigitalCall";
import {useTickerDependenciesDigitalCallShort} from "../../hooks/useTickerDependenciesDigitalCallShort";
import {useTickerDependenciesSpreadOption} from "../../hooks/useTickerDependenciesSpreadOption";

/**
 * Component for input ticker. Add new ticker to store and to all dependencies data
 * @returns {JSX.Element}
 * @constructor
 * @usage <TickersSelector />
 */
const InputTicker = () => {
    const dispatch = useDispatch()
    const [ticker, setTicker] = useState('')
    const [tickerTrigger, setTickerTrigger] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const allTickers = useAllTickers()
    const [countDown, setCountDown] = useState(0)

    const filteredTickers = allTickers.searchTicker(ticker)

    const viewManager = useViewMode()
    const popup = useInfoPopup()
    const refreshManager = useRefreshMarketData()
    let data = null
    if (viewManager.viewMode === "Autocall") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependencies()
    } else if (viewManager.viewMode === "Vanilla" || viewManager.viewMode === "Protected Participation") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesVanilla()
    } else if (viewManager.viewMode === "MarketData") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesMarketData()
    } else if (viewManager.viewMode === "Call On Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesCallOnBasket()
    } else if (viewManager.viewMode === "Digital Call on Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesDigitalCall()
    } else if (viewManager.viewMode === "Digital Call") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesDigitalCallShort()
    } else if (viewManager.viewMode === "Spread Option") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesSpreadOption()
    }
    const [refSpots, tickers] = useSelector(state => {
        const {VanillaReducer, ParticipationReducer} = state
        if (viewManager.viewMode === "Vanilla") {
            return [VanillaReducer.refSpots, VanillaReducer.tickersVanilla]
        } else if (viewManager.viewMode === "Protected Participation") {
            return [ParticipationReducer.refSpots, ParticipationReducer.tickersParticipation]
        }
        return [undefined, undefined]
    })


    const saveTicker = (e) => {
        data.saveTicker(ticker.replaceAll("_", " "))
        setTicker('')
        e.target.blur()
        // setTickerTrigger(!tickerTrigger)
    }

    useEffect(() => {
        if (data.isSaved) {
            refreshManager.refreshMD()
            data.setIsSaved(false)
        }
    }, [data.isSaved])

    useEffect(() => {
        if (refreshManager.isRefresh) {
            if (viewManager.viewMode === "Vanilla") {
                const spot = refSpots[tickers[0]];
                dispatch(set_strike_abs(spot))
                dispatch(set_strike(1))
            } else if (viewManager.viewMode === "Protected Participation") {
                const spot = refSpots[tickers[0]];
                dispatch(set_strike_abs_participation(spot))
                dispatch(set_strike_participation(1))
            }
            popup.setPopupStatus(true, "Success updated market_data", true)
            // data.setIsSaved(false)
            refreshManager.setIsRefresh(false)
        }
    }, [refreshManager.isRefresh])

    const handleClick = (ticker_) => {
        // setTicker(ticker_)
        setIsActive(false)
        saveTickerDirectly(ticker_)
    }

    const saveTickerDirectly = (ticker_) => {
        console.log("{///}", ticker_)
        data.saveTicker(ticker_.replaceAll("_", " "))
        setTicker('')
        // setTickerTrigger(!tickerTrigger)
    }

    const doBlur = () => {
        setTimeout(() => {
            setIsActive(false)
        }, 100)
    }

    return (
        <div className="input_ticker">
            <label htmlFor="ticker" className="input_ticker-label">Tickers</label>
            <div className="input_ticker-wrapper">
                <input type="text"
                       name="ticker"
                       className="input_ticker-field"
                       placeholder={"Select tickers"}
                       value={ticker}
                       onBlur={() => doBlur()}
                       onFocus={() => setIsActive(true)}
                       onChange={e => setTicker(e.target.value)}
                       onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                               if (countDown > 0) {
                                   saveTickerDirectly(filteredTickers[countDown - 1])
                                   setCountDown(0)
                                   return
                               }
                               saveTicker(e)
                           } else if (e.key === 'ArrowDown') {
                               setCountDown(prev => prev + 1)
                           } else if (e.key === 'ArrowUp') {
                               if (countDown > 0) setCountDown(prev => prev - 1)
                           }

                       }}
                />
                <div className="input_ticker-btn"
                     onClick={(e) => saveTicker(e)}
                >
                    <img src={search} alt="Search icon"/>
                </div>
                <TickerHint isActive={isActive}
                            tickers={filteredTickers}
                            setCommand={handleClick}
                            resetClickDown={setCountDown}
                            countDown={countDown}
                            targetCommand={ticker}
                />
            </div>

        </div>
    )
}

export default InputTicker