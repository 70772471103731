import useNotionalParticipation from "./useNotionalParticipation";
import useExpiryParticipation from "./useExpiryParticipation";
import useDetailsParticipation from "./useDetailsParticipation";
import useCapParticipation from "./useCapParticipation";
import useLeverageParticipation from "./useLeverageParticipation";

const useProductDetailParticipation = () => {
    const notional = useNotionalParticipation()
    const expiry = useExpiryParticipation()
    const details = useDetailsParticipation()
    const cap = useCapParticipation()
    const leverage = useLeverageParticipation()

    return [notional, expiry, details, leverage, cap]
}

export default useProductDetailParticipation