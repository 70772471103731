import {
    ALL_DATA_VANILLA,
    CALL_UP_LIMIT, CALL_UP_LIMIT_ABS, CURRENCY, FIXING_DATE_VANILLA,
    FIXING_DATES_VANILLA, GREEKS_VANILLA,
    IS_WITHOUT_NOTIONAL_VANILLA, MODEL_VANILLA, N_SIMS_VANILLA, NOTIONAL_VANILLA,
    PAYOFF_VANILLA, PERIOD_MODE_VANILLA,
    PRICE_CURRENCY, REF_SPOTS_VANILLA, STRIKE_ABS_VANILLA,
    STRIKE_VANILLA,
    TICKERS_VANILLA,
    TRADING_DATE_VANILLA
} from "./types";
import {makeLog} from "../../utils/logger";

const nextDate = new Date()

const initialState = {
    // main
    className: "Vanilla",
    fixingDates: 365,
    isWithoutNotional: true,
    payoff: "call",
    priceCurrency: "RUB",
    strike: 1.0,
    tickersVanilla: ["SBER"],
    tradingDate: new Date().toISOString().slice(0, 10),
    version: "'v1.0_20230702",
    typology: "OPT",
    callUpLimit: undefined,
    currency: "RUB",

    // services
    currencyOptions: ["RUB", "USD", "EUR", "CNY"],
    refSpots: {
        "SBER": 100
    },
    fixingDate: ["2023-08-30"],
    strikeAbs: 100,
    callUpLimitAbs: undefined,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },
    notional: 100000000,
    periodMode: "days",

    model: "Analytic",
    modelOptions: ["LocalVol", "Analytic"],

    n_sims: 131072
}

export const VanillaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIXING_DATES_VANILLA:
            makeLog("FIXING_DATES_VANILLA", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case IS_WITHOUT_NOTIONAL_VANILLA:
            makeLog("IS_WITHOUT_NOTIONAL_VANILLA", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case PAYOFF_VANILLA:
            makeLog("PAYOFF_VANILLA", state.payoff, action.value)
            return {
                ...state,
                payoff: action.value
            }
        case CURRENCY:
            makeLog("CURRENCY", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case PRICE_CURRENCY:
            makeLog("PRICE_CURRENCY", state.priceCurrency, action.value)
            return {
                ...state,
                priceCurrency: action.value
            }
        case STRIKE_VANILLA:
            makeLog("STRIKE_VANILLA", state.strike, action.value)
            return {
                ...state,
                strike: action.value
            }
        case TICKERS_VANILLA:
            makeLog("TICKERS_VANILLA", state.tickers, action.value)
            return {
                ...state,
                tickersVanilla: action.value
            }
        case TRADING_DATE_VANILLA:
            makeLog("TRADING_DATE_VANILLA", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case CALL_UP_LIMIT:
            makeLog("CALL_UP_LIMIT", state.callUpLimit, action.value)
            return {
                ...state,
                callUpLimit: action.value
            }
        case REF_SPOTS_VANILLA:
            makeLog("REF_SPOTS_VANILLA", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case FIXING_DATE_VANILLA:
            makeLog("FIXING_DATE_VANILLA", state.fixingDate, action.value)
            return {
                ...state,
                fixingDate: action.value
            }
        case STRIKE_ABS_VANILLA:
            makeLog("STRIKE_ABS_VANILLA", state.strikeAbs, action.value)
            return {
                ...state,
                strikeAbs: action.value
            }
        case CALL_UP_LIMIT_ABS:
            makeLog("CALL_UP_LIMIT_ABS", state.callUpLimitAbs, action.value)
            return {
                ...state,
                callUpLimitAbs: action.value
            }
        case GREEKS_VANILLA:
            makeLog("GREEKS_VANILLA", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case NOTIONAL_VANILLA:
            makeLog("NOTIONAL_VANILLA", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        case PERIOD_MODE_VANILLA:
            makeLog("PERIOD_MODE_VANILLA", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case MODEL_VANILLA:
            makeLog("MODEL_VANILLA", state.model, action.value)
            return {
                ...state,
                model: action.value
            }
        case N_SIMS_VANILLA:
            makeLog("N_SIMS_VANILLA", state.n_sims, action.value)
            return {
                ...state,
                n_sims: action.value
            }
        case ALL_DATA_VANILLA:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}