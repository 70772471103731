import Graph from "../Graph/Graph";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useRequest from "../../hooks/useRequest";
import Loader from "../Loader/Loader";

const GraphFixings = () => {
    const {get} = useRequest()

    const [tickers, fixings, acP,
        cpnP, finalP, barrier,
        payoff, strike, trading_date,
        pricing_date, acStrikes, cpnStrikes,
        fixingDates] = useSelector(state => {
        const {autocallReducer, MarketDataReducer} = state
        return [autocallReducer.tickers, MarketDataReducer.fixings, autocallReducer.acPerformance,
            autocallReducer.cpnPerformance, autocallReducer.finalPerformance, autocallReducer.barrier,
            autocallReducer.payoff, autocallReducer.strike, autocallReducer.tradingDate,
            MarketDataReducer.pricingDate, autocallReducer.acStrikes, autocallReducer.cpnStrikes,
            autocallReducer.fixingDates]
    })
    const [isLoading, setIsLoading] = useState(true)

    const [data, setData] = useState([{
        x: [1, 2, 3, 4],
        y: [2, 1, 6, 4],
        type: "scatter",
        mode: "lines",
        name: "test"
    }])

    const getStatus = (date, mode, value) => {
        const index = fixingDates.indexOf(date)
        if (mode === "AC") {
            const targetAC = acStrikes[index]
            if (targetAC < value) {
                return `<b>Hit</b>`
            }
            return `<b>Missed</b>`
        }
        if (mode === "CPN") {
            const targetCPN = cpnStrikes[index]
            if (targetCPN < value) {
                return `<b>Paid</b>`
            }
            return `<b>Missed</b>`
        }
        return "not match"
    }

    useEffect(() => {
        if (tickers.length === 0 || new Date(trading_date) >= new Date(pricing_date)) {
            setIsLoading(false)
            return
        }
        setIsLoading(true)
        get(
            "/charts/render/",
            {
                params: {
                    tickers: JSON.stringify(tickers),
                    command: "GP",
                    settings: JSON.stringify({
                        start: trading_date
                    })
                }
            },
            () => {},
            () => {}
        )
            .then(data => {
                const parsedData = (JSON.parse(data))
                const dates = parsedData.index.map(elem => new Date(elem).toISOString().slice(0, 10))
                const colors = ["rgba(34,167,248,0.5)", "rgba(74,206,110,0.5)", "rgba(213,51,51,0.5)", "rgba(255,0,255,0.5)", "rgba(225, 0, 0, 0.5)", "rgba(0, 255, 0, 0.5)", "rgba(255, 255, 0, 0.5)", "rgba(208,197,63,0.5)"]
                const average = {}
                const result = parsedData.columns.map((elem, i) => {
                    const firstVal = parsedData.data[0][i]
                    average[elem] = parsedData.data.map(el => el[i] / firstVal)
                    return {
                        x: dates,
                        y: parsedData.data.map(el => (el[i] / firstVal).toFixed(4)),
                        type: "scatter",
                        mode: "lines",
                        name: elem,
                        hovertemplate: '%{y}',
                        marker: {
                            color: colors[i % colors.length]
                        }
                    }
                })
                if (parsedData.columns.length > 1 && [acP, cpnP, finalP].includes("Avg")) {
                    result.push({
                        x: dates,
                        y: average[parsedData.columns[0]].map((elem, i) => {
                            let sum = 0
                            parsedData.columns.forEach(el => {
                                sum += average[el][i]
                            })
                            return (sum / parsedData.columns.length).toFixed(4)
                        }),
                        type: "scatter",
                        mode: "lines",
                        name: "AVG",
                        hovertemplate: '%{y}',
                        marker: {
                            color: "grey"
                        },
                        line: {
                            dash: "dot"
                        }
                    })
                }
                if (fixings.length > 0) {
                    console.log("FIXINGS", fixings)
                    fixings.forEach(elem => {
                        const date = elem.Date
                        if (new Date(date) <= new Date(trading_date)) return
                        let avg = 0
                        let min = Infinity
                        tickers.forEach((ticker, i) => {
                            if (min > elem[ticker] / parsedData.data[0][i]) {
                                min = elem[ticker] / parsedData.data[0][i]
                            }
                            avg += elem[ticker] / parsedData.data[0][i]
                        })
                        const target = payoff === "EKI" ? barrier : strike
                        // if ([acP, cpnP, finalP].includes("WoF")) {
                        //     console.log("PARAMS", min, barrier)
                        //     result.push({
                        //         x: [date],
                        //         y: [min],
                        //         type: "scatter",
                        //         mode: "markers",
                        //         name: "MIN",
                        //         showlegend: false,
                        //         hovertemplate: `${(min * 100).toFixed(2)}% ${target < min ? "<b>> </b>" + (target * 100).toFixed(2) + "%" : "<b>< </b>" + (target * 100).toFixed(2) + "%"}`,
                        //         marker: {
                        //             symbol: target < min ? "triangle-up" : "triangle-down",
                        //             color: target < min ? "green" : "red",
                        //             size: 10
                        //         }
                        //     })
                        // }
                        // if ([acP, cpnP, finalP].includes("Avg")) {
                        //     console.log("AVG", avg, tickers.length)
                        //     result.push({
                        //         x: [date],
                        //         y: [avg / tickers.length],
                        //         type: "scatter",
                        //         mode: "markers",
                        //         name: "AVG",
                        //         showlegend: false,
                        //         hovertemplate: `${((avg / tickers.length) * 100).toFixed(2)}% ${target < avg / tickers.length ? "<b>> </b>" + (target * 100).toFixed(2) + "%" : "<b>< </b>" + (target * 100).toFixed(2) + "%"}`,
                        //         marker: {
                        //             symbol: target < avg / tickers.length ? "triangle-up" : "triangle-down",
                        //             color: target < avg / tickers.length ? "green" : "red",
                        //             size: 10
                        //         }
                        //     })
                        // }

                        if (acP === "WoF" && acStrikes.some(el => el < 5)) {
                            result.push({
                                x: [date],
                                y: [min],
                                type: "scatter",
                                mode: "markers",
                                name: "AC",
                                showlegend: false,
                                hovertemplate: getStatus(date, "AC", min),
                                marker: {
                                    symbol: target < min ? "triangle-up" : "triangle-down",
                                    color: target < min ? "green" : "red",
                                    size: 10
                                }
                            })
                        }

                        if (acP === "Avg" && acStrikes.some(el => el < 5)) {
                            result.push({
                                x: [date],
                                y: [avg / tickers.length],
                                type: "scatter",
                                mode: "markers",
                                name: "AC",
                                showlegend: false,
                                hovertemplate: getStatus(date, "AC", avg / tickers.length),
                                marker: {
                                    symbol: target < min ? "triangle-up" : "triangle-down",
                                    color: target < min ? "green" : "red",
                                    size: 10
                                }
                            })
                        }

                        if (cpnP === "WoF" && cpnStrikes.some(el => el > 0)) {
                            result.push({
                                x: [date],
                                y: [min],
                                type: "scatter",
                                mode: "markers",
                                name: "CPN",
                                showlegend: false,
                                hovertemplate: getStatus(date, "CPN", min),
                                marker: {
                                    symbol: target < min ? "triangle-up" : "triangle-down",
                                    color: target < min ? "green" : "red",
                                    size: 10
                                }
                            })
                        }

                        if (cpnP === "Avg" && cpnStrikes.some(el => el > 0)) {
                            result.push({
                                x: [date],
                                y: [avg / tickers.length],
                                type: "scatter",
                                mode: "markers",
                                name: "CPN",
                                showlegend: false,
                                hovertemplate: getStatus(date, "CPN", avg / tickers.length),
                                marker: {
                                    symbol: target < min ? "triangle-up" : "triangle-down",
                                    color: target < min ? "green" : "red",
                                    size: 10
                                }
                            })
                        }

                    })
                }
                console.log("RESULT", result)
                setData(result)
                setIsLoading(false)
            })
    }, [])

    const layout = {
        margin: {
            l: 50,
            r: 20,
            t: 20,
            b: 20
        },
        hovermode: 'x unified',
        plot_bgcolor: "#ffffff",
        // autosize: true,
        paper_bgcolor: '#ffffff',
        font: {
            color: "#000000"
        },
        scene: {
            xaxis: {
                gridcolor: "#000000"
            },
            yaxis: {
                gridcolor: "#000000"
            },
            zaxis: {
                gridcolor: "#000000"
            }
        },
        xaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        yaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        showlegend: true,
        legend: {
            orientation: "h",
            x: 0,
            y: -0.3,
            yanchor: "bottom",
        }//!isMobile
    }


    return (
        <div>
            {isLoading && <Loader/>}
            <div style={{
                height: "300px"
            }}>
                {!isLoading && tickers.length !== 0 && new Date(trading_date) < new Date(pricing_date) &&
                    <Graph data={data} customLayout={layout}/>
                }
            </div>
        </div>
    )
}

export default GraphFixings;