import {useDispatch, useSelector} from "react-redux";
import {set_spread_option_order_spread_option} from "../../redux/SpreadOption/actions";

export const useProductMainTicker = () => {
    const dispatch = useDispatch()
    const [tickers, spreadOptionOrder] = useSelector(state => {
        const {spreadOptionReducer} = state
        return [spreadOptionReducer.tickers, spreadOptionReducer.spreadOptionOrder]
    })

    const setMainTicker = (value) => {
        const tmp = [value]
        console.log("[ TMP MAIN TICKER ]", tmp)
        spreadOptionOrder.forEach((item) => {
            if (item !== value) {
                tmp.push(item)
            }
        })
        console.log("[ TMP MAIN TICKER after ]", tmp)
        dispatch(set_spread_option_order_spread_option(tmp))
    }

    return {
        title: "Main ticker",
        data: [
            {
                name: "Main ticker",
                value: spreadOptionOrder[0],
                onChange: setMainTicker,
                type: {
                    name: "selector",
                    options: tickers
                },
                extra: "small"
            }
        ]
    }
}