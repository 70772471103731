import {useSelector} from "react-redux";

const useProductParticipation = () => {
    const [className, fixingDate, isWithoutNotional, payoff, priceCurrency, currency, strikeAbs, callUpLimitAbs, tickers, typology, tradingDate, version, refSpots, leverageRate] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.className, ParticipationReducer.fixingDate, ParticipationReducer.isWithoutNotional, ParticipationReducer.payoff, ParticipationReducer.priceCurrency, ParticipationReducer.currency, ParticipationReducer.strikeAbs, ParticipationReducer.callUpLimitAbs, ParticipationReducer.tickersParticipation, ParticipationReducer.typology, ParticipationReducer.tradingDate, ParticipationReducer.version, ParticipationReducer.refSpots, ParticipationReducer.leverage_rate]
    })

    return {
        class_name: className,
        fixing_dates: fixingDate,
        is_without_notional: isWithoutNotional,
        payoff: payoff,
        price_currency: priceCurrency,
        currency: currency,
        strike: strikeAbs,
        call_up_limit: callUpLimitAbs ? callUpLimitAbs : undefined,
        tickers: tickers,
        typology: typology,
        trading_date: tradingDate,
        version: version,
        ref_spots: refSpots,
        leverage_rate: leverageRate
    }
}

export default useProductParticipation