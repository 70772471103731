import useGreeks from "../../../hooks/useGreeks";
import "./GreekDetails.css"
import useTradeDetails from "../../../hooks/useTradeDetails";

const GreekDetails = () => {
    const greeks = useGreeks()
    const notional = useTradeDetails().notional

    const calcPercentile = (value) => {
        return Math.round(value / notional * 10000) / 100
    }

    return (
        <div className={"greek__details"}>
            {Object.keys(greeks.deltas).length === 0 &&
                <div className="greek__table-empty">Need "Price" first</div>
            }
            {Object.keys(greeks.deltas).length > 0 &&
                <div className="greek__block">
                    <div className="greek__block-title">Delta</div>
                    <div className="greek__table">
                        <div className="greek__table-row head">
                            <div className={"greek__table-row--ticker"}>Ticker</div>
                            <div className={"greek__table-row--value"}>Delta, %</div>
                            <div className={"greek__table-row--value"}>Delta, Cash</div>
                        </div>
                        {Object.keys(greeks.deltas).map((elem, i) => (
                            <div className="greek__table-row" key={i}>
                                <div className={"greek__table-row--ticker"}>{elem}</div>
                                <div className={"greek__table-row--value"}>{greeks.deltas[elem]} %</div>
                                <div className={"greek__table-row--value"}>{greeks.deltasCash[elem].toLocaleString("ru")}</div>
                            </div>
                        ))}
                        <div className="greek__table-row tfooter">
                            <div className={"greek__table-row--ticker"}>Total</div>
                            <div className={"greek__table-row--value"}></div>
                            <div className={"greek__table-row--value"}>{Object.values(greeks.deltasCash).reduce((a, b) => a + b, 0).toLocaleString("ru")}</div>
                        </div>
                    </div>
                </div>
            }

            {greeks.gammaCash !== undefined &&
                <div className="greek__block">
                    <div className="greek__block-title">Gamma</div>
                    <div className="greek__table sm">
                        <div className="greek__table-row head sm">
                            <div className={"greek__table-row--value"}>Gamma, Cash</div>
                        </div>
                        <div className="greek__table-row sm">
                            <div className={"greek__table-row--value sm"}>{greeks.gammaCash.toLocaleString("ru")}</div>
                        </div>
                    </div>
                </div>
            }

            {Object.keys(greeks.vegas).length > 0 &&
                <div className="greek__block">
                    <div className="greek__block-title">Vega</div>
                    <div className="greek__table">
                        <div className="greek__table-row head">
                            <div className={"greek__table-row--ticker"}>Ticker</div>
                            <div className={"greek__table-row--value"}>Vega, %</div>
                            <div className={"greek__table-row--value"}>Vega, Cash</div>
                        </div>
                        {Object.keys(greeks.vegas).length > 0 && Object.keys(greeks.vegas).map((elem, i) => (
                            <div className="greek__table-row" key={i}>
                                <div className={"greek__table-row--ticker"}>{elem}</div>
                                <div className={"greek__table-row--value"}>{calcPercentile(greeks.vegasCash[elem])} %</div>
                                <div className={"greek__table-row--value"}>{greeks.vegasCash[elem].toLocaleString("ru")}</div>
                            </div>
                        ))}
                        <div className="greek__table-row tfooter">
                            <div className={"greek__table-row--ticker"}>Total</div>
                            <div className={"greek__table-row--value"}></div>
                            <div className={"greek__table-row--value"}>{Object.values(greeks.vegasCash).reduce((a, b) => a + b, 0).toLocaleString("ru")}</div>
                        </div>
                    </div>
                </div>
            }

            {greeks.rho !== undefined &&
                <div className="greek__block">
                    <div className="greek__block-title">Rho</div>
                    <div className="greek__table ssm">
                        <div className="greek__table-row head sm">
                            <div className={"greek__table-row--value"}>Rho, 1bps</div>
                            <div className={"greek__table-row--value"}>Rho, Cash</div>
                        </div>
                        <div className="greek__table-row sm">
                            <div className={"greek__table-row--value sm"}>{calcPercentile(greeks.rhoCash)} %</div>
                            <div className={"greek__table-row--value sm"}>{greeks.rhoCash.toLocaleString("ru")}</div>
                        </div>
                    </div>
                </div>
            }

            {greeks.theta !== undefined &&
                <div className="greek__block">
                    <div className="greek__block-title">Theta</div>
                    <div className="greek__table sm">
                        <div className="greek__table-row head sm">
                            <div className={"greek__table-row--value"}>Theta, Cash</div>
                        </div>
                        <div className="greek__table-row sm">
                            <div className={"greek__table-row--value sm"}>{greeks.theta.toLocaleString("ru")}</div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default GreekDetails;