import {useSelector} from "react-redux";

const useMarketData = () => {
    const [corrMtx, divs, fixings, pricingDate, rate, spots, vols, version] = useSelector((state) => {
        const {MarketDataReducer} = state
        return [
            MarketDataReducer.corrMtx,
            MarketDataReducer.divs,
            MarketDataReducer.fixings,
            MarketDataReducer.pricingDate,
            MarketDataReducer.rate,
            MarketDataReducer.spots,
            MarketDataReducer.vols
        ]
    })

    return {
        class_name: "_MarketData_v2",
        corr_mtx: corrMtx,
        divs: divs,
        fixings: fixings,
        pricing_date: pricingDate,
        rate: rate,
        spots: spots,
        vols: vols,
        version: version
    }
}

export default useMarketData