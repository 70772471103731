import './Select.css'
import arrow_up from "./../../static/up.svg";
import arrow_down from "./../../static/down.svg";
import SelectField from "../../routes/Main/ProductSelector/SelectField/SelectField";
import {useState} from "react";

// стили можно наслдовать но они будут унифицированны
const Select = ({values, selected, setSelected, label, disabled, changeState, isMain}) => {
    const [isActive, setIsActive] = useState(false)
    const toggleActive = () => {
        if (!!disabled) return
        setIsActive(!isActive)
    }
    return (
        <div className="select_component">
            <div className="select_component-label">{label}</div>
            <div className={`select_component-selected ${!!disabled && 'disabled'} ${isMain ? "select_component-selected--main" : null} ${changeState ? isMain ? "select_component-selected--change" : "select_component-selected--change-minor" : null}`} onClick={toggleActive}>
                {selected}
                <div className="select_component-img"><img src={isActive ? arrow_up : arrow_down} alt="arrow"/></div>
            </div>
            <div className={`select_component-listener ${isActive ? 'active' : null}`} onClick={toggleActive}></div>
            <div className={`select_component-list ${isActive ? 'active' : null}`}>
                <div className="select_component-field">
                    {values.map((elem, i) => (
                        <div key={i} className={`select_component-field-line ${selected === elem ? "selected" : null}`}
                             onClick={() => {setSelected(elem); setIsActive(false)}}
                        >{elem}</div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Select