import Header from "../Header/Header";
import Main from "../Main/Main";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import useAllTickers from "../../hooks/useAllTickers";
import useMainView from "../../hooks/useMainView";
import {set_refresh_mode_async} from "../../redux/Common/actions";
import ErrorBoundary from "../../components/ErrorHandler";
import axios from "axios";

/**
 * Main Root element for Pricing page. Combine different views and components
 * @returns {JSX.Element}
 * @constructor
 */
const Pricing = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {getAllTickers, getAllGraphs} = useAllTickers()
    const {setMainView} = useMainView()

    useEffect(() => {
        document.title = "Rumberg | Pricing"
        getAllTickers()
        // getAllGraphs()
    }, [])

    useEffect(() => {
        if (localStorage.getItem("oAuth_pricing") === null) {
            navigate("/auth")
            return
        }
        if (localStorage.getItem("oAuth_pricing_uuid") !== null && (sessionStorage.getItem("session_id") === null || sessionStorage.getItem("session_id") === "undefined")) {
            axios.post("/auth_back/get_session_id", {uuid: localStorage.getItem("oAuth_pricing_uuid")}, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(data => {
                if (data.status_code === 1) {
                    localStorage.removeItem("oAuth_pricing")
                    localStorage.removeItem("oAuth_pricing_uuid")
                    sessionStorage.removeItem("session_id")
                    navigate("/auth")
                } else {
                    sessionStorage.setItem("session_id", data.data.data.session_id)
                }
            })
        }
        if (localStorage.getItem("refresh_mode") === null) {
            dispatch(set_refresh_mode_async("DEFAULT"))
            localStorage.setItem("refresh_mode", "DEFAULT")
        } else {
            dispatch(set_refresh_mode_async(localStorage.getItem("refresh_mode")))
        }
        if (localStorage.getItem("rumberg_static_data") !== null) {
            setMainView("DES", "* DES")
            localStorage.removeItem("rumberg_static_data")
        } else if (localStorage.getItem("rumberg_chart_data") !== null) {
            setMainView("GN", "IMOEX USD000000TOD SBER RGBI GN")
            localStorage.removeItem("rumberg_chart_data")
        } else if (localStorage.getItem("rumberg_market_data") !== null) {
            setMainView("DVD", "IMOEX DVD")
            localStorage.removeItem("rumberg_market_data")
        }
    }, [])

    return (
        <div className="pricing">
            <ErrorBoundary>
                <Header selectHeader="pricing"/>
                <Main/>
            </ErrorBoundary>
        </div>
    )
}
export default Pricing