import './VolData.css'

const VolData = ({vols}) => {
    if (!Array.isArray(vols)) return undefined
    const tickers = {}
    vols.forEach(elem => tickers[elem.ticker] = true)
    return (
        <div className="vol__container">
            {Object.keys(tickers).map((elem, i) => (
                <div key={i} className="vol__card">
                    <div className="vol__label">{elem}</div>
                    <div className="vol__line headhead">
                        <p className={"vol__line-strike"}>Strike</p>
                        <p className={"vol__line-data"}>30</p>
                        <p className={"vol__line-data"}>90</p>
                        <p className={"vol__line-data"}>180</p>
                        <p className={"vol__line-data"}>365</p>
                        <p className={"vol__line-data"}>1095</p>
                        <p className={"vol__line-data"}>1825</p>

                    </div>
                    {vols.map((data, j) => (
                        <div key={j}>
                            {(data.ticker === elem) &&
                                <div key={i} className="vol__line">
                                    <p className={"vol__line-strike"}>{data.index}</p>
                                    <p className={"vol__line-data"}>{data[30]}</p>
                                    <p className={"vol__line-data"}>{data[90]}</p>
                                    <p className={"vol__line-data"}>{data[180]}</p>
                                    <p className={"vol__line-data"}>{data[365]}</p>
                                    <p className={"vol__line-data"}>{data[1095]}</p>
                                    <p className={"vol__line-data"}>{data[1825]}</p>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default VolData