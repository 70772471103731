import {
    ALL_DATA_PARTICIPATION,
    CALL_UP_LIMIT_ABS_PARTICIPATION,
    CALL_UP_LIMIT_PARTICIPATION,
    CURRENCY_PARTICIPATION,
    FIXING_DATE_PARTICIPATION,
    FIXING_DATES_PARTICIPATION,
    GREEKS_PARTICIPATION,
    IS_WITHOUT_NOTIONAL_PARTICIPATION,
    LEVERAGE_RATE_PARTICIPATION,
    MODEL_PARTICIPATION,
    N_SIMS_PARTICIPATION,
    NOTIONAL_PARTICIPATION,
    PAYOFF_PARTICIPATION,
    PERIOD_MODE_PARTICIPATION,
    PRICE_CURRENCY_PARTICIPATION,
    REF_SPOTS_PARTICIPATION,
    STRIKE_ABS_PARTICIPATION,
    STRIKE_PARTICIPATION,
    TICKERS_PARTICIPATION,
    TRADING_DATE_PARTICIPATION
} from "./types";
import {makeLog} from "../../utils/logger";

const initialState = {
    className: "Vanilla",
    fixingDates: 365,
    isWithoutNotional: false,
    payoff: "call",
    priceCurrency: "RUB",
    strike: 1.0,
    tickersParticipation: ["SBER"],
    tradingDate: new Date().toISOString().slice(0, 10),
    version: "'v1.0_20230702",
    typology: "OPT",
    callUpLimit: undefined,
    currency: "RUB",

    // services
    currencyOptions: ["RUB", "USD", "EUR", "CNY"],
    refSpots: {
        "SBER": 100
    },
    fixingDate: ["2023-08-30"],
    strikeAbs: 100,
    callUpLimitAbs: undefined,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },
    notional: 100000000,
    periodMode: "days",

    model: "Analytic",
    modelOptions: ["LocalVol", "Analytic"],

    n_sims: 131072,
    leverage_rate: 1.0
}

export const ParticipationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIXING_DATES_PARTICIPATION:
            makeLog("FIXING_DATES_PARTICIPATION", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case IS_WITHOUT_NOTIONAL_PARTICIPATION:
            makeLog("IS_WITHOUT_NOTIONAL_PARTICIPATION", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case PAYOFF_PARTICIPATION:
            makeLog("PAYOFF_PARTICIPATION", state.payoff, action.value)
            return {
                ...state,
                payoff: action.value
            }
        case CURRENCY_PARTICIPATION:
            makeLog("CURRENCY_PARTICIPATION", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case PRICE_CURRENCY_PARTICIPATION:
            makeLog("PRICE_CURRENCY_PARTICIPATION", state.priceCurrency, action.value)
            return {
                ...state,
                priceCurrency: action.value
            }
        case STRIKE_PARTICIPATION:
            makeLog("STRIKE_PARTICIPATION", state.strike, action.value)
            return {
                ...state,
                strike: action.value
            }
        case TICKERS_PARTICIPATION:
            makeLog("TICKERS_PARTICIPATION", state.tickersParticipation, action.value)
            return {
                ...state,
                tickersParticipation: action.value
            }
        case TRADING_DATE_PARTICIPATION:
            makeLog("TRADING_DATE_PARTICIPATION", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case CALL_UP_LIMIT_PARTICIPATION:
            makeLog("CALL_UP_LIMIT_PARTICIPATION", state.callUpLimit, action.value)
            return {
                ...state,
                callUpLimit: action.value
            }
        case REF_SPOTS_PARTICIPATION:
            makeLog("REF_SPOTS_PARTICIPATION", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case FIXING_DATE_PARTICIPATION:
            makeLog("FIXING_DATE_PARTICIPATION", state.fixingDate, action.value)
            return {
                ...state,
                fixingDate: action.value
            }
        case STRIKE_ABS_PARTICIPATION:
            makeLog("STRIKE_ABS_PARTICIPATION", state.strikeAbs, action.value)
            return {
                ...state,
                strikeAbs: action.value
            }
        case CALL_UP_LIMIT_ABS_PARTICIPATION:
            makeLog("CALL_UP_LIMIT_ABS_PARTICIPATION", state.callUpLimitAbs, action.value)
            return {
                ...state,
                callUpLimitAbs: action.value
            }
        case GREEKS_PARTICIPATION:
            makeLog("GREEKS_PARTICIPATION", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case NOTIONAL_PARTICIPATION:
            makeLog("NOTIONAL_PARTICIPATION", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        case PERIOD_MODE_PARTICIPATION:
            makeLog("PERIOD_MODE_PARTICIPATION", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case MODEL_PARTICIPATION:
            makeLog("MODEL_PARTICIPATION", state.model, action.value)
            return {
                ...state,
                model: action.value
            }
        case N_SIMS_PARTICIPATION:
            makeLog("N_SIMS_PARTICIPATION", state.n_sims, action.value)
            return {
                ...state,
                n_sims: action.value
            }
        case LEVERAGE_RATE_PARTICIPATION:
            makeLog("LEVERAGE_RATE_PARTICIPATION", state.leverage_rate, action.value)
            return {
                ...state,
                leverage_rate: action.value
            }
        case ALL_DATA_PARTICIPATION:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}