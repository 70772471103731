import {
    ALL_DATA_COMMON,
    ALL_TICKERS,
    COMMANDS, GRAPH_DATE, GRAPH_MODE, GRAPH_PARAMS,
    IS_CHANGED,
    IS_LOADER_WINDOW, IS_MODAL_WINDOW, MAIN_VIEW,
    POPUP_CONTENT,
    POPUP_STATUS,
    POPUP_TYPE, REFRESH_FIELDS, REFRESH_MODE, TAB_OPTION,
    TRUNCATE_TICKER,
    VIEW_MODE
} from "./types";

export const set_is_changed = (value) => {
    return {
        type: IS_CHANGED,
        value
    }
}

export const set_view_mode = (value) => {
    return {
        type: VIEW_MODE,
        value
    }
}

export const set_popup_status = (value) => {
    return {
        type: POPUP_STATUS,
        value
    }
}

export const set_popup_content = (value) => {
    return {
        type: POPUP_CONTENT,
        value
    }
}

export const set_popup_type = (value) => {
    return {
        type: POPUP_TYPE,
        value
    }
}

export const set_truncate_ticker = (value) => {
    return {
        type: TRUNCATE_TICKER,
        value
    }
}

export const set_is_local_window = value => {
    return {
        type: IS_LOADER_WINDOW,
        value
    }
}

export const set_main_view = value => {
    return {
        type: MAIN_VIEW,
        value
    }
}

export const set_commands = value => {
    return {
        type: COMMANDS,
        value
    }
}

export const set_refresh_mode = value => {
    return {
        type: REFRESH_MODE,
        value
    }
}

export const set_refresh_mode_async = value => {
    return dispatch => {
        dispatch(set_refresh_mode(value))
    }
}

export const set_refresh_fields = value => {
    return {
        type: REFRESH_FIELDS,
        value
    }
}

export const set_graph_params = value => {
    return {
        type: GRAPH_PARAMS,
        value
    }
}

export const set_graph_date = value => {
    return {
        type: GRAPH_DATE,
        value
    }
}

export const set_refresh_by_id = value => {
    return {
        type: REFRESH_FIELDS,
        value
    }
}

export const set_all_tickers = value => {
    return {
        type: ALL_TICKERS,
        value
    }
}

export const set_tab_option = value => {
    return {
        type: TAB_OPTION,
        value
    }
}

export const set_all_data_common = value => {
    return {
        type: ALL_DATA_COMMON,
        value: value.common
    }
}

export const set_is_modal_window = value => {
    return {
        type: IS_MODAL_WINDOW,
        value
    }
}

export const set_graph_mode = value => {
    return {
        type: GRAPH_MODE,
        value
    }
}
