import useNominalDetail from "./useNominalDetail";
import useAutocallDetail from "./useAutocallDetail";
import useCouponDetail from "./useCouponDetail";
import useRedemptionDetail from "./useRedemptionDetail";
import usePerformanceDetail from "./usePerformanceDetail";
import useFixingsDetail from "./useFixingsDetail";
import useOtherDetail from "./useOtherDetail";

const useProductDetails = () => {
    const nominal = useNominalDetail()
    const fixings = useFixingsDetail()
    const autocall = useAutocallDetail()
    const coupon = useCouponDetail()
    const redemption = useRedemptionDetail()
    const performance = usePerformanceDetail()
    const other = useOtherDetail()

    return [nominal, fixings, autocall, coupon, redemption, performance, other]
}

export default useProductDetails