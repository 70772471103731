import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";
import {useProductDetailSpreadOption} from "../../hooks/SpreadOptionProductData/useProductDetailSpreadOption";

export const ProductDataViewSpreadOption = () => {
    const data = useProductDetailSpreadOption()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}