import {useDispatch, useSelector} from "react-redux";
import {set_greeks_autocall} from "../redux/Autocall/actions";
import useViewMode from "./useViewMode";
import {set_greeks_vanilla} from "../redux/Vanilla/actions";
import {set_greeks_basket} from "../redux/CallOnBasket/action";
import {set_greeks_participation} from "../redux/Participation/actions";
import {set_greeks_digital_call} from "../redux/DigitalCall/actions";
import {set_greeks_digital_call_short} from "../redux/DigitalCallShort/actions";
import {set_greeks_spread_option} from "../redux/SpreadOption/actions";

const useGreeks = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const greeks = useSelector(state => {
        const {autocallReducer, VanillaReducer, BasketReducer, ParticipationReducer,
            digitalCallReducer, digitalCallShortReducer, spreadOptionReducer} = state
        if (viewMode.viewMode === "Vanilla") return VanillaReducer.greeks
        if (viewMode.viewMode === "Call On Basket") return BasketReducer.greeks
        if (viewMode.viewMode === "Protected Participation") return ParticipationReducer.greeks
        if (viewMode.viewMode === "Digital Call on Basket") return digitalCallReducer.greeks
        if (viewMode.viewMode === "Digital Call") return digitalCallShortReducer.greeks
        if (viewMode.viewMode === "Spread Option") return spreadOptionReducer.greeks
        return autocallReducer.greeks
    })

    const setGreeks = (pricing) => {
        const result = {
            delta: pricing.Deltas,
            deltaCash: pricing.DeltaCash,
            vega: pricing.Vegas ?? pricing.Vega,
            vegaCash: pricing.VegaCash,
            gammaCash: pricing.GammaCash.Gamma,
            rho: pricing.Rho,
            rhoCash: pricing.RhoCash.Rho,
            theta: pricing.ThetaCash.Theta
        }
        Object.keys(result.delta).forEach(elem => {
            result.delta[elem] = Math.round(result.delta[elem] * 10000) / 100
            if (viewMode.viewMode !== "Vanilla" && viewMode.viewMode !== "Protected Participation") {
                result.vega[elem] = Math.round(result.vega[elem] * 10000) / 100
            }
        })
        result.rho = Math.round(result.rho * 10000) / 100
        // result.theta = Math.round(result.theta * 10000) / 100
        console.log("[ SET GREEKS ]", result)
        if (viewMode.viewMode === "Vanilla") dispatch(set_greeks_vanilla(result))
        if (viewMode.viewMode === "Call On Basket") dispatch(set_greeks_basket(result))
        if (viewMode.viewMode === "Autocall") dispatch(set_greeks_autocall(result))
        if (viewMode.viewMode === "Protected Participation") dispatch(set_greeks_participation(result))
        if (viewMode.viewMode === "Digital Call on Basket") {
            console.error("[ SET GREEKS ]", result)
            dispatch(set_greeks_digital_call(result))
        }
        if (viewMode.viewMode === "Digital Call") dispatch(set_greeks_digital_call_short(result))
        if (viewMode.viewMode === "Spread Option") dispatch(set_greeks_spread_option(result))
    }

    return {
        deltas: greeks.delta,
        deltasCash: greeks.deltaCash,
        vegas: greeks.vega,
        vegasCash: greeks.vegaCash,
        gammaCash: greeks.gammaCash,
        rho: greeks.rho,
        rhoCash: greeks.rhoCash,
        theta: greeks.theta,
        setGreeks
    }
}

export default useGreeks
