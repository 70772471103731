import PricingRequest from "../PricingRequest/PricingRequest";
import FixingDetails from "../FixingDetails/FixingDetails";
import MarketData from "../MarketData/MarketData";
import TradeDetails from "../TradeDetails/TradeDetails";
import GreekDetails from "../GreeksDetails/GreekDetails";

/**
 * Make design for pricing view (PricingRequest, FixingDetails, MarketData, TradeDetails)
 * @param mode {number} - details option (0 - pricing request, 1 - fixing details, 2 - market data, 3 - trade details)
 * @returns {JSX.Element}
 * @constructor
 */
const PricingView = ({mode}) => {
    if (mode === 0) {
        return (
            <PricingRequest/>
        )
    } else if (mode === 1) {
        return (
            <FixingDetails/>
        )
    } else if (mode === 2) {
        return (
            <MarketData/>
        )
    } else if (mode === 3) {
        return (
            <TradeDetails/>
        )
    } else if (mode === 4) {
        return (
            <GreekDetails/>
        )
    } else {
        return (
            <div>some error</div>
        )
    }
}
export default PricingView