import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";
import useProductDetailBasket from "../../hooks/BasketProductData/useProductDetailBasket";

const ProductDetailViewBasket = () => {
    const data = useProductDetailBasket()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}

export default ProductDetailViewBasket;