import {useDispatch, useSelector} from "react-redux";
import {set_market_data_id, set_vols} from "../redux/MarketData/actions";
import useAllMarketData from "./useAllMarketData";
import useRequest from "./useRequest";

const useVols = (ticker) => {
    const dispatch = useDispatch()
    const {post} = useRequest()
    const allData = useAllMarketData()

    const vols = useSelector((store) => {
        const {MarketDataReducer} = store
        return MarketDataReducer.vols
    })

    const changeVols = (newVols) => {
        const tmp = JSON.parse(JSON.stringify(allData))
        tmp.vols = {...vols, [ticker]: newVols}
        post(
            "/rdict/post",
            tmp,
            undefined,
            (data) => {dispatch(set_market_data_id(data))}
        )
            .then(() => console.log(" __ POST VOLS __ "))
        dispatch(set_vols({...vols, [ticker]: newVols}))
    }

    return [
        vols[ticker],
        changeVols
    ]
}

export default useVols