import './DesData.css'
import Modal from "../Modal/Modal";
import {useState} from "react";
import openSymbol from './../../static/open-symbol.png'
import {useDispatch} from "react-redux";
import {set_is_local_window} from "../../redux/Common/actions";
import useRequest from "../../hooks/useRequest";

// Импортируем React и необходимые хуки и утилиты
const DesData = ({ data }) => {
    // Локальные состояния для управления активностью модальных окон
    const [isActive, setIsActive] = useState(false)
    const [isActiveStrikes, setIsActiveStrikes] = useState(false)
    // Состояние для управления отображаемыми данными в модальных окнах
    const [displayData, setIsDisplayData] = useState([])
    // Состояния для хранения заголовков модальных окон
    const [title, setTitle] = useState("")
    const [titleStrikes, setTitleStrikes] = useState("")
    // Хук для работы с Redux
    const dispatch = useDispatch()
    // Хук для отправки и получения данных через API
    const {post, get} = useRequest()

    // Функция обработки клика по элементу данных
    const handleClick = async (shownData, rTitle, type) => {
        // Логирование полученных данных для отладки
        console.log(shownData, typeof shownData, Array.isArray(shownData), JSON.stringify(shownData))
        // Проверка, являются ли данные массивом
        if (!Array.isArray(shownData)) {
            // Активация локального окна и чистка данных продукта
            dispatch(set_is_local_window(true))
            Object.keys(shownData.product).forEach(key => {
                if (shownData.product[key] === null) {
                    delete shownData.product[key]
                }
                if (key === "payoff" && shownData.product[key] === 'c') {
                    shownData.product[key] = 'call'
                }
            })
            // Отправка данных на сервер и получение ответа
            const data = await post(
                "/rdict/post",
                JSON.stringify(shownData),
                { headers: { "Content-Type": "application/json" } },
                () => {},
                () => {}
            )
            // Получение обновленных данных с сервера
            const rData = await get(
                `/market_data/refresh/`,
                { params: { iid: data } },
                () => {},
                () => {}
            )
            // Парсинг полученных данных
            const parseData = JSON.parse(rData)
            if (parseData.market_data.fixings && Object.keys(parseData.market_data.fixings).length > 0) {
                const newIID = await post(
                    `/rdict/post`,
                    parseData,
                    undefined,
                    () => {},
                    () => {}
                )
                parseData.events = await get(
                    `/update_accrued/${newIID}`,
                    undefined,
                    () => {},
                    () => {}
                )
            }
            // Открытие новой страницы с результатом
            const result = await post(
                "/rdict/post",
                parseData,
                { headers: { "Content-Type": "application/json" } },
                () => {},
                () => {}
            )
            window.open("/pricing?restore=" + result, '_blank', 'noopener,noreferrer')
            dispatch(set_is_local_window(false))
        } else {
            // Обработка различных типов данных
            if (type === "coupon") {
                setIsDisplayData(shownData)
                const resultTitle = rTitle.replaceAll("_", " ")
                setTitle(resultTitle)
                setIsActive(true)
            }
            if (type === "strike") {
                setIsDisplayData(shownData)
                const resultTitle = rTitle.replaceAll("_", " ")
                setTitleStrikes(resultTitle)
                setIsActiveStrikes(true)
            }
        }
    }

    // Рендеринг компонента
    return (
        <div className="graph__description">
            {/* Модальное окно для strikes */}
            <Modal isActive={isActiveStrikes}>
                <div onClick={() => setIsActiveStrikes(false)} className="des__wrapper">
                    <div onClick={e => e.stopPropagation()} className="des__data">
                        <p onClick={() => setIsActiveStrikes(false)} className="des__data-close">&times;</p>
                        <div className="des__data-card">
                            <div className="des__data-card-title">{titleStrikes}</div>
                            <div className="des__data-line headhead">
                                <p className="des__data-line-fixings">Fixing Date</p>
                                <p className="des__data-line-fixings">AC Strike</p>
                                <p className="des__data-line-fixings">CPN Strike</p>
                            </div>
                            {displayData.map((elem, i) => (
                                <div key={i} className="des__data-line">
                                    <p className="des__data-line-fixings">{elem.fixing_date}</p>
                                    <p className="des__data-line-fixings">{elem.ac_strike}</p>
                                    <p className="des__data-line-fixings">{elem.cpn_strike}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
            {/* Модальное окно для coupons */}
            <Modal isActive={isActive}>
                <div onClick={() => setIsActive(false)} className="des__wrapper">
                    <div onClick={e => e.stopPropagation()} className="des__data">
                        <p onClick={() => setIsActive(false)} className="des__data-close">&times;</p>
                        <div className="des__data-card">
                            <div className="des__data-card-title">{title}</div>
                            <div className="des__data-line headhead">
                                <p className="des__data-line-id">ID</p>
                                <p className="des__data-line-start">Start Date</p>
                                <p className="des__data-line-end">End Date</p>
                                <p className="des__data-line-amount">Amount</p>
                                <p className="des__data-line-rate">Rate</p>
                                <p className="des__data-line-payment">Payment Date</p>
                            </div>
                            {displayData.map((elem, i) => (
                                <div key={i} className="des__data-line">
                                    <p className="des__data-line-id">{elem.id}</p>
                                    <p className="des__data-line-start">{elem.start_date}</p>
                                    <p className="des__data-line-end">{elem.end_date}</p>
                                    <p className="des__data-line-amount">{elem.amount}</p>
                                    <p className="des__data-line-rate">{elem.rate}</p>
                                    <p className="des__data-line-payment">{elem.payment_date}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
            {/* Вывод карточек с информацией */}
            {Object.keys(data).map((label, i) => (
                <div key={i} className="des__card-wrapper">
                    <div className={"des__card"}>
                        <div className="des__label">{label}</div>
                        {data[label].map((elem, i) => (
                            <div key={i} className={`des__line ${elem.type === "title" ? "des__line-subtitle" : null}`}>
                                <p className={"des__line-title"}>{elem.title}</p>
                                {elem.data
                                    ? <p className={"des__line-value"} onClick={() => handleClick(elem.data, elem.title, elem.type)}>
                                        {elem.value === "price"
                                            ? <p className={"des__line-price"}>Price</p>
                                            : <div className="des__line-img-container">
                                                <img src={openSymbol} alt=""/>
                                            </div>
                                        }
                                    </p>
                                    : <p className={`des__line-value`}>{elem.value}</p>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

// Экспорт компонента для использования в других частях приложения
export default DesData