import React from 'react';
import axios from "axios";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)
        this.state = { hasError: false, errorInfo: null, error: null };
    }

    static getDerivedStateFromError(error) {
        // Обновите состояние так, чтобы следующий рендер показал фоллбек-UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно логировать ошибку с использованием сервиса отслеживания ошибок
        console.log(error, errorInfo);
        this.setState({ error: error, errorInfo: errorInfo, hasError: true })
    }

    render() {
        if (this.state.hasError) {
            const url = window.location.href
            const uuid = localStorage.getItem("oAuth_pricing_uuid")
            const role = localStorage.getItem("oAuth_pricing")
            const session_id = sessionStorage.getItem("session_id")
            axios.post("/logger/log", {
                message: `Error in component`,
                uuid: uuid ?? "unauthorized",
                role: role ?? "unauthorized",
                level: "ERROR",
                description: `Detail: ${this.state.error && this.state.error.toString()}`,
                response: "",
                url,
                session_id
            })
            return (
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <h1>Server Issue</h1>
                    <p>{this.state.error && this.state.error.toString()}</p>
                    {/*<p>{this.state.errorInfo.componentStack}</p>*/}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;