import './GraphVol.css'
import blueChart from "../../static/blueChart.svg";
import whiteChart from "../../static/whiteChart.svg";
import blueSurf from "../../static/blueSurf.svg";
import whiteSurf from "../../static/whiteSurf.svg";
import blueList from "../../static/blueList.svg";
import whiteList from "../../static/whiteList.svg";
import {useEffect, useState} from "react";
import InputText from "../InputText/InputText";
import VolSurface from "../VolSurface/VolSurface";
import GraphParams from "../GraphParams/GraphParams";
import useVols from "../../hooks/useVols";
import SimpleMainButton from "../SimpleMainButton/SimpleMainButton";
import {compareObjects} from "../../utils/utilsFunctions";
import {useDispatch} from "react-redux";
import {set_is_modal_window} from "../../redux/Common/actions";
import SnapShotBtn from "../../routes/Header/SnapShotBtn/SnapShotBtn";

const GraphVol = ({ticker, setVolChange}) => {
    const dispatch = useDispatch()
    const [mode, setMode] = useState("scatter")
    const [volData, setVolData] = useVols(ticker)
    const [localChange, setLocalChange] = useState(false)
    const [resetTrigger, setResetTrigger] = useState(false)
    console.log("GraphVol", ticker, volData)
    const [tmpData, setTmpData] = useState({
        amax: volData.amax,
        amin: volData.amin,
        short: volData.short,
        long: volData.long,
        eps_skew: volData.eps_skew,
        skew: volData.skew,
        eps_curve: volData.eps_curve,
        curve: volData.curve,
        speed: volData.speed,
        vol_floor: volData.vol_floor,
        vol_cap: volData.vol_cap,
        model: "parametric",
        ref_spot: volData.ref_spot,
    })

    useEffect(() => {
        console.log("[ OPT OPEN VOL ]", ticker)
    }, [])

    const save = () => {
        console.log("save", JSON.stringify(tmpData), JSON.stringify(volData))
        if (!compareObjects(tmpData, volData)) {
            console.log("save not equals")
            setVolData(tmpData)
            setVolChange(true)
        }
        dispatch(set_is_modal_window(false))
        // setIsActive(false)
    }

    const reset = () => {
        setTmpData({
            amax: volData.amax,
            amin: volData.amin,
            short: volData.short,
            long: volData.long,
            eps_skew: volData.eps_skew,
            skew: volData.skew,
            eps_curve: volData.eps_curve,
            curve: volData.curve,
            speed: volData.speed,
            vol_floor: volData.vol_floor,
            vol_cap: volData.vol_cap,
            model: "parametric",
            ref_spot: volData.ref_spot,
        })
        setResetTrigger(true)
        setLocalChange(false)
    }

    return (
        <div className="graph_vol">
            <div className="graph_vol-property">
                <div className="graph_vol-property-block">
                    <div className={`graph_vol-property-header`}>
                        <div className={"graph_vol-property-ticker"}>
                            <InputText value={ticker} title={"Ticker"} changeCallback={() => {}}/>
                        </div>
                        <div className={`graph_vol-property-type`}>
                            {/* TODO тут классы подобраны по старым стилям */}
                            <div className={`graph_vol-property-type-label`}>
                                Chart Type
                            </div>
                            <div className={'graph_vol-property-type-nav'}>
                                <div onClick={() => setMode("scatter")}
                                     className={`graph_vol-property-type-btn`}>
                                    <img src={mode === "scatter" ? blueChart : whiteChart} alt={"chart"}/>
                                </div>
                                <div onClick={() => setMode("surface")}
                                     className={`graph_vol-property-type-btn`}>
                                    <img src={mode === "surface" ? blueSurf : whiteSurf} alt={"Surf"}/>
                                </div>
                                <div onClick={() => setMode("list")}
                                     className={`graph_vol-property-type-btn`}>
                                    <img src={mode === "list" ? blueList : whiteList} alt={"List"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GraphParams data={tmpData} setData={setTmpData} setLocalChange={setLocalChange} ticker={ticker}/>
                </div>
            </div>
            <div className="graph_vol-graph">
                <VolSurface setIsChange={() => {}} ticker={ticker} mode={mode} volData={volData} tmpData={tmpData} resetTrigger={{resetTrigger, setResetTrigger}}/>
            </div>
            <div className="graph_vol-actions">
                <div>
                    <SimpleMainButton text={"Save"} callback={() => save()}/>
                </div>
                <div>
                    <SimpleMainButton text={"Reset"} style_={"grey"} callback={() => reset()}/>
                </div>
                <SnapShotBtn/>
            </div>
        </div>
    )
}

export default GraphVol