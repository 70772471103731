import useProductDetailVanilla from "../../hooks/VanillaProductData/useProductDetailVanilla";
import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";

const ProductDetailViewVanilla = () => {
    const data = useProductDetailVanilla()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}

export default ProductDetailViewVanilla;