import {
    ALL_DATA_SPREAD_OPTION,
    CURRENCY_SPREAD_OPTION, FIXING_DATES_SPREAD_OPTION, GREEKS_SPREAD_OPTION,
    IS_WITHOUT_NOTIONAL_SPREAD_OPTION, NOTIONAL_SPREAD_OPTION, PERIOD_MODE_SPREAD_OPTION,
    REF_SPOTS_SPREAD_OPTION, SPREAD_OPTION_ORDER_SPREAD_OPTION,
    TICKERS_SPREAD_OPTION, TIME_TO_MATURITY_SPREAD_OPTION, TRADING_DATE_SPREAD_OPTION
} from "./types";

export const set_tickers_spread_option = (value) => {
    return {
        type: TICKERS_SPREAD_OPTION,
        value
    }
}

export const set_ref_spots_spread_option = (value) => {
    return {
        type: REF_SPOTS_SPREAD_OPTION,
        value
    }
}

export const set_is_without_notional_spread_option = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL_SPREAD_OPTION,
        value
    }
}

export const set_currency_spread_option = (value) => {
    return {
        type: CURRENCY_SPREAD_OPTION,
        value
    }
}

export const set_fixing_dates_spread_option = (value) => {
    return {
        type: FIXING_DATES_SPREAD_OPTION,
        value
    }
}

export const set_time_to_maturity_spread_option = (value) => {
    return {
        type: TIME_TO_MATURITY_SPREAD_OPTION,
        value
    }
}

export const set_period_mode_spread_option = (value) => {
    return {
        type: PERIOD_MODE_SPREAD_OPTION,
        value
    }
}

export const set_spread_option_order_spread_option = (value) => {
    return {
        type: SPREAD_OPTION_ORDER_SPREAD_OPTION,
        value
    }
}

export const set_trading_date_spread_option = (value) => {
    return {
        type: TRADING_DATE_SPREAD_OPTION,
        value
    }
}

export const set_greeks_spread_option = (value) => {
    return {
        type: GREEKS_SPREAD_OPTION,
        value
    }
}

export const set_all_data_spread_option = (value) => {
    return {
        type: ALL_DATA_SPREAD_OPTION,
        value: value.product
    }
}

export const set_notional_spread_option = (value) => {
    return {
        type: NOTIONAL_SPREAD_OPTION,
        value
    }
}