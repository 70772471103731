import './TickersWindow.css'
import useTickerDependencies from "../../hooks/useTickerDependencies";
import useViewMode from "../../hooks/useViewMode";
import useTickerDependenciesVanilla from "../../hooks/useTickerDependenciesVanilla";
import useTickerDependenciesMarketData from "../../hooks/marketDataProduct/useTickerDependenciesMarketData";
import useTickerDependenciesCallOnBasket from "../../hooks/useTickerDependenciesCallOnBasket";
import useTickerDependenciesDigitalCall from "../../hooks/useTickerDependenciesDigitalCall";
import {useTickerDependenciesDigitalCallShort} from "../../hooks/useTickerDependenciesDigitalCallShort";
import {useTickerDependenciesSpreadOption} from "../../hooks/useTickerDependenciesSpreadOption";

const TickersWindow = () => {
    const viewManager = useViewMode()
    let data = []
    if (viewManager.viewMode === "Autocall") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependencies()
    } else if (viewManager.viewMode === "Vanilla" || viewManager.viewMode === "Protected Participation") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesVanilla()
        console.log("[ VIEW MODE TEST ]", viewManager.viewMode)
    } else if (viewManager.viewMode === "MarketData") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesMarketData()
    } else if (viewManager.viewMode === "Call On Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesCallOnBasket()
    } else if (viewManager.viewMode === "Digital Call on Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesDigitalCall()
    } else if (viewManager.viewMode === "Digital Call") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesDigitalCallShort()
    } else if (viewManager.viewMode === "Spread Option") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        data = useTickerDependenciesSpreadOption()
    }

    if (data.tickers === undefined) {
        data.tickers = new Array();
    }
    return (
        <div className="tickers_window">
            {data.tickers.map((ticker, index) => {
                    return (
                        <div className="tickers_window-ticker"
                             key={index}
                             onClick={() => data.removeTicker(ticker)}
                        >
                            <p>{ticker}</p>
                            <p className="ticker_window-remove">&times;</p>
                        </div>
                    )
                }
            )}
            {data.tickers.length === 0
                ? <></>
                : <div onClick={() => data.removeAllTickers()} className="tickers_window-ticker-remove">Remove all <span
                    className="ticker_window-remove">&times;</span></div>
            }
        </div>
    )
}

export default TickersWindow