import "./Container.css"
const Container = ({ children }) => {
    return (
        <div className="container"
             data-testid="container">
            {children}
        </div>
    )
}

export default Container