import './TickerHint.css'


const TickerHint = ({ isActive, command, setCommand, countDown,
                        resetClickDown, targetCommand, tickers }) => {

    if (!isActive) return undefined

    if (targetCommand === '') return undefined

    if (tickers.length === 0) return (
        <div className="ticker-hint">
            <div className="ticker-hint-item">No matches</div>
        </div>
    )


    return (
        <div className="ticker-hint" onMouseOver={() => resetClickDown(0)}>
            {tickers.map((ticker, i) => {
                return (
                    <div key={i} className={`ticker-hint-item ${i === countDown - 1 ? 'active' : ""}`}
                         onClick={() => setCommand(ticker)}>
                        {ticker}
                    </div>
                )
            }
            )}
        </div>
    )
}

export default TickerHint