import './GraphList.css'
import {CSVLink} from "react-csv";
import dwnImage from "../../static/download_blue_16.svg";
import {createVoDates} from "../../utils/utilsFunctions";
import {useSelector} from "react-redux";
import useMarketData_do_ref from "../../hooks/useMarketData_do_ref";

const GraphList = ({ticker, graphData}) => {
    const x = [30, 90, 180, 365, 1095]
    const tradingDate = useSelector(state => state.autocallReducer.tradingDate)
    const data = useMarketData_do_ref(ticker).spot
    const dates = createVoDates(x, tradingDate)
    const [y, y_30, y_90, y_180, y_365, y_1095] = graphData
    const prepareDataToCSV = () => {
        const data = [["Strike, %", "Strike, abs", ...dates]]
        y.map((el, i) => {
            data[i + 1] = [`${Math.round(el * 100)}%`, (data * el).toFixed(2),
                `${y_30[i].toFixed(1)}%`, `${y_90[i].toFixed(1)}%`,
                `${y_180[i].toFixed(1)}%`, `${y_365[i].toFixed(1)}%`,
                `${y_1095[i].toFixed(1)}%`]
            return undefined
        })
        return data
    }

    return (
        <div className={`graph-list`}>
            <div className="graph-list-title">
                Vol
                <div className="market-table-downloads_vol">
                    <div className="market-table-downloads-text">
                        <CSVLink filename={"vol_values"} data={prepareDataToCSV()}>
                            <img src={dwnImage} alt="dwn icon"/>
                        </CSVLink>
                    </div>
                </div>
            </div>
            <div className="graph-table">
                <div className="graph-table-context">
                    <div className="graph-table-header">
                        <div className={`graph-table-number graph-table-number-header`}>Strike, %</div>
                        <div className={`graph-table-ok graph-table-ok-header`}>Strike, abs</div>
                        {dates.map((el, i) => (
                            <div key={i}
                                 className={`graph-table-date graph-table-date-header`}>
                                {el}
                            </div>
                        ))}
                    </div>
                    {y.map((el, i) => (
                        <div className={`graph-table-line`}>
                            <div className={"graph-table-number"}>{Math.round(el * 100)}%</div>
                            <div className="graph-table-ok">{(data * el).toFixed(2)}</div>
                            <div className={"graph-table-date"}>
                                {y_30[i].toFixed(1)} %
                            </div>
                            <div className={"graph-table-date"}>
                                {y_90[i].toFixed(1)} %
                            </div>
                            <div className={"graph-table-date"}>
                                {y_180[i].toFixed(1)} %
                            </div>
                            <div className={"graph-table-date"}>
                                {y_365[i].toFixed(1)} %
                            </div>
                            <div className={"graph-table-date"}>
                                {y_1095[i].toFixed(1)} %
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GraphList