import './SnapShotBtn.css'
import share from '../../../static/share.png'
import copy from 'clipboard-copy'
import useFullSnapshot from "../../../hooks/snapshots/useFullSnapshot";
import useInfoPopup from "../../../hooks/useInfoPopup";
import {useDispatch} from "react-redux";
import {set_is_local_window} from "../../../redux/Common/actions";
import {Tooltip} from "@mui/material";

const SnapShotBtn = () => {
    const {getFullSnapshot} = useFullSnapshot()
    const {setPopupStatus} = useInfoPopup()
    const dispatch = useDispatch()

    const snapshot = () => {
        dispatch(set_is_local_window(true))
        getFullSnapshot()
            .then(res => {
                if (res !== undefined) {
                    console.log("[ LOCATION ]", window.location.pathname.split("/"))
                    copy(`${window.location.origin}/${window.location.pathname.split("/")[1]}/${res}`)
                        .then(() => {
                            setPopupStatus(true, 'Copied to clipboard', true)
                        })
                }
            })
            .finally(() => {
                dispatch(set_is_local_window(false))
            })
    }
    return (
        <Tooltip arrow title={"Press this button to get a hyperlink for current state of the terminal. Send this link to your colleague to exchange information seen on display"}>
            <div onClick={snapshot} className="snapshot-btn__container">
                <img src={share} alt="share"/>
            </div>
        </Tooltip>
    )
}

export default SnapShotBtn;