import {useDispatch, useSelector} from "react-redux";
import {set_is_changed} from "../redux/Common/actions";

const useChangeState = () => {
    const dispatch = useDispatch();
    const isChange = useSelector((state) => {
        const {commonReducer} = state;
        return commonReducer.isChanged
    })
    const setChange = (value, prev, new_) => {
        console.log(value, prev, new_)
        if (value && prev !== new_) {
            dispatch(set_is_changed(value))
        } else if (!value) {
            dispatch(set_is_changed(value))
        }
    }

    return {setChange, isChange}
}

export default useChangeState;