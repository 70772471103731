import {
    ALL_DATA_MARKET_DATA_PRODUCT,
    REF_SPOTS_MARKET_DATA_PRODUCT,
    TICKERS_MARKET_DATA_PRODUCT,
    TRADING_DATE_MARKET_DATA_PRODUCT
} from "./types";
import {makeLog} from "../../utils/logger";

const initialState = {
    tickersMD: [],
    refSpotsMD: {},
    tradingDateMD: new Date().toISOString().slice(0, 10)
}

export const MarketDataProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKERS_MARKET_DATA_PRODUCT:
            makeLog("TICKERS_MARKET_DATA_PRODUCT", state.tickersMD, action.value)
            return {
                ...state,
                tickersMD: action.value
            }
        case REF_SPOTS_MARKET_DATA_PRODUCT:
            makeLog("REF_SPOTS_MARKET_DATA_PRODUCT", state.refSpotsMD, action.value)
            return {
                ...state,
                refSpotsMD: action.value
            }
        case TRADING_DATE_MARKET_DATA_PRODUCT:
            makeLog("TRADING_DATE_MARKET_DATA_PRODUCT", state.tradingDateMD, action.value)
            return {
                ...state,
                tradingDateMD: action.value
            }
        case ALL_DATA_MARKET_DATA_PRODUCT:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}
