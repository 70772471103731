import './Auth.css'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import useRequest from "../../hooks/useRequest";

const Auth = () => {
    const [login, setLogin] = useState("")
    const [pass, setPass] = useState("")
    const [error, setError] = useState("")
    const {get, post} = useRequest()
    // const loginFull = 'full'
    // const passFull = 'full'

    const loginData = [
        {
            login: 'full',
            pass: 'full',
            role: 'full'
        },
        {
            login: "aplyasunov",
            pass: "wQWingoB",
            role: "demo"
        },
        {
            login: "ashabanov",
            pass: "cttBqqVn",
            role: "demo"
        },
        {
            login: "isorokin",
            pass: "jMTqNbyd",
            role: "demo"
        }
    ]
    const navigate = useNavigate()
    useEffect(() => {
        document.title = "Rumberg | Auth"
    })
    // const loginTiny = 'tiny'
    // const passTiny = 'tiny'
    const register = async () => {
        // if (loginFull === login && passFull === pass) {
        //     localStorage.setItem("oAuth_pricing", "full")
        // } else {
        //     localStorage.setItem("oAuth_pricing", "tiny")
        // }
        const resp = await axios.post("/auth_back/login", {login, password: pass}, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        console.log("[ LOGIN PROCESS ]", resp.data)
        console.log("[ LOGIN PROCESS ]", resp.data.data.role, resp.data.data.uuid, resp.data.data.session_id)
        if (resp.data.status_code !== 0) {
            setError(resp.data.message)
            return
        }
        localStorage.setItem("oAuth_pricing", resp.data.data.role)
        localStorage.setItem("oAuth_pricing_uuid", resp.data.data.uuid)
        sessionStorage.setItem("session_id", resp.data.data.session_id)
        // for (const item of loginData) {
        //     if (item.login === login && item.pass === pass) {
        //         localStorage.setItem("oAuth_pricing", item.role)
        //         navigate("/")
        //         return
        //     }
        // }
        // localStorage.setItem("oAuth_pricing", "tiny")
        navigate("/")
    }
    return (
        <div className="auth__form">
            <div className="auth__form-container">
                <div className="form-outline">
                    <input type="email" className="form-control" id="form1" value={login}
                           onChange={e => {
                               setLogin(e.target.value)
                               setError("")
                           }}/>
                    <label className="form-label" htmFlor="form1">Login</label>
                </div>
                <div className="form-outline">
                    <input type="password" className="form-control" id="form2" value={pass}
                           onChange={e => {
                               setPass(e.target.value)
                               setError("")
                           }}/>
                    <label className="form-label" htmlFor="form2">Password</label>
                </div>
                {error !== "" && <p className={"signin__error"}>{error}</p>}
                <button className="signin__btn" onClick={() => register()}>Sign in</button>
                {/*<p className="auth__description">Just click "Sign In" for use DEMO version</p>*/}
            </div>
        </div>

    );
}

export default Auth