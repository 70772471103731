import {useDispatch, useSelector} from "react-redux";
import {set_ac_performance, set_cpn_performance, set_final_performance} from "../../redux/Autocall/actions";
import useChangeState from "../useChangeState";

const usePerformanceDetail = () => {
    const dispatch = useDispatch();
    const changeState = useChangeState();

    const [acPerformance, cpnPerformance, finalPerformance, snapshot] = useSelector((store) => {
        const {autocallReducer} = store;
        return [autocallReducer.acPerformance, autocallReducer.cpnPerformance, autocallReducer.finalPerformance, autocallReducer.snapshot]
    })

    const setAcPerformance = (value) => {
        changeState.setChange(true, acPerformance, value)
        dispatch(set_ac_performance(value))
    }

    const setCpnPerformance = (value) => {
        changeState.setChange(true, cpnPerformance, value)
        dispatch(set_cpn_performance(value))
    }

    const setFinalPerformance = (value) => {
        changeState.setChange(true, finalPerformance, value)
        dispatch(set_final_performance(value))
    }

    return {
        title: "Performance",
        data: [
            {
                name: "AC",
                value: acPerformance,
                onChange: setAcPerformance,
                change: snapshot ? snapshot.acPerformance !== acPerformance : false,
                type: {
                    name: "selector",
                    options: ["WoF", "Avg"]
                }
            },
            {
                name: "CPN",
                value: cpnPerformance,
                onChange: setCpnPerformance,
                change: snapshot ? snapshot.cpnPerformance !== cpnPerformance : false,
                type: {
                    name: "selector",
                    options: ["WoF", "Avg"]
                }
            },
            {
                name: "Final",
                value: finalPerformance,
                onChange: setFinalPerformance,
                change: snapshot ? snapshot.finalPerformance !== finalPerformance : false,
                type: {
                    name: "selector",
                    options: ["WoF", "Avg"]
                }
            }
            ]
    }
}

export default usePerformanceDetail;