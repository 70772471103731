import {useDispatch, useSelector} from "react-redux";
import {set_tickers} from "../redux/Vanilla/actions";
import {set_divs, set_service_divs, set_service_vols, set_spots, set_vols} from "../redux/MarketData/actions";
import {useState} from "react";
import useViewMode from "./useViewMode";
import {set_tickers_participation} from "../redux/Participation/actions";

const useTickerDependenciesVanilla = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers] = useSelector((store) => {
        const {VanillaReducer, ParticipationReducer} = store
        if (viewMode.viewMode === "Vanilla") {
            return [VanillaReducer.tickersVanilla]
        } else if (viewMode.viewMode === "Protected Participation") {
            return[ParticipationReducer.tickersParticipation]
        }
    })

    const saveTicker = (ticker) => {
        if (viewMode.viewMode === "Vanilla") {
            dispatch(set_tickers([ticker.toUpperCase()]))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_tickers_participation([ticker.toUpperCase()]))
            console.log("[ CHECK SAVED TICKER PARTICIPATION ]", ticker.toUpperCase())
        }

        dispatch(set_spots({[ticker.toUpperCase()]: 100}))
        dispatch(set_vols({[ticker.toUpperCase()]: {
                "amax": 0.9,
                "amin": 0.9,
                "curve": 50,
                "eps_curve": 50,
                "eps_skew": 50,
                "long": 33.9,
                "model": "parametric",
                "short": 17.5,
                "skew": -6,
                "speed": 50,
                "vol_cap": 10,
                "vol_floor": 0.1
            }}))
        dispatch(set_divs({[ticker.toUpperCase()]: []}))
        dispatch(set_service_vols({[ticker.toUpperCase()]: '📈'}))
        dispatch(set_service_divs({[ticker.toUpperCase()]: '📈'}))
        setIsSaved(true)
    }

    const removeTicker = () => {
        if (viewMode.viewMode === "Vanilla") {
            dispatch(set_tickers([]))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_tickers_participation([]))
        }
        dispatch(set_spots({}))
        dispatch(set_vols({}))
        dispatch(set_divs({}))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    const removeAllTickers = () => {
        console.log("try to remove all tickers")
        if (viewMode.viewMode === "Vanilla") {
            dispatch(set_tickers([]))
        } else if (viewMode.viewMode === "Protected Participation") {
            console.log("try to remove participation")
            dispatch(set_tickers_participation([]))
        }
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved,
    }

}

export default useTickerDependenciesVanilla