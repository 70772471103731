import {useDispatch, useSelector} from "react-redux";
import {set_payoff, set_strike, set_strike_abs} from "../../redux/Vanilla/actions";

const useDetailsVanilla = () => {
    const dispatch = useDispatch()
    const [payoff] = useSelector((state) => {
        const {VanillaReducer} = state;
        return [VanillaReducer.payoff]
    })
    const [refSpots, strike, strikeAbs, tickers] = useSelector((state) => {
        const {VanillaReducer} = state;
        return [VanillaReducer.refSpots, VanillaReducer.strike, VanillaReducer.strikeAbs, VanillaReducer.tickersVanilla]
    })

    const setStrike = (value) => {
        const tmp = Number((value / 100).toFixed(2))
        const abs = Number(tmp * refSpots[tickers[0]].toFixed(2))
        dispatch(set_strike(tmp))
        dispatch(set_strike_abs(abs))
    }

    const setStrikeAbs = (value) => {
        const tmp = Number(((value / refSpots[tickers[0]])).toFixed(2))
        dispatch(set_strike_abs(value))
        dispatch(set_strike(tmp))
    }

    const setPayoff = (value) => {
        dispatch(set_payoff(value))
    }

    console.log("DEB", strike)
    return {
        title: "Details",
        data: [
            {
                name: "Exercise",
                value: payoff,
                onChange: setPayoff,
                isMain: true,
                type: {
                    name: "selector",
                    options: ["call", "put"]
                }
            },
            {
                name: "Strike, %",
                value: strike * 100,
                isMain: true,
                onChange: setStrike,
                type: {
                    name: "number"
                }
            },
            {
                name: "Strike, abs",
                value: strikeAbs,
                onChange: setStrikeAbs,
                type: {
                    name: "number"
                }
            }
        ]
    }

}

export default useDetailsVanilla