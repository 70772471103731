import useViewMode from "../useViewMode";
import useAutocallSnapshot from "./useAutocallSnapshot";
import useRequest from "../useRequest";
import useVanillaSnapshot from "./useVanillaSnapshot";
import useCallOnBasketSnapshot from "./useCallOnBasketSnapshot";
import useParticipationSnapshot from "./useParticipationSnapshot";
import useMarketDataProductSnapshot from "./useMarketDataProductSnapshot";
import useDigitalCallSnapshot from "./useDigitalCallSnapshot";
import {useDigitalCallShortSnapshot} from "./useDigitalCallShortSnapshot";
import {useSpreadOptionSnapshot} from "./useSpreadOptionSnapshot";

const useFullSnapshot = () => {
    const {viewMode} = useViewMode()
    const {post} = useRequest()
    const autocallData = useAutocallSnapshot()
    const vanillaData = useVanillaSnapshot()
    const callOnBasketData = useCallOnBasketSnapshot()
    const participationData = useParticipationSnapshot()
    const marketData = useMarketDataProductSnapshot()
    const digitalCallData = useDigitalCallSnapshot()
    const digitalCallShortData = useDigitalCallShortSnapshot()
    const spreadOption = useSpreadOptionSnapshot()


    const getFullSnapshot = async () => {
        let requestData = undefined
        if (viewMode === "Autocall") {
            requestData = autocallData
        } else if (viewMode === "Vanilla") {
            requestData = vanillaData
        } else if (viewMode === "Call On Basket") {
            requestData = callOnBasketData
        } else if (viewMode === "Protected Participation") {
            requestData = participationData
        } else if (viewMode === "MarketData") {
            requestData = marketData
        } else if (viewMode === "Digital Call on Basket") {
            requestData = digitalCallData
        } else if (viewMode === "Digital Call") {
            requestData = digitalCallShortData
        } else if (viewMode === "Spread Option") {
            requestData = spreadOption
        }
        return await post(
            "/rdict/post",
            JSON.stringify(requestData),
            undefined,
            () => {},
            () => {}
        )
    }

    const getGraphSnapshot = async (command, nextHash) => {
        let requestData = undefined
        let tmp = undefined
        if (viewMode === "Autocall") {
            requestData = autocallData
        } else if (viewMode === "Vanilla") {
            requestData = vanillaData
        } else if (viewMode === "Call On Basket") {
            requestData = callOnBasketData
        } else if (viewMode === "Protected Participation") {
            requestData = participationData
        } else if (viewMode === "MarketData") {
            requestData = marketData
        } else if (viewMode === "Digital Call on Basket") {
            requestData = digitalCallData
        } else if (viewMode === "Digital Call") {
            requestData = digitalCallShortData
        } else if (viewMode === "Spread Option") {
            requestData = spreadOption
        }
        if (requestData) {
            tmp = JSON.parse(JSON.stringify(requestData))
            tmp.common.command = command
        }
        if (nextHash) {
            tmp["hash"] = nextHash
        }
        return await post(
            "/rdict/post",
            JSON.stringify(tmp),
            undefined,
            () => {},
            () => {}
        )
    }

    return {
        getFullSnapshot,
        getGraphSnapshot
    }
}

export default useFullSnapshot;