import './Loader.css'
import image from './../../static/load.png'
const Loader = () => {
  return (
      <div className={'loader-block'}>
          <img src={image} alt={"epkfmlb[el"}/>
      </div>
  )
}

export default Loader