import './DownloadAll.css'
import useAllData from "../../hooks/useAllData";
import {CSVLink} from "react-csv";

const DownloadAll = () => {
    const data = useAllData()

    return (
        <div className={"download_all"}>
            <CSVLink data={data} filename={"all_data.csv"}>Download All Data</CSVLink>
        </div>
    )
}

export default DownloadAll