import {useDispatch, useSelector} from "react-redux";
import {set_fixing_date_basket, set_fixing_dates_basket, set_period_mode_basket} from "../../redux/CallOnBasket/action";

const useExpiryBasket = () => {
    const dispatch = useDispatch();
    const [fixingDates, fixingDate, tradingDate, periodMode] = useSelector(state => {
       const {BasketReducer} = state
       return [BasketReducer.fixingDates, BasketReducer.fixingDate[0], BasketReducer.tradingDate, BasketReducer.periodMode]
    })

    const setFixingDates = (value) => {
        if (periodMode === "years") {
            value = Math.round(value * 365)
        }
        const tmp = new Date(tradingDate)
        tmp.setDate(tmp.getDate() + value)
        dispatch(set_fixing_date_basket([tmp.toISOString().slice(0, 10)]))
        dispatch(set_fixing_dates_basket(value))
    }

    const setFixingDate = (value) => {
        const tmpDate = new Date(value)
        const tmp = new Date(tradingDate)
        dispatch(set_fixing_date_basket([value]))
        dispatch(set_fixing_dates_basket((tmpDate - tmp) / (1000 * 60 * 60 * 24)))
    }

    const setPeriodMode = (value) => {
        dispatch(set_period_mode_basket(value))
    }

    return {
        title: "Expiry",
        data: [
            {
                name: `Term, ${periodMode}`,
                value: periodMode === "days" ? fixingDates : (fixingDates / 365).toFixed(2),
                onChange: setFixingDates,
                type: {
                    name: "number"
                }
            },
            {
                name: "Term, prd",
                value: periodMode,
                onChange: setPeriodMode,
                type: {
                    name: "selector",
                    options: ["days", "years"]
                }
            },
            {
                name: "Expiry date",
                value: fixingDate,
                onChange: setFixingDate,
                type: {
                    name: "date"
                }
            }
        ]
    }
}

export default useExpiryBasket;