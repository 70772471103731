import useRequest from "../useRequest";
import {useDispatch} from "react-redux";
import {set_all_data_autocall, set_snapshot_autocall} from "../../redux/Autocall/actions";
import {set_all_data_common} from "../../redux/Common/actions";
import {set_all_data_marketdata} from "../../redux/MarketData/actions";
import useViewMode from "../useViewMode";
import {set_all_data_vanilla} from "../../redux/Vanilla/actions";
import {set_all_data_basket} from "../../redux/CallOnBasket/action";
import {set_all_data_market_data_product} from "../../redux/MarketDataProduct/actions";
import {set_all_data_participation} from "../../redux/Participation/actions";
import {set_all_data_digital_call} from "../../redux/DigitalCall/actions";
import {set_all_data_digital_call_short} from "../../redux/DigitalCallShort/actions";
import {set_all_data_spread_option} from "../../redux/SpreadOption/actions";

const useRestoreSnapshot = () => {
    const {get} = useRequest()
    const dispatch = useDispatch()
    const {viewMode} = useViewMode()
    const restore = async iid => {
        const data = await get(
            `/rdict/get/${iid}`,
            undefined,
            () => {},
            () => {}
        )
        if (viewMode === "Autocall") {
            dispatch(set_all_data_autocall(data))
        } else if (viewMode === "Vanilla") {
            dispatch(set_all_data_vanilla(data))
        } else if (viewMode === "Call On Basket") {
            dispatch(set_all_data_basket(data))
        } else if (viewMode === "MarketData") {
            dispatch(set_all_data_market_data_product(data))
        } else if (viewMode === "Protected Participation") {
            dispatch(set_all_data_participation(data))
        } else if (viewMode === "Digital Call on Basket") {
            dispatch(set_all_data_digital_call(data))
        } else if (viewMode === "Digital Call") {
            dispatch(set_all_data_digital_call_short(data))
        } else if (viewMode === "Spread Option") {
            dispatch(set_all_data_spread_option(data))
        }

        dispatch(set_all_data_marketdata(data))
        dispatch(set_all_data_common(data))
        if (data.hash) {
            window.open("/pricing/" + data.hash, '_blank', 'noopener,noreferrer')
        }
        console.log("data restored", data)
    }

    return {
        restore
    }
}

export default useRestoreSnapshot;