import {useDispatch, useSelector} from "react-redux";
import useViewMode from "./useViewMode";
import {set_ref_spots as set_ref_spots_autocall} from "../redux/Autocall/actions";
import {set_ref_spots as set_ref_spots_vanilla} from "../redux/Vanilla/actions";

const useRefSpots = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const refSpots = useSelector((state) => {
        const {autocallReducer, VanillaReducer} = state
        if (viewMode.viewMode === "Autocall") {
            return autocallReducer.refSpots
        } else if (viewMode.viewMode === "Vanilla") {
            return VanillaReducer.refSpots
        }
    })

    const changeRefSpots = (newRefSpots, ticker) => {
        if (viewMode.viewMode === "Autocall") {
            dispatch(set_ref_spots_autocall({...refSpots, [ticker]: newRefSpots}))
        } else if (viewMode.viewMode === "Vanilla") {
            dispatch(set_ref_spots_vanilla({...refSpots, [ticker]: newRefSpots}))
        }
    }

    return {
        refSpots,
        changeRefSpots
    }
}

export default useRefSpots