import axios from "axios";

export const makeLog = (type, prevValue, newValue) => {
    prevValue = JSON.stringify(prevValue)
    newValue = JSON.stringify(newValue)
    const url = window.location.href
    const uuid = localStorage.getItem("oAuth_pricing_uuid")
    const role = localStorage.getItem("oAuth_pricing")
    const session_id = sessionStorage.getItem("session_id")
    axios.post("/logger/log", {
        message: `Change ${type} value`,
        uuid: uuid ?? "unauthorized",
        role: role ?? "unauthorized",
        level: "CHANGE",
        description: `Prev Value: ${prevValue}; New Value: ${newValue}`,
        response: "",
        url,
        session_id
    })
}

export const makeLogService = (type, prevValue, newValue) => {
    prevValue = JSON.stringify(prevValue)
    newValue = JSON.stringify(newValue)
    const url = window.location.href
    const uuid = localStorage.getItem("oAuth_pricing_uuid")
    const role = localStorage.getItem("oAuth_pricing")
    const session_id = sessionStorage.getItem("session_id")
    axios.post("/logger/log", {
        message: `Service. Change ${type} value`,
        uuid: uuid ?? "unauthorized",
        role: role ?? "unauthorized",
        level: "CHANGE",
        description: `Prev Value: ${prevValue}; New Value: ${newValue}`,
        response: "",
        url,
        session_id
    })
}