export const TICKERS_SPREAD_OPTION = 'TICKERS_SPREAD_OPTION'

export const REF_SPOTS_SPREAD_OPTION = 'REF_SPOTS_SPREAD_OPTION'

export const IS_WITHOUT_NOTIONAL_SPREAD_OPTION = 'IS_WITHOUT_NOTIONAL_SPREAD_OPTION'

export const CURRENCY_SPREAD_OPTION = 'CURRENCY_SPREAD_OPTION'

export const FIXING_DATES_SPREAD_OPTION = 'FIXING_DATES_SPREAD_OPTION'

export const TIME_TO_MATURITY_SPREAD_OPTION = 'TIME_TO_MATURITY_SPREAD_OPTION'

export const PERIOD_MODE_SPREAD_OPTION = 'PERIOD_MODE_SPREAD_OPTION'

export const SPREAD_OPTION_ORDER_SPREAD_OPTION = 'SPREAD_OPTION_ORDER_SPREAD_OPTION'

export const TRADING_DATE_SPREAD_OPTION = 'TRADING_DATE_SPREAD_OPTION'

export const GREEKS_SPREAD_OPTION = 'GREEKS_SPREAD_OPTION'

export const ALL_DATA_SPREAD_OPTION = 'ALL_DATA_SPREAD_OPTION'

export const NOTIONAL_SPREAD_OPTION = "NOTIONAL_SPREAD_OPTION"