import PricingView from "../../routes/Main/PricingView/PricingView";
import {useEffect} from "react";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import {useParams} from "react-router-dom";
import useRefreshByRequestId from "../../hooks/useRefreshByRequestId";
import useFixingDetails from "../../hooks/useFixingDetails";
import useFullSnapshot from "../../hooks/snapshots/useFullSnapshot";

/**
 * Block for content. Here we have all products and selector for them.
 * @param mode {number} - details option (0 - pricing request, 1 - fixing details, 2 - market data, 3 - trade details)
 * @returns {JSX.Element}
 * @constructor
 */
const Autocall = ({mode}) => {
    const refreshManager = useRefreshMarketData()
    const fixings = useFixingDetails()
    const {iid} = useParams()
    const updateManager = useRefreshByRequestId()

    // useEffect(() => {
    //     refreshManager.refreshMD()
    //         .then(() => {
    //             if (iid !== undefined) {
    //                 setTimeout(() => {
    //                     updateManager.refresh(iid, true)
    //                 }, 1000)
    //             } else {
    //                 fixings.refreshFixingDetailsFull()
    //             }
    //         })
    // }, [])

    return (
        <PricingView mode={mode}/>
    )
}

export default Autocall