import {
    ALL_DATA_PARTICIPATION,
    CALL_UP_LIMIT_ABS_PARTICIPATION,
    CALL_UP_LIMIT_PARTICIPATION,
    CURRENCY_PARTICIPATION,
    FIXING_DATE_PARTICIPATION,
    FIXING_DATES_PARTICIPATION,
    GREEKS_PARTICIPATION,
    IS_WITHOUT_NOTIONAL_PARTICIPATION, LEVERAGE_RATE_PARTICIPATION,
    MODEL_PARTICIPATION,
    N_SIMS_PARTICIPATION,
    NOTIONAL_PARTICIPATION,
    PAYOFF_PARTICIPATION,
    PERIOD_MODE_PARTICIPATION,
    REF_SPOTS_PARTICIPATION,
    STRIKE_ABS_PARTICIPATION,
    STRIKE_PARTICIPATION,
    TICKERS_PARTICIPATION,
    TRADING_DATE_PARTICIPATION
} from "./types";

export const set_tickers_participation = (value) => {
    return {
        type: TICKERS_PARTICIPATION,
        value
    }
}

export const set_fixing_dates_participation = (value) => {
    return {
        type: FIXING_DATES_PARTICIPATION,
        value
    }
}

export const set_is_without_notional_participation = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL_PARTICIPATION,
        value
    }
}

export const set_currency_participation = (value) => {
    return {
        type: CURRENCY_PARTICIPATION,
        value
    }
}

export const set_payoff_participation = (value) => {
    return {
        type: PAYOFF_PARTICIPATION,
        value
    }
}

export const set_strike_participation = (value) => {
    return {
        type: STRIKE_PARTICIPATION,
        value
    }
}

export const set_call_up_limit_participation = (value) => {
    return {
        type: CALL_UP_LIMIT_PARTICIPATION,
        value
    }
}

export const set_trading_date_participation = (value) => {
    return {
        type: TRADING_DATE_PARTICIPATION,
        value
    }
}

export const set_ref_spots_participation = (value) => {
    console.log("{ change ref spots participation }", value)
    return {
        type: REF_SPOTS_PARTICIPATION,
        value
    }
}

export const set_fixing_date_participation = (value) => {
    return {
        type: FIXING_DATE_PARTICIPATION,
        value
    }
}

export const set_strike_abs_participation = (value) => {
    return {
        type: STRIKE_ABS_PARTICIPATION,
        value
    }
}

export const set_call_up_limit_abs_participation = (value) => {
    return {
        type: CALL_UP_LIMIT_ABS_PARTICIPATION,
        value
    }
}

export const set_greeks_participation = (value) => {
    return {
        type: GREEKS_PARTICIPATION,
        value
    }
}

export const set_notional_participation = (value) => {
    return {
        type: NOTIONAL_PARTICIPATION,
        value
    }
}

export const set_period_mode_participation = (value) => {
    return {
        type: PERIOD_MODE_PARTICIPATION,
        value
    }
}

export const set_model_participation = (value) => {
    return {
        type: MODEL_PARTICIPATION,
        value
    }
}

export const set_n_sims_participation = (value) => {
    return {
        type: N_SIMS_PARTICIPATION,
        value
    }
}

export const set_leverage_rage_participation = value => {
    return {
        type: LEVERAGE_RATE_PARTICIPATION,
        value
    }
}

export const set_all_data_participation = value => {
    return {
        type: ALL_DATA_PARTICIPATION,
        value: value.product
    }
}
