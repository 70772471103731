import {useDispatch, useSelector} from "react-redux";
import {set_refresh_mode} from "../../../redux/Common/actions";
import useRefreshMode from "../../../hooks/useRefreshMode";

const SettingsMarketData = () => {
    const dispatch = useDispatch()
    const refreshMode = useRefreshMode()

    const setMode = (e) => {
        localStorage.setItem("refresh_mode", e.target.name)
        dispatch(set_refresh_mode(e.target.name))
    }

    return (
        <div className={"settings-market-data"}>
            <p className={"settings-market-data__title"}>Default Refresh Mode:</p>
            <label className={"settings--label"}>
                DEFAULT
                <input type="checkbox" checked={localStorage.getItem("refresh_mode") === "DEFAULT"} name={"DEFAULT"}
                       onChange={(e) => setMode(e)}/>
            </label>
            <label className={"settings--label"}>
                HEDGING
                <input type="checkbox" checked={localStorage.getItem("refresh_mode") === "HEDGING"} name={"HEDGING"}
                       onChange={(e) => setMode(e)}/>
            </label>
        </div>
    )
}

export default SettingsMarketData;