import {useDispatch, useSelector} from "react-redux";
import {set_popup_content, set_popup_status, set_popup_type} from "../redux/Common/actions";

const useInfoPopup = () => {
    const dispatch = useDispatch();
    const [popupStatus, popupContent, popupType] = useSelector((state) => {
        const {commonReducer} = state
        return [commonReducer.popupStatus, commonReducer.popupContent, commonReducer.popupType]
    })

    if (popupStatus) {
        setTimeout(() => {
            dispatch(set_popup_status(false))
        }, 3000)
    }

    const setPopupStatus = (status, content, type) => {
        dispatch(set_popup_status(status))
        dispatch(set_popup_content(content))
        dispatch(set_popup_type(type))
    }

    return {
        data: {
            popupStatus,
            popupContent,
            popupType
        },
        setPopupStatus
    }

}

export default useInfoPopup;