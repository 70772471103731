import {
    ALL_DATA_CALL_ON_BASKET,
    CALL_UP_LIMIT_CALL_ON_BASKET,
    CURRENCY_CALL_ON_BASKET,
    FIXING_DATE_CALL_ON_BASKET,
    FIXING_DATES_CALL_ON_BASKET,
    GREEKS_CALL_ON_BASKET,
    IS_WITHOUT_NOTIONAL_CALL_ON_BASKET,
    MODEL_CALL_ON_BASKET, N_SIMS_CALL_ON_BASKET,
    NOTIONAL_CALL_ON_BASKET,
    PERIOD_MODE_CALL_ON_BASKET,
    REF_SPOTS_CALL_ON_BASKET,
    TICKERS_CALL_ON_BASKET, TRADING_DATE_CALL_ON_BASKET
} from "./types";
import {makeLog} from "../../utils/logger";

const nowDate = new Date()
nowDate.setDate(nowDate.getDate() + 365)

const initialState = {
    className: "Autocall",
    fixingDates: 365,
    isWithoutNotional: true,
    payoff: "call",
    priceCurrency: "RUB",
    strike: 1.0,
    tickersBasket: ["SBER"],
    tradingDate: new Date().toISOString().slice(0, 10),
    version: "'v1.0_20230702",
    typology: "OPT",
    callUpLimit: undefined,
    currency: "RUB",

    // services
    currencyOptions: ["RUB", "USD", "EUR", "CNY"],
    refSpots: {
        "SBER": 100
    },
    fixingDate: [nowDate.toISOString().slice(0, 10)],
    strikeAbs: 100,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },
    notional: 100000000,
    periodMode: "days",

    model: "LocalVol",
    modelOptions: ["LocalVol", "BlackScholes"],

    n_sims: 131072
}

export const BasketReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_WITHOUT_NOTIONAL_CALL_ON_BASKET:
            makeLog("IS_WITHOUT_NOTIONAL_CALL_ON_BASKET", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case CURRENCY_CALL_ON_BASKET:
            makeLog("CURRENCY_CALL_ON_BASKET", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case TICKERS_CALL_ON_BASKET:
            makeLog("TICKERS_CALL_ON_BASKET", state.tickersBasket, action.value)
            return {
                ...state,
                tickersBasket: action.value
            }
        case FIXING_DATES_CALL_ON_BASKET:
            makeLog("FIXING_DATES_CALL_ON_BASKET", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case FIXING_DATE_CALL_ON_BASKET:
            makeLog("FIXING_DATE_CALL_ON_BASKET", state.fixingDate, action.value)
            return {
                ...state,
                fixingDate: action.value
            }
        case REF_SPOTS_CALL_ON_BASKET:
            makeLog("REF_SPOTS_CALL_ON_BASKET", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case CALL_UP_LIMIT_CALL_ON_BASKET:
            makeLog("CALL_UP_LIMIT_CALL_ON_BASKET", state.callUpLimit, action.value)
            return {
                ...state,
                callUpLimit: action.value
            }
        case GREEKS_CALL_ON_BASKET:
            makeLog("GREEKS_CALL_ON_BASKET", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case NOTIONAL_CALL_ON_BASKET:
            makeLog("NOTIONAL_CALL_ON_BASKET", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        case PERIOD_MODE_CALL_ON_BASKET:
            makeLog("PERIOD_MODE_CALL_ON_BASKET", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case MODEL_CALL_ON_BASKET:
            makeLog("MODEL_CALL_ON_BASKET", state.model, action.value)
            return {
                ...state,
                model: action.value
            }
        case N_SIMS_CALL_ON_BASKET:
            makeLog("N_SIMS_CALL_ON_BASKET", state.n_sims, action.value)
            return {
                ...state,
                n_sims: action.value
            }
        case TRADING_DATE_CALL_ON_BASKET:
            makeLog("TRADING_DATE_CALL_ON_BASKET", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case ALL_DATA_CALL_ON_BASKET:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}