import {useSelector} from "react-redux";

const useProductBasket = () => {
    const [tickersBasket, isWithoutNotionalBasket, fixingDatesBasket,
        fixingDateBasket, tradingDateBasket, refSpots,
        callUpLimit] = useSelector((store) => {
        const {BasketReducer} = store
        return [BasketReducer.tickersBasket, BasketReducer.isWithoutNotional, BasketReducer.fixingDates,
            BasketReducer.fixingDate, BasketReducer.tradingDate, BasketReducer.refSpots,
            BasketReducer.callUpLimit]
    })

    return {
        class_name: "Autocall",
        tickers: tickersBasket,
        typology: "EQS",
        version: "v1.0_20230826_2",
        ac_strike: 5.0,
        ac_strikes: [5.0],
        barrier: 0,
        strike: 0,
        cpn_strike: 0,
        cpn_strikes: [0],
        fixed_cpn_amt: 0,
        fixed_cpn_cnt: 0,
        is_call_payoff: true,
        is_memory: true,
        cpn_amt_pa: 0,
        barrier_type: {
            ac: "avg"
        },
        digital_spreads: {
            put: 0.1
        },
        payoff_details: {
            payoff: "put"
        },

        is_without_notional: isWithoutNotionalBasket,
        ref_spots: refSpots,
        fixing_dates: fixingDateBasket,
        trading_date: tradingDateBasket,
        schedule_freq: fixingDatesBasket,
        time2maturity: fixingDatesBasket,
        call_up_limit: callUpLimit
    }
}

export default useProductBasket;