export const FIXING_DATES_CALL_ON_BASKET = 'FIXING_DATES_CALL_ON_BASKET'
export const IS_WITHOUT_NOTIONAL_CALL_ON_BASKET = 'IS_WITHOUT_NOTIONAL_CALL_ON_BASKET'
export const PAYOFF_CALL_ON_BASKET = 'PAYOFF_CALL_ON_BASKET'
export const CURRENCY_CALL_ON_BASKET = 'CURRENCY_CALL_ON_BASKET'
export const PRICE_CURRENCY_CALL_ON_BASKET = 'PRICE_CURRENCY_CALL_ON_BASKET'
export const STRIKE_CALL_ON_BASKET = 'STRIKE_CALL_ON_BASKET'
export const TICKERS_CALL_ON_BASKET = 'TICKERS_CALL_ON_BASKET'
export const TRADING_DATE_CALL_ON_BASKET = 'TRADING_DATE_CALL_ON_BASKET'
export const CALL_UP_LIMIT_CALL_ON_BASKET = 'CALL_UP_LIMIT_CALL_ON_BASKET'
export const REF_SPOTS_CALL_ON_BASKET = 'REF_SPOTS_CALL_ON_BASKET'
export const FIXING_DATE_CALL_ON_BASKET = 'FIXING_DATE_CALL_ON_BASKET'
export const STRIKE_ABS_CALL_ON_BASKET = 'STRIKE_ABS_CALL_ON_BASKET'
export const GREEKS_CALL_ON_BASKET = 'GREEKS_CALL_ON_BASKET'
export const NOTIONAL_CALL_ON_BASKET = 'NOTIONAL_CALL_ON_BASKET'
export const PERIOD_MODE_CALL_ON_BASKET = 'PERIOD_MODE_CALL_ON_BASKET'

export const MODEL_CALL_ON_BASKET = "MODEL_CALL_ON_BASKET"

export const N_SIMS_CALL_ON_BASKET = "N_SIMS_CALL_ON_BASKET"

export const ALL_DATA_CALL_ON_BASKET = "ALL_DATA_CALL_ON_BASKET"
