import {useDispatch, useSelector} from "react-redux";
import {
    set_currency_autocall,
    set_is_without_notional
} from "../../redux/Autocall/actions";
import useChangeState from "../useChangeState";

const useNominalDetail = () => {
    const dispatcher = useDispatch()
    const changeState = useChangeState()
    const options = ["RUB", "USD", "EUR"]

    const [isWithoutNotional, currency, snapshot] = useSelector((state) => {
        const {autocallReducer} = state
        return [autocallReducer.isWithoutNotional, autocallReducer.currency, autocallReducer.snapshot]
    })



    const setCurrency = (value) => {
        dispatcher(set_currency_autocall(value))
    }

    const setIsWithoutNotional = (value) => {
        if (value === "Warrant") {
            value = true
        } else if (value === "Note") {
            value = false
        }
        changeState.setChange(true, isWithoutNotional, value)
        dispatcher(set_is_without_notional(value))
    }

    return {
        title: "Notional",
        data: [
            {
                name: "Currency",
                value: currency,
                onChange: setCurrency,
                change: snapshot ? snapshot?.currency !== currency : false,
                type: {
                    name: "selector",
                    options: options
                }
            },
            {
                name: "Wrapper",
                value: isWithoutNotional ? "Warrant" : "Note",
                onChange: setIsWithoutNotional,
                change: snapshot ? snapshot?.isWithoutNotional !== isWithoutNotional : false,
                type: {
                    "name": "selector",
                    "options": ["Note", "Warrant"]
                }
            }]
    }
}

export default useNominalDetail