export class GraphDataStructure {
    constructor() {
        this.data = undefined;
        this.settings = undefined;
        this.calc = undefined;
        this.flow = undefined;
        this.name = undefined;
        this.result = [];
        this.metrics = {
            last_value: {
                date: undefined,
                value: []
            }
        }
    }

    updateState(data, flow, name) {
        this.flow = flow;
        this.name = name;
        if (typeof data.data === "object") {
            console.log("object", data.data)
            if (Array.isArray(data.data)) {
                this.data = data.data
                this.settings = undefined;
                this.calc = undefined;
            } else {
                this.data = JSON.parse(data.data.data)
                if (Object.keys(data.data.settings.params).length === 0) {
                    this.settings = undefined;
                    console.log("OKJDANPOJADNG{OAEg")
                } else {
                    this.settings = data.data.settings.params;
                }


                if (Array.isArray(data.data.calcs) === true) {
                    this.calc = data.data.calcs;
                    for (const row of this.calc) {
                        if (typeof row.result === "object") {
                            console.log("object", row.result)
                            const tmp = []
                            for (const key in row.result) {
                                tmp.push(row.result[key])
                            }
                            row.result = tmp.join("/")
                        }
                    }
                }
            }




            // if (typeof this.calc.result === "object") {
            //     console.log("object", this.calc.result)
            //     const tmp = []
            //     for (const key in this.calc.result) {
            //         tmp.push(this.calc.result[key])
            //     }
            //     this.calc.result = tmp.join("/")
            // }
        } else {
            console.log("-.-.- --.--", data.data, typeof data.data)
            this.data = JSON.parse(data.data.replaceAll("NaN", "null").replaceAll("Infinity", "null"))
            console.warn("DATA", this.data)
            this.settings = undefined;
            this.calc = undefined;
        }
        console.warn("DATA", this.data)
        if (this.flow === "IGP" || this.flow === "IGPC") {
            this.#prepareCandlestickData()
            this.preparedData()
            console.warn("PP DATA", this.data, this.preparedData())
            return
        }
        if (this.flow === "IHST") {
            console.warn("IHST", this.data)
            // this.#validateData();
            this.#transformDates();
            this.preparedData()
            return
        }
        if (this.flow === "IGPCT") {
            console.warn("IGPCT", this.data)
            // this.#validateData();
            this.#transformDates();
            this.#prepareCandlestickDataExtra()
            this.preparedData()
            return
        }
        this.#validateData();
        this.#transformDates();
        this.preparedData()

        console.log("calc ", this.calc)
        console.log("settings ", this.settings)
        console.log("data ", this.data)
        console.log("result ", this.result)

        // this.settings = data.data.settings.params;
        // this.calc = data.data.settings.calc;
    }

    #transformDates() {
        if (this.flow === "IHS") {
            this.data.data = this.data.data.map(elem => {
                const tmp = new Date(elem[0])
                return [new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16), elem[1], elem[2]]
            })
            return
        }
        if (this.flow === "IHST" || this.flow === "IGPCT") {
            this.data.data.buy_trades.data = this.data.data.buy_trades.data.map(el => {
                const tmp = new Date(el[0])
                return [new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16), el[1], el[2]]
            })
            this.data.data.sell_trades.data = this.data.data.sell_trades.data.map(el => {
                const tmp = new Date(el[0])
                return [new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16), el[1], el[2]]
            })
            if (this.flow === "IHST") {
                this.data.data.spread.data = this.data.data.spread.data.map(el => {
                    const tmp = new Date(el[0])
                    return [new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16), el[1], el[2]]
                })
            }
            if (this.flow === "IGPCT") {
                this.data.data.candles.data = this.data.data.candles.data.map(el => {
                    const tmp = new Date(el[3])
                    const tmp2 = new Date(el[4])
                    return [el[0], el[1], el[2], new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16), new Date(tmp2 - tmp2.getTimezoneOffset() * 60000).toISOString().slice(0, 16), el[5], el[6], el[7], el[8]]
                })
            }


            return
        }
        this.data.index = this.data.index.map(elem => new Date(elem).toISOString().slice(0, 10));
    }

    #validateData() {
        if (this.data === undefined || typeof this.data !== "object") {
            throw new Error("Not found data in request")
        }
        if (this.flow === "GPO") this.#validateCandlestick()
        else if (this.flow === "HS") this.#validateUnique()
        else if (this.flow === "GPC") this.#validateMerge()
        else this.#validateCommon()
    }

    #validateStandard() {
        console.log(this.data)
        if (this.data.columns === undefined ||
            this.data.data === undefined ||
            this.data.index === undefined) {
            throw new Error("No such data in response")
        }

        // Проверка на типы поля index. Для всех запрососв это массив чисел
        if (Array.isArray(this.data.index) === false) throw new Error("Index must be array (dates)")
        for (const value of this.data.index) {
            if (typeof value !== "number") throw new Error("Index must be array of numbers")
        }


        // Проверка что длина массива data равна длине массива index
        if (this.data.data.length !== this.data.index.length) throw new Error("Data length must be equal to index length")

        // Проверка, что элементы массива columns являются строками или числами
        if (Array.isArray(this.data.columns) === false) throw new Error("Columns must be array")
    }

    #validateCommon() {
        this.#validateStandard()
        for (const value of this.data.columns) {
            if (typeof value !== "string" && typeof value !== "number") throw new Error("Columns must be array of strings or numbers")
        }

        // Проверка, что длина массива data[i] равна длине массива columns
        for (const value of this.data.data) {
            if (value.length !== this.data.columns.length) throw new Error("Data length must be equal to columns length")
        }
    }

    #validateCandlestick() {
        this.#validateStandard()
        for (const value of this.data.columns) {
            if (!Array.isArray(value)) throw new Error("Columns must be array of array")
            for (const v of value) {
                if (typeof v !== "string") throw new Error("Columns must be array of strings")
            }
        }

        // Проверка, что длина массива data[i] равна длине массива columns
        for (const value of this.data.data) {
            if (value.length !== this.data.columns.length) throw new Error("Data length must be equal to columns length")
        }

        // this.data.columns = this.data.columns.map(elem => elem.join(", "))
    }

    #validateUnique() {
        this.data.columns = [this.data.name]
        this.data.data = this.data.data.map(elem => [elem])
        this.#validateCommon()
    }

    #validateMerge() {
        this.data.columns = this.data.columns.map(elem => elem.join(", "))
        this.#validateCommon()
    }

    preparedData() {
        if (this.flow === "GPO") return this.#getCandlestickData()
        else if (this.flow === "IGP") return this.#getCandlestickDataIGP()
        else if (this.flow === "IGPC") return this.#getCandlestickDataIGPC()
        else if (this.flow === "IGPCT") return this.#getCandlestickDataIGPCT()
        else if (this.flow === "IHS") return this.#getIHSData()
        else if (this.flow === "IHST") return this.#getIHSTData()
        else this.#getCommonData()
        console.log("[ PREPEARED IN CLASS ] ", this.result)
        return this.result
    }

    #getIHSTData() {
        const tmp = []
        const x = this.data.data.spread.data.map(el => el[0])
        for (let i = 1; i < 3; i++) {
            tmp.push({
                x: x,
                y: this.data.data.spread.data.map(el => el[i]),
                type: "scatter",
                mode: "lines",
                name: this.data.data.spread.columns[i]
            })
        }
        const x_buy = this.data.data.buy_trades.data.map(el => el[0])
        for (let i = 1; i < 2; i++) {
            tmp.push({
                x: x_buy,
                y: this.data.data.buy_trades.data.map(el => el[i]),
                type: "scatter",
                mode: "markers",
                marker: {
                    color: "green", // Цвет точек, RGBA-формат
                    size: 10, // Размер точек
                    symbol: "triangle-up"
                },
                name: "buy"
            })
        }
        const x_sell = this.data.data.sell_trades.data.map(el => el[0])
        for (let i = 1; i < 2; i++) {
            tmp.push({
                x: x_sell,
                y: this.data.data.sell_trades.data.map(el => el[i]),
                type: "scatter",
                mode: "markers",
                marker: {
                    color: "red", // Цвет точек, RGBA-формат
                    size: 10, // Размер точек
                    symbol: "triangle-down"
                },
                name: "sell"
            })
        }
        this.result = tmp
    }

    #getIHSData() {
        const x = this.data.data.map(el => el[0])
        const tmp = []
        // let index = 0
        // for (let i = 0; i < this.data.data.length; i++) {
        //     if (this.data.data[i][1] === null && this.data.data[i][2] === null) {
        //         index++
        //     } else {
        //         break
        //     }
        // }
        for (let i = 1; i < 3; i++) {
            // const y = []
            // for (let j = index; j < this.data.data.length; j++) {
            //     y.push(this.data.data[j][i])
            // }
            tmp.push({
                x: x,
                y: this.data.data.map(el => el[i]),
                type: "scatter",
                mode: "lines",
                name: this.data.columns[i]

            })
        }
        this.result = tmp
        // this.result = this.data.columns.map((el, i) => {
        //     if (i === 0) return undefined
        //     return {
        //         x: x,
        //         y: this.data.data.map(el => el[i]),
        //         type: "scatter",
        //         mode: "lines",
        //         name: el
        //     }
        // })
    }

    #getCommonData() {
        const strictData = {}
        this.data.columns.forEach((elem, index) => {
            strictData[elem] = this.data.data.map(value => value[index])
        })
        const lastValue = []
        this.data.columns.forEach((elem, index) => {
            lastValue.push({
                ticker: elem,
                value: this.data.data[this.data.data.length - 1][index]
            })
        })
        console.log("metrics", this.metrics)
        this.metrics.last_value.value = lastValue
        this.metrics.last_value.date = this.data.index[this.data.index.length - 1]
        this.result = Object.keys(strictData).map((elem, i) => {
            return {
                x: this.data.index,
                y: strictData[elem],
                type: "scatter",
                mode: "lines",
                name: this.data.columns[i]
            }
        })
    }

    #getCandlestickData() {
        const strictData = {}
        this.data.columns.forEach((elem, index) => {
            strictData[elem[0]] = this.data.data.map(value => value[index])
        })
        console.log(strictData)
        const lastValue = []
        this.data.columns.forEach((elem, index) => {
            lastValue.push({
                ticker: elem[0],
                value: this.data.data[this.data.data.length - 1][index]
            })
        })
        this.metrics.last_value.value = lastValue
        this.metrics.last_value.date = this.data.index[this.data.index.length - 1]

        this.result =  [{
            x: this.data.index,
            open: strictData["Open"],
            close: strictData["Close"],
            high: strictData["High"],
            low: strictData["Low"],
            type: "candlestick",
            name: this.data.columns[0][1],
            xaxis: "x",
            yaxis: "y"
        }]
        console.warn("RESULT GPO", this.result)
    }

    #prepareCandlestickData() {
        const tmpData = []
        for (let i = 0; i < this.data.index.length; i++) {
            const tmp = {}
            for (let j = 0; j < this.data.columns.length; j++) {
                tmp[this.data.columns[j]] = this.data.data[i][j]
            }
            tmpData.push(tmp)
        }
        this.data = tmpData
    }

    #prepareCandlestickDataExtra() {
        const tmpData = []
        for (let i = 0; i < this.data.data.candles.index.length; i++) {
            const tmp = {}
            for (let j = 0; j < this.data.data.candles.columns.length; j++) {
                tmp[this.data.data.candles.columns[j]] = this.data.data.candles.data[i][j]
            }
            tmpData.push(tmp)
        }
        this.data.data.candles = tmpData
        console.warn("Preared data", this.data.data)
    }

    #getCandlestickDataIGP() {
        this.result =  [{
            x: this.data.map(el => {
                const tmp = new Date(el["open_time"])
                return new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
            }),
            y: this.data.map(el => el["close"]),
            type: "scatter",
            mode: "lines",
            name: this.name
        }]
        return this.result
    }

    #getCandlestickDataIGPC() {
        this.result = [{
            x: this.data.map(el => {
                const tmp = new Date(el["open_time"])
                return new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
            }),
            open: this.data.map(el => el["open"]),
            close: this.data.map(el => el["close"]),
            high: this.data.map(el => el["high"]),
            low: this.data.map(el => el["low"]),
            type: "candlestick",
            name: this.name,
            xaxis: "x",
            yaxis: "y"
        }]
        console.warn("RESULT IGPC", this.result)
        return this.result
    }

    #getCandlestickDataIGPCT() {
        this.result = [{
            x: this.data.data.candles.map(el => {
                const tmp = new Date(el["open_time"])
                return new Date(tmp - tmp.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
            }),
            open: this.data.data.candles.map(el => el["open"]),
            close: this.data.data.candles.map(el => el["close"]),
            high: this.data.data.candles.map(el => el["high"]),
            low: this.data.data.candles.map(el => el["low"]),
            type: "candlestick",
            name: this.name,
            xaxis: "x",
            yaxis: "y"
        }, {
            x: this.data.data.buy_trades.data.map(el => el[0]),
            y: this.data.data.buy_trades.data.map(el => el[1]),
            type: "scatter",
            mode: "markers",
            marker: {
                color: "green", // Цвет точек, RGBA-формат
                size: 10, // Размер точек
                symbol: "triangle-up"
            },
            name: "buy"
        },{
            x: this.data.data.sell_trades.data.map(el => el[0]),
            y: this.data.data.sell_trades.data.map(el => el[1]),
            type: "scatter",
            mode: "markers",
            marker: {
                color: "red", // Цвет точек, RGBA-формат
                size: 10, // Размер точек
                symbol: "triangle-down"
            },
            name: "sell"
        }]
        console.warn("RESULT IGPC", this.result)
        return this.result
    }

}