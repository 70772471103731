import './MarketData.css'
import MarketTable from "./MarketTable/MarketTable";
import PricingRequestSearch from "../PricingRequest/PricingRequestSearch/PricingRequestSearch";
import MarketDataWindow from "../../../views/MarketDataWindow/MarketDataWindow";
import MarketDataRefresh from "../../../components/MarketDataRefresh/MarketDataRefresh";
import useViewMode from "../../../hooks/useViewMode";
import MarketDataRate from "../../../components/MarketDataRate/MarketDataRate";

const MarketData = () => {
    const viewMode = useViewMode()
    return (
        <div className={`market-data`}>
            <PricingRequestSearch/>
            <MarketDataRefresh/>
            <MarketDataWindow/>
            {viewMode.viewMode !== 'Vanilla' && viewMode.viewMode !== "Protected Participation" && viewMode.viewMode !== "Digital Call" && <MarketTable/>}
            <MarketDataRate />
        </div>
    )
}

export default MarketData

// https://api.rumberg.ru/market_data/refresh/?iid=2a75899144125b22fa59f7eea3dccc174dea43a3bda639ea5f57bd5a73e4f3ce&corr_history=365&corr_floor=0.7&pricing_date=2023-05-21&refresh_results=false
// https://api.rumberg.ru/market_data/refresh/?iid=2a75899144125b22fa59f7eea3dccc174dea43a3bda639ea5f57bd5a73e4f3ce&corr_history=365&corr_floor=0.7&pricing_date=2023-05-09&refresh_results=false