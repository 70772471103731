import axios from "axios";
import useMarketDataId from "../useMarketDataId";
import {useDispatch} from "react-redux";
import {set_change_state_marketdata} from "../../redux/MarketData/actions";

/**
 * Hook returns function for get id from server
 * @returns {(function(*): void)|*}
 */
const useGetId = () => {
    const dispatch = useDispatch()
    const mdId = useMarketDataId()
    const updateMdId = mdObj => {
        axios.post(`/rdict/post`, mdObj)
            .then(receivedV => {
                console.log("[ update with pricing ]", receivedV.data)
                mdId.setMarketDataId(receivedV.data)
                dispatch(set_change_state_marketdata(false))
            })
    }
    // return (obj) => {
    //     console.log("[ obj ]", obj)
    //     axios(`/rdict/push/${JSON.stringify(obj)}`)
    //         .then(receivedV => {
    //             console.log("[ receivedV ]", receivedV.data)
    //             return receivedV.data
    //         })
    // }
    return {
        updateMdId
    }
}

export default useGetId;