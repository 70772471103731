import {useDispatch, useSelector} from "react-redux";
import {
    set_ac_performance,
    set_ac_stepdown,
    set_ac_strike,
    set_ac_strikes,
    set_barrier,
    set_cpn_amt_pa,
    set_cpn_performance,
    set_cpn_strike, set_cpn_strikes, set_date_expiry_autocall,
    set_final_performance,
    set_fixed_cpn_cnt,
    set_fixing_dates,
    set_is_without_notional,
    set_no_ac_periods, set_period_mode,
    set_ref_spots,
    set_schedule_freq,
    set_strike,
    set_tickers,
    set_time_to_maturity,
    set_trading_date,
    set_payoff, set_is_call_payoff, set_fixed_cpn_amt, set_snapshot_autocall
} from "../redux/Autocall/actions";
import {
    set_call_up_limit,
    set_currency,
    set_trading_date_vanilla,
    set_is_without_notional as set_is_without_notional_vanilla,
    set_payoff as set_payoff_vanilla,
    set_strike as set_strike_vanilla,
    set_tickers as set_tickers_vanilla,
    set_ref_spots as set_ref_spots_vanilla, set_fixing_date_vanilla, set_strike_abs,
    set_fixing_dates as set_fixing_dates_vanilla, set_call_up_limit_abs
} from "../redux/Vanilla/actions";
import {daysDifference} from "../utils/utilsFunctions";
import useInfoPopup from "./useInfoPopup";

/**
 * This hook is used to update all product parameters in the store
 * @returns {{setAllParamsVanilla: setAllParamsVanilla, setAllParamsAutocall: setAllParamsAutocall}} two functions to update all parameters
 */
const useProductDataRefresh = () => {
    const dispatch = useDispatch()
    const popup = useInfoPopup()

    const setAllParamsAutocall = (product) => {
        console.log(" [ product Autocall update all ]", product, product.fixed_cpn_cnt)
        const tmpDate = new Date(product.trading_date)
        dispatch(set_payoff(product.barrier <= product.strike ? "EKI" : "GP"))
        tmpDate.setDate(tmpDate.getDate() + product.time2maturity)
        dispatch(set_period_mode("days"))
        dispatch(set_date_expiry_autocall(tmpDate.toISOString().slice(0, 10)))
        dispatch(set_ac_strikes(product.ac_strikes))
        dispatch(set_cpn_strikes(product.cpn_strikes))
        dispatch(set_trading_date(product.trading_date))
        dispatch(set_time_to_maturity(product.time2maturity / 365))
        dispatch(set_tickers(product.tickers))
        dispatch(set_strike(product.strike))
        dispatch(set_schedule_freq(product.schedule_freq))
        if (product.ref_spots !== undefined) {
            dispatch(set_ref_spots(product.ref_spots))
        } else {
            popup.setPopupStatus(true, "Error update ref spots", false)
        }
        // dispatch(set_ref_spots(product.ref_spots))
        dispatch(set_no_ac_periods(product.no_ac_period))
        dispatch(set_is_without_notional(product.is_without_notional))
        dispatch(set_fixing_dates(product.fixing_dates))
        dispatch(set_fixed_cpn_cnt(product.fixed_cpn_cnt))
        dispatch(set_fixed_cpn_amt(product.fixed_cpn_amt))
        // dispatch(set_no_ac_periods(product.no_ac_periods))
        dispatch(set_ac_strike(product.ac_strike))
        dispatch(set_barrier(product.barrier))
        dispatch(set_ac_performance(product.barrier_types.ac ?? "Wof"))
        dispatch(set_cpn_performance(product.barrier_types.cpn ?? "Wof"))
        dispatch(set_final_performance(product.barrier_types.put ?? "Wof"))
        dispatch(set_cpn_amt_pa(product.cpn_amt_pa))
        dispatch(set_ac_stepdown(product.ac_stepdown))
        dispatch(set_cpn_strike(product.cpn_strike))
        dispatch(set_is_call_payoff(product.is_call_payoff))
        dispatch(set_snapshot_autocall({
            ac_strike: product.ac_strike,
            timeToMaturity: product.time2maturity / 365,
            isWithoutNotional: product.is_without_notional,
            scheduleFreq: product.schedule_freq,
            cpnStrike: product.cpn_strike,
            cpnAMTpa: product.cpn_amt_pa,
            barrier: product.barrier,
            noACPeriod: product.no_ac_period,
            acStepdown: product.ac_stepdown,
            currency: product.currency,
            periodMode: "years",
            fixedCPNAMT: product.fixed_cpn_amt,
            fixedCPNCNT: product.fixed_cpn_cnt,
            payoff: product.barrier <= product.strike ? "EKI" : "GP",
            strike: product.strike,
            acPerformance: product.barrier_types.ac ?? "Wof",
            cpnPerformance: product.barrier_types.cpn ?? "Wof",
            finalPerformance: product.barrier_types.put ?? "Wof",
            isCallPayoff: product.is_call_payoff
        }))
    }

    const setAllParamsVanilla = (product) => {
        console.log('[ product Vanilla update all ]', product)
        // const tmpTradingDate = new Date().toISOString().slice(0, 10)
        // const tmpFixingDate = new Date(tmpTradingDate)
        // tmpFixingDate.setDate(tmpFixingDate.getDate() + 365)

        const tradingDate = new Date(product.trading_date)
        const fixingDate = new Date(product.fixing_dates[0])
        const diffDays = daysDifference(fixingDate, tradingDate)
        dispatch(set_fixing_dates_vanilla(diffDays))

        dispatch(set_call_up_limit_abs(product.call_up_limit))
        if (product.call_up_limit !== undefined) {
            const tmpCallUp = product.call_up_limit / 1
            dispatch(set_call_up_limit(Number(tmpCallUp.toFixed(2))))
        }
        // dispatch(set_fixing_dates_vanilla(365))
        // dispatch(set_trading_date_vanilla(new Date().toISOString().slice(0, 10)))
        dispatch(set_trading_date_vanilla(product.trading_date))
        // dispatch(set_fixing_date_vanilla([tmpFixingDate.toISOString().slice(0, 10)]))
        dispatch(set_fixing_date_vanilla([product.fixing_dates[0]]))
        dispatch(set_currency(product.price_currency))
        dispatch(set_is_without_notional_vanilla(product.is_without_notional))
        dispatch(set_payoff_vanilla(product.payoff))
        // dispatch(set_strike_vanilla(product.strike))
        dispatch(set_tickers_vanilla(product.tickers))
        dispatch(set_ref_spots_vanilla({[product.tickers[0]]: 105}))
        dispatch(set_strike_abs(product.strike))
        dispatch(set_strike_vanilla(product.strike / product.ref_spots[product.tickers[0]]))
    }

    return {
        setAllParamsAutocall,
        setAllParamsVanilla
    }
}

export default useProductDataRefresh
