import './PricingRequest.css'
import ProductDetailView from "../../../views/ProductDetailView/ProductDetailView";
import TickersSelector from "../../../components/TickersSelector/TickersSelector";
import ProductDetailManager from "../../../components/managers/ProductDetailManager";


/**
 * Component for union tickers Selector and view for display product details
 * @returns {JSX.Element}
 * @constructor
 */
const PricingRequest = () => {
    return (
        <div className={`pricing-request`}>
            <TickersSelector/>
            <ProductDetailManager/>
        </div>
    )
}
export default PricingRequest